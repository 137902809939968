const { compose, withProps, lifecycle } = require("recompose");
import React from "react";
const {
    SearchBox,
} = require("react-google-maps/lib/components/places/SearchBox");
const {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    Circle,
    KmlLayer,
} = require("react-google-maps");
const {
    DrawingManager,
} = require("react-google-maps/lib/components/drawing/DrawingManager");
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
import CustomMarker from "./CustomMarker";
import PlaceMarker from "./PlaceMarker";
var showInfoWindow = false;

const mapUrl =
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyBu0-76sJL5A8Ss84aFm9PrpdoB2pktrgI&v=3.exp&libraries=geometry,drawing,places";
const MapWithADrawingManager = compose(
    withProps({
        googleMapURL: mapUrl,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: (
            <div style={{ minHeight: `400px`, height: "90vh" }} />
        ),
        mapElement: <div style={{ height: `100%` }} />,
    }),
    lifecycle({
        componentWillMount() {
            var refs = {};

            var fillColor = this.props.getColor();

            this.setState({
                zoom: 13,
                fillColor: fillColor,
                markers: [],
                onMapMounted: (ref) => {
                    window.googleMap = ref;
                    refs.map = ref;
                },
                handleOverlayComplete(e) {
                    this.setDrawingMode(null);
                },
                onBoundsChanged: () => {
                    this.setState({
                        bounds: refs.map.getBounds(),
                        center: refs.map.getCenter(),
                    });
                },
                onSearchBoxMounted: (ref) => {
                    refs.searchBox = ref;
                },
                markerComplete: (marker, fn) => {
                    console.log(this.props.raio);
                    let circle = new window.google.maps.Circle({
                        center: marker.getPosition(),
                        radius: this.props.raio,
                    });

                    refs.map.panTo(marker.getPosition());
                    circle.setMap(null);

                    //refs.map.fitBounds(circle.getBounds(), 200);
                    refs.map.center = marker.getPosition();

                    this.setState({
                        zoom: 15,
                    });

                    fn(marker, refs.map);
                },
                circleComplete: (circle) => {
                    circle.setOptions({
                        fillColor: this.props.getColor(),
                    });

                    //circle.setRadius(this.props.raio);

                    refs.map.panTo(circle.getCenter());
                    refs.map.center = circle.getCenter();

                    //window.googleMap.fitBounds(circle.getBounds());

                    window.circles.push(circle);

                    this.props.onCircleComplete(circle);
                    this.setState({
                        zoom: 15,
                    });
                },

                onPlacesChanged: () => {
                    const place = refs.searchBox.getPlaces();
                    refs.map.panTo(place[0].geometry.location);

                    this.props.setLocation(place);

                    this.setState({
                        zoom: 12,
                    });
                },
            });
        },
    }),
    withScriptjs,
    withGoogleMap
)((props) => (
    <GoogleMap
        defaultZoom={13}
        zoom={props.zoom}
        ref={props.onMapMounted}
        onBoundsChanged={props.onBoundsChanged}
        defaultCenter={
            new google.maps.LatLng(
                props.currentPosition.lat,
                props.currentPosition.lng
            )
        }
        defaultOptions={{
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: google.maps.ControlPosition.TOP_RIGHT,
            },

            fullscreenControlOptions: {
                position: google.maps.ControlPosition.BOTTOM_RIGHT,
            },
        }}
    >
        {props.manualCircles.map((circle, index) => {
            // if( circle.type == 'Círculo Manual'){
            let circleOverlay = (
                <Circle
                    center={circle.center}
                    key={index}
                    onRadiusChanged={(e) => {
                        props.onCircleRadiusChanged(circle.id);
                    }}
                    onCenterChanged={() => {
                        props.onCircleCenterChanged(circle.id);
                    }}
                    ref={(ref) => {
                        window.refs[circle.id] = ref;
                    }}
                    onMouseOver={() => {
                        console.log(circle.radius, circle.color);
                    }}
                    onClick={() => {}}
                    radius={circle.radius}
                    editable={true}
                    options={{
                        fillColor: circle.fillColor,
                        fillOpacity: 0.3,
                    }}
                />
            );

            window.circles.push(circleOverlay);
            return circleOverlay;
            // }
        })}

        {props.kml_layers.map((kml, index) => {
            if (props.showKMl === true) {
                console.log(kml);
                return (
                    <KmlLayer
                        key={kml}
                        url={kml}
                        options={{ preserveViewport: true }}
                    />
                );
            }
        })}

        {props.showMarkers &&
            props.empreendimentos.map((empreendimento, key) => {
                let icon = empreendimento.marker;

                if (empreendimento.customMarker) {
                    icon = empreendimento.customMarker;
                }

                let url = "http://maps.google.com/mapfiles/ms/icons/";
                url += "red-dot.png";

                let marker = (
                    <CustomMarker
                        key={key}
                        onClick={() => props.onMarkerClick(empreendimento._id)}
                        empreendimento={empreendimento}
                        icon={icon}
                        position={{
                            lat: empreendimento.location.coordinates[1],
                            lng: empreendimento.location.coordinates[0],
                        }}
                    >
                        {/* > */}
                    </CustomMarker>
                );

                //marker.id = empreendimento._id;

                window.markers.push(marker);
                return marker;
            })}
        {props.selectedIcon !== undefined && (
            <Marker
                icon={props.selectedIcon.icon}
                position={props.selectedIcon.position}
            />
        )}

        {props.pontos.map((marker, index) => {
            return (
                <Marker
                    key={index + 1000 * 999}
                    position={{ lat: marker.lat, lng: marker.lng }}
                    onClick={() => {}}
                />
            );
        })}

        {props.show_polos_geradores &&
            props.polos_geradores.map((poloGerador, index) => {
                let marker = (
                    <PlaceMarker
                        key={"polo_gerador_" + index}
                        place={poloGerador}
                        icon={poloGerador.image}
                        position={{
                            lat: poloGerador.position.coordinates[1],
                            lng: poloGerador.position.coordinates[0],
                        }}
                    ></PlaceMarker>
                );

                return marker;
            })}

        <DrawingManager
            defaultDrawingMode={null}
            onMarkerComplete={(marker) => {
                props.markerComplete(marker, props.onMarkerComplete);
                marker.setMap(null);
            }}
            onPolygonComplete={(polygon) => {
                polygon.fillColor = "blue";
                polygon.setOptions({
                    fillColor: props.getColor(),
                });

                props.onPolygonComplete(polygon);
            }}
            onCircleComplete={props.circleComplete}
            onRectangleComplete={(rectangle) => {
                rectangle.setOptions({
                    fillColor: props.getColor(),
                });

                props.onRectangleComplete(rectangle);
            }}
            onOverlayComplete={props.handleOverlayComplete}
            defaultOptions={{
                drawingControl: true,

                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [
                        google.maps.drawing.OverlayType.CIRCLE,
                        google.maps.drawing.OverlayType.POLYGON,
                        google.maps.drawing.OverlayType.RECTANGLE,
                        google.maps.drawing.OverlayType.MARKER,
                    ],
                },
                circleOptions: {
                    fillColor: `transparent`,
                    fillOpacity: 0.3,
                    strokeWeight: 3,
                    draggable: false,
                    clickable: true,
                    editable: true,
                    zIndex: 1,
                },
                polygonOptions: {
                    fillColor: `transparent`,
                    fillOpacity: 0.1,
                    strokeWeight: 3,
                    clickable: true,
                    editable: true,
                    zIndex: 1,
                    draggable: true,
                },
                rectangleOptions: {
                    fillColor: "transparent",
                    fillOpacity: 0.3,
                    strokeWeight: 3,
                    clickable: true,
                    editable: true,
                    zIndex: 1,
                    draggable: true,
                },
            }}
        />
        <SearchBox
            ref={props.onSearchBoxMounted}
            bounds={props.bounds}
            controlPosition={google.maps.ControlPosition.TOP_CENTER}
            onPlacesChanged={props.onPlacesChanged}
        >
            <input
                type="text"
                placeholder="Digite um endereço"
                id="search_box"
                style={{
                    boxSizing: `border-box`,
                    border: `1px solid #527440`,
                    width: `40%`,
                    height: `50px`,
                    marginTop: `5px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                }}
            />
        </SearchBox>
    </GoogleMap>
));

export default MapWithADrawingManager;
