import React, {Component} from 'react';
import IntlCurrencyInput from "react-intl-currency-input";

const currencyConfig = {
    locale: 'pt-BR', formats: {
        number: {
            BRL: {
                style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2,
            },
        },
    },
};

class EstoqueValoresForm extends Component {

    render() {

        return (<React.Fragment>
            <table className={"table table-striped"}>
                <thead>
                <tr>
                    <th className={'text-center'}>DORM.</th>
                    <th className={'text-center'}>Área Privativa</th>
                    <th className={'text-center'}>QTY</th>
                    <th className={'text-center'}>Tipo</th>
                    <th className={'text-center'}>Valor</th>
                    <th className={'text-center'}>Valor Negociado</th>
                    <th className={'text-center'}>Venda</th>
                    <th className={'text-center'}>Distratos</th>
                    <th className={'text-center'}>Estoque</th>
                </tr>
                </thead>
                <tbody>
                {this.props.state.tipologias.map((tipologia, index) => {

                    if( tipologia.vendas === undefined ){
                        tipologia.vendas = null;
                    }

                    if( tipologia.distratos === undefined ){
                        tipologia.distratos = null;
                    }

                    if( tipologia.estoque === undefined ){
                        tipologia.estoque = null;
                    }

                    if( tipologia.valor_negociado === undefined ){
                        tipologia.valor_negociado = null;
                    }

                    if( tipologia.valor === undefined ){
                        tipologia.valor = null;
                    }

                    return (<tr key={tipologia.id}>
                        <td>
                            {tipologia.number_bedroom}
                        </td>
                        <td>
                            {tipologia.private_area}
                        </td>
                        <td>
                            {tipologia.qty}
                        </td>
                        <td>
                            {tipologia.type_of_typology}
                        </td>
                        <td>
                            <IntlCurrencyInput
                                currency="BRL"
                                config={currencyConfig}
                                value={tipologia.valor}
                                onChange={(event, value, maskedValue) => {

                                    console.log( value, maskedValue );
                                    let tipologias = this.props.state.tipologias;
                                    tipologias[index].valor = maskedValue;

                                    this.props.updateTipologias(tipologias);
                                }}
                                style={{padding: '3px'}}
                                className="form-control"
                            />

                            {this.props.state.errors[`${index}.valor`] && <div className="invalid-feedback" style={{display: "block"}}>
                                {this.props.state.errors[`${index}.valor`]}
                            </div>}
                        </td>
                        <td>
                            <IntlCurrencyInput
                                currency="BRL"
                                config={currencyConfig}
                                value={tipologia.valor_negociado}
                                onChange={(event, value, maskedValue) => {
                                    let tipologias = this.props.state.tipologias;
                                    tipologias[index].valor_negociado = maskedValue;

                                    this.props.updateTipologias(tipologias);
                                }}
                                style={{padding: '3px'}}
                                className="form-control"

                            />
                            {this.props.state.errors[`${index}.valor_negociado`] && <div className="invalid-feedback" style={{display: "block"}}>

                                {this.props.state.errors[`${index}.valor_negociado`]}
                            </div>}
                        </td>

                        <td>
                            <input className={'form-control'} value={tipologia.vendas} onChange={ (e)=>{
                                let tipologias = this.props.state.tipologias;
                                tipologias[index].vendas = e.target.value;

                                this.props.updateTipologias(tipologias);

                            }} type="number"/>

                            {this.props.state.errors[`${index}.vendas`] && <div className="invalid-feedback" style={{display: "block"}}>

                                {this.props.state.errors[`${index}.vendas`]}
                            </div>}
                        </td>
                        <td>
                            <input className={'form-control'}
                                   type="number" value={tipologia.distratos} onChange={ (e)=>{
                                let tipologias = this.props.state.tipologias;
                                tipologias[index].distratos = e.target.value;

                                this.props.updateTipologias(tipologias);

                            }}/>

                            {this.props.state.errors[`${index}.distratos`] && <div className="invalid-feedback" style={{display: "block"}}>

                                {this.props.state.errors[`${index}.distratos`]}
                            </div>}
                        </td>
                        <td>
                            <input className={'form-control'} value={tipologia.estoque} onChange={(e)=>{
                                let tipologias = this.props.state.tipologias;
                                tipologias[index].estoque = e.target.value;

                                this.props.updateTipologias(tipologias);
                            }}  type="number"/>

                            {this.props.state.errors[`${index}.estoque`] && <div className="invalid-feedback" style={{display: "block"}}>

                                {this.props.state.errors[`${index}.estoque`]}
                            </div>}
                        </td>

                    </tr>);
                })}
                </tbody>
            </table>
        </React.Fragment>);
    }

}

export default EstoqueValoresForm;
