import {colorRanges} from "../services/rendaIbgeService";
import React, {Component} from 'react';

function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return `rgb(${r}, ${g}, ${b})`;
}

class RendaIbgeLegend extends Component {

    render() {
        return (<>
                <table className="table table-striped table-condensed legenda-mapa">
                    <thead>
                    <tr>
                        <th scope="col" colSpan="2" style={{textAlign: 'center'}}>

                            <span className="font-weight-bold text-center"
                                  style={{color: 'rgb(0, 72, 14)', fontSize: 16}}>
                                        Renda Per Capita - Setores Censitários
                            </span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {colorRanges.map((item, index) => (<tr key={index} style={styles.legend}>
                    <td style={{...styles.legend, backgroundColor:  hexToRGB(item.color, '0.5')}}>
                                <div style={{width: '13px', height: '13px'}}></div>
                            </td>
                            <td style={styles.legend}>
                                {item.max !== Infinity && <>
                                    {Number(item.min).toLocaleString('pt-BR', { currency: 'BRL', style: 'currency', minimumFractionDigits: 0 })}
                                    &nbsp;- {Number(item.max).toLocaleString('pt-BR', { currency: 'BRL', style: 'currency', minimumFractionDigits: 0 })}
                                </>}
                                {item.max === Infinity && <>
                                    Acima de {Number(item.min).toLocaleString('pt-BR', { currency: 'BRL', style: 'currency', minimumFractionDigits: 0 })}
                                </>}
                            </td>
                        </tr>))}
                    </tbody>
                </table>
        </>);

    }
}

const styles = {
    legend: {
        padding: '5px',
        margin: '4px',
    }
}


export default RendaIbgeLegend;
