import CenterMark from "./CenterMark";
import React from 'react';
import CustomMarker from './CustomMarker';
import PlaceMarker from './PlaceMarker';
import {
    defaultRectangleOptions,
    defaultCircleOptions,
    defaultPolygonOptions,
} from "./mapsDefaultOptions";

const {compose, withProps, lifecycle} = require("recompose");

const {SearchBox} = require("react-google-maps/lib/components/places/SearchBox");
const {withScriptjs, withGoogleMap, GoogleMap, Marker, Circle, KmlLayer} = require("react-google-maps");
const {DrawingManager} = require("react-google-maps/lib/components/drawing/DrawingManager");
const {InfoBox} = require("react-google-maps/lib/components/addons/InfoBox");

var showInfoWindow = false;
const {MarkerClusterer} = require("react-google-maps/lib/components/addons/MarkerClusterer");

const getMarker = (empreendimento, onMarkerClick) => {
    let icon = empreendimento.marker;

    if (empreendimento.customMarker) {
        icon = empreendimento.customMarker;
    }


    let marker = <CustomMarker
        key={empreendimento._id}
        onClick={(e) => {


            return onMarkerClick(empreendimento)
        }}
        empreendimento={empreendimento}
        icon={icon}
        position={
            {
                lat: empreendimento.location.coordinates[1],
                lng:
                    empreendimento.location.coordinates[0]
            }
        }>
    </CustomMarker>

    window.markers.push(marker);

    return marker;
}

const API_KEY = process.env.MIX_GOOGLE_MAPS_API_KEY;
const mapUrl = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places,visualization`;
const MapWithADrawingManager = compose(
    withProps({
        googleMapURL: mapUrl,
        loadingElement: <div style={{height: `100%`}}/>,
        containerElement: <div style={{minHeight: `400px`, height: '90vh'}}/>,
        mapElement: <div style={{height: `100%`}}/>,
    }),
    lifecycle({
        componentDidMount() {
            var refs = {}

            var fillColor = this.props.getColor();

            this.setState({
                zoom: 13,
                fillColor: fillColor,
                markers: [],
                onMapMounted: ref => {
                    window.googleMap = ref;
                    refs.map = ref;
                },
                handleOverlayComplete(e) {
                    this.setDrawingMode(null);

                },
                onBoundsChanged: () => {
                    this.setState({
                        bounds: refs.map.getBounds(),
                        center: refs.map.getCenter(),
                    })
                },
                onSearchBoxMounted: ref => {
                    refs.searchBox = ref;
                },
                markerComplete: (marker, fn) => {

                    let circle = new window.google.maps.Circle({
                        center: marker.getPosition(),
                        radius: this.props.raio,
                    });

                    refs.map.panTo(marker.getPosition());
                    circle.setMap(null);

                    refs.map.center = marker.getPosition();

                    this.setState({
                        zoom: 15
                    });

                    fn(marker, refs.map);

                },
                circleComplete: (circle) => {
                    circle.setOptions({
                        fillColor: this.props.getColor()
                    });

                    this.props.onCircleComplete(circle);

                },

                onPlacesChanged: () => {
                    const place = refs.searchBox.getPlaces();
                    refs.map.panTo(place[0].geometry.location);


                    this.props.setLocation(place);

                    this.setState({
                        zoom: 12,
                    })

                },
            })
        },
    }),
    withScriptjs,
    withGoogleMap
)(props => {

    let getMarkers = () => {
        return props.empreendimentos.map((empreendimento) => {

            if (empreendimento.show_marker) {
                return (
                    getMarker(empreendimento, props.onMarkerClick)
                );
            }
        })
    }

    let getChildren = () => {

        if (props.use_cluster && props.showMarkers) {
            return (
                <MarkerClusterer
                    averageCenter
                    enableRetinaIcons
                    gridSize={60}
                >
                    {getMarkers()}
                </MarkerClusterer>
            );

            return;
        }

        if (!props.use_cluster && props.showMarkers) {
            return getMarkers();
        }

    }

    return (
        <GoogleMap
            defaultZoom={13}
            zoom={props.zoom}
            ref={props.onMapMounted}
            onBoundsChanged={props.onBoundsChanged}
            defaultCenter={new google.maps.LatLng(props.currentPosition.lat, props.currentPosition.lng)}
            defaultOptions={
                {
                    mapTypeControlOptions: {
                        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                        position: google.maps.ControlPosition.TOP_RIGHT
                    },

                    fullscreenControlOptions: {
                        position: google.maps.ControlPosition.BOTTOM_RIGHT,
                    },

                }
            }
        >

            {props.kml_layers.map((kml, index) => {
                if (props.showKMl === true) {
                    return <KmlLayer key={kml}
                                     url={kml}
                                     options={{preserveViewport: true}}
                    />
                }
            })}


            {getChildren()}


            {props.showResaleMarkers && props.revendaLocacao.map((empreendimento, index) => {
                if (empreendimento.tipo_negocio != "Aluguel" && empreendimento.tipo_negocio != "aluguel") {
                    let icon = empreendimento.marker;

                    if (empreendimento.customMarker) {
                        icon = empreendimento.customMarker;
                    }

                    let url = "http://maps.google.com/mapfiles/ms/icons/";
                    url += "red-dot.png";


                    let marker = <CustomMarker key={`${empreendimento._id}_resale_${index}`}
                                               onClick={() => props.onRevendaMarkerClick(empreendimento._id)}
                                               empreendimento={empreendimento}
                                               icon={icon}
                                               position={{
                                                   lat: empreendimento.location.coordinates[1],
                                                   lng: empreendimento.location.coordinates[0]
                                               }}>

                    </CustomMarker>

                    window.markers.push(marker);
                    return (
                        marker
                    );
                }
            })}
            {props.showLocationMarkers && props.revendaLocacao.map((empreendimento, index ) => {
                if (empreendimento.tipo_negocio != "Venda" && empreendimento.tipo_negocio != "venda") {
                    let icon = empreendimento.marker;

                    if (empreendimento.customMarker) {
                        icon = empreendimento.customMarker;
                    }

                    let url = "http://maps.google.com/mapfiles/ms/icons/";
                    url += "red-dot.png";


                    let marker = <CustomMarker key={`location_${empreendimento._id}_${index}`}
                                               onClick={() => props.onRevendaMarkerClick(empreendimento._id)}
                                               empreendimento={empreendimento}
                                               icon={icon}
                                               position={{
                                                   lat: empreendimento.location.coordinates[1],
                                                   lng: empreendimento.location.coordinates[0]
                                               }}>

                    </CustomMarker>

                    window.markers.push(marker);
                    return (
                        marker
                    );
                }
            })}
            {props.selectedIcon !== undefined && <Marker
                icon={props.selectedIcon.icon}
                position={props.selectedIcon.position}
            />}

            {props.pontos.map((marker, index) => {
                return <CenterMark key={index}
                                   onClick={() => {
                                       props.onCenterMarkClick({lat: marker.lat, lng: marker.lng});
                                   }}
                                   position={
                                       {
                                           lat: marker.lat,
                                           lng: marker.lng
                                       }
                                   }
                >
                </CenterMark>
            })}

            {props.show_polos_geradores && props.polos_geradores.map((poloGerador, index) => {

                let marker = <PlaceMarker key={'polo_gerador_' + index}
                                          place={poloGerador}
                                          icon={poloGerador.image}
                                          position={{
                                              lat: poloGerador.position.coordinates[1],
                                              lng: poloGerador.position.coordinates[0]
                                          }}>

                </PlaceMarker>

                return (
                    marker
                );
            })}

            <DrawingManager
                defaultDrawingMode={null}
                onMarkerComplete={(marker) => {
                    props.markerComplete(marker, props.onMarkerComplete);
                    marker.setMap(null);
                }}
                onPolygonComplete={(polygon) => {
                    polygon.setOptions({
                        fillColor: props.getColor(),
                        color: props.getColor(),
                    });

                    props.onPolygonComplete(polygon)
                }}
                onCircleComplete={props.circleComplete}
                onRectangleComplete={(rectangle) => {

                    rectangle.setOptions({
                        fillColor: props.getColor(),
                        color: props.getColor()
                    });

                    props.onRectangleComplete(rectangle)
                }}
                onOverlayComplete={props.handleOverlayComplete}
                defaultOptions={{
                    drawingControl: true,
                    drawingControlOptions: {
                        position: google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: [
                            google.maps.drawing.OverlayType.CIRCLE,
                            google.maps.drawing.OverlayType.POLYGON,
                            google.maps.drawing.OverlayType.RECTANGLE,
                            google.maps.drawing.OverlayType.MARKER,
                        ],
                    },
                    circleOptions: defaultCircleOptions,
                    polygonOptions: defaultPolygonOptions,
                    rectangleOptions: defaultRectangleOptions
                }}
            />
            <SearchBox
                ref={props.onSearchBoxMounted}
                bounds={props.bounds}
                controlPosition={google.maps.ControlPosition.TOP_CENTER}
                onPlacesChanged={props.onPlacesChanged}
            >

                <input
                    type="text"
                    placeholder="Digite um endereço"
                    id="search_box"
                    style={{
                        boxSizing: `border-box`,
                        border: `1px solid #527440`,
                        width: `40%`,
                        height: `50px`,
                        marginTop: `5px`,
                        padding: `0 12px`,
                        borderRadius: `3px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                    }}
                />

            </SearchBox>
        </GoogleMap>
    )
});

export default MapWithADrawingManager;
