import React from "react";
import Table from "./Table";
import Grafico from "./Grafico";


class TabLancamento extends React.Component {
    componentDidMount() { }

    filtroPorTipo = (rows, tipo) => {
        return rows.filter((index) => { if (index.tipo == tipo) { return index } });
    }
    validate = (data) => {
        return data != undefined && data != [] && data.length != 0;
    }
    render() {
        let exibirTabelas = true;
        let exibirGraficos = this.props.state.exibirGraficos;
        return (
            <>
                {this.props.state.tipos.some(option => option.label === 'Vertical')
                    && this.validate(this.props.state.dataVendasTipologia)
                    && this.validate(this.props.state.dataVendasTipo)
                    &&
                    <>
                        {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                            tipo_detalhamento='Tipologia'
                            titulo='Unidades Vendidas Líquidas por Tipologia'
                            rows={this.props.state.dataVendasTipologia.unidadesVendidas}
                            detalhesLabel={this.props.state.detalhesLabel}
                            response_period_type={this.props.state.response_period_type}
                            periodos={this.props.state.periodos}
                            setLoading={this.setLoading}
                            variacao={this.props.state.response_variacao}
                            periodos_variacao={this.props.state.periodos_variacao}
                            totais={this.filtroPorTipo(this.props.state.dataVendasTipo.unidadesVendidas, "Vertical")}
                            id="excel9"

                        />}
                        {exibirGraficos && <Grafico
                            tipo_detalhamento='Tipologia'
                            titulo='Unidades Vendidas Líquidas por Tipologia'
                            rows={this.props.state.dataVendasTipologia.unidadesVendidas}
                            detalhesLabel={this.props.state.detalhesLabel}
                            response_period_type={this.props.state.response_period_type}
                            periodos={this.props.state.periodos}
                            setLoading={this.setLoading}
                            variacao={this.props.state.response_variacao}
                            periodos_variacao={this.props.state.periodos_variacao}
                        />}
                        {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                            tipo_detalhamento='Tipologia'
                            titulo='VGV Unidades Vendidas Líquidas Por Tipologia'
                            rows={this.props.state.dataVendasTipologia.vgv}
                            detalhesLabel={this.props.state.detalhesLabel}
                            response_period_type={this.props.state.response_period_type}
                            periodos={this.props.state.periodos}
                            setLoading={this.setLoading}
                            variacao={this.props.state.response_variacao}
                            periodos_variacao={this.props.state.periodos_variacao}
                            totais={this.filtroPorTipo(this.props.state.dataVendasTipo.vgv, "Vertical")}
                            id="excel10"

                        />}
                        {exibirGraficos && <Grafico
                            tipo_detalhamento='Tipologia'
                            titulo='VGV Unidades Vendidas Líquidas Por Tipologia'
                            rows={this.props.state.dataVendasTipologia.vgv}
                            detalhesLabel={this.props.state.detalhesLabel}
                            response_period_type={this.props.state.response_period_type}
                            periodos={this.props.state.periodos}
                            setLoading={this.setLoading}
                            variacao={this.props.state.response_variacao}
                            periodos_variacao={this.props.state.periodos_variacao}
                        />}
                    </>
                }
                {this.validate(this.props.state.dataVendasPadrao)
                    && this.validate(this.props.state.dataVendasTipo) &&
                    <>
                    {
                        this.props.state.tipos.some(option => option.label === 'Vertical') &&
                            <>
                                {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                    tipo_detalhamento='Padrão'
                                    titulo='Unidades Vendidas Líquidas Vertical por Padrão'
                                    rows={this.filtroPorTipo(this.props.state.dataVendasPadrao.unidadesVendidas, "Vertical")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                    totais={this.filtroPorTipo(this.props.state.dataVendasTipo.unidadesVendidas, "Vertical")}
                                    id="excel11"

                                />}
                                {exibirGraficos && <Grafico
                                    tipo_detalhamento='Padrão'
                                    titulo='Unidades Vendidas Líquidas Vertical por Padrão'
                                    rows={this.filtroPorTipo(this.props.state.dataVendasPadrao.unidadesVendidas, "Vertical")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                />}
                                {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                                    tipo='Vertical'
                                    tipo_detalhamento='Padrão'
                                    titulo='VGV Unidades Vendidas Líquidas Vertical Por Padrão'
                                    rows={this.filtroPorTipo(this.props.state.dataVendasPadrao.vgv, "Vertical")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                    totais={this.filtroPorTipo(this.props.state.dataVendasTipo.vgv, "Vertical")}
                                    id="excel12"

                                />}
                                {exibirGraficos && <Grafico
                                    tipo='Vertical'
                                    tipo_detalhamento='Padrão'
                                    titulo='VGV Unidades Vendidas Líquidas Vertical Por Padrão'
                                    rows={this.filtroPorTipo(this.props.state.dataVendasPadrao.vgv, "Vertical")}
                                    detalhesLabel={this.props.state.detalhesLabel}
                                    response_period_type={this.props.state.response_period_type}
                                    periodos={this.props.state.periodos}
                                    setLoading={this.setLoading}
                                    variacao={this.props.state.response_variacao}
                                    periodos_variacao={this.props.state.periodos_variacao}
                                />}
                            </>
                    }
                        {this.props.state.tipos.some(option => option.label === 'Horizontal') &&

                    <>
                        {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                            tipo='Horizontal'
                            tipo_detalhamento='Padrão'
                            titulo='Unidades Vendidas Líquidas Horizontal'
                            rows={this.filtroPorTipo(this.props.state.dataVendasPadrao.unidadesVendidas, "Horizontal")}
                            detalhesLabel={this.props.state.detalhesLabel}
                            response_period_type={this.props.state.response_period_type}
                            periodos={this.props.state.periodos}
                            setLoading={this.setLoading}
                            variacao={this.props.state.response_variacao}
                            periodos_variacao={this.props.state.periodos_variacao}
                            totais={this.filtroPorTipo(this.props.state.dataVendasTipo.unidadesVendidas, "Horizontal")}
                            id="excel13"

                        />}
                        {exibirGraficos && <Grafico
                            tipo='Horizontal'
                            tipo_detalhamento='Padrão'
                            titulo='Unidades Vendidas Líquidas Horizontal'
                            rows={this.filtroPorTipo(this.props.state.dataVendasPadrao.unidadesVendidas, "Horizontal")}
                            detalhesLabel={this.props.state.detalhesLabel}
                            response_period_type={this.props.state.response_period_type}
                            periodos={this.props.state.periodos}
                            setLoading={this.setLoading}
                            variacao={this.props.state.response_variacao}
                            periodos_variacao={this.props.state.periodos_variacao}
                        />}
                        {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                            tipo='Horizontal'
                            tipo_detalhamento='Padrão'
                            titulo='VGV Unidades Vendidas Líquidas Horizontal'
                            rows={this.filtroPorTipo(this.props.state.dataVendasPadrao.vgv, "Horizontal")}
                            detalhesLabel={this.props.state.detalhesLabel}
                            response_period_type={this.props.state.response_period_type}
                            periodos={this.props.state.periodos}
                            setLoading={this.setLoading}
                            variacao={this.props.state.response_variacao}
                            periodos_variacao={this.props.state.periodos_variacao}
                            totais={this.filtroPorTipo(this.props.state.dataVendasTipo.vgv, "Horizontal")}
                            id="excel14"

                        />}
                        {exibirGraficos && <Grafico
                            tipo='Horizontal'
                            tipo_detalhamento='Padrão'
                            titulo='VGV Unidades Vendidas Líquidas Horizontal'
                            rows={this.filtroPorTipo(this.props.state.dataVendasPadrao.vgv, "Horizontal")}
                            detalhesLabel={this.props.state.detalhesLabel}
                            response_period_type={this.props.state.response_period_type}
                            periodos={this.props.state.periodos}
                            setLoading={this.setLoading}
                            variacao={this.props.state.response_variacao}
                            periodos_variacao={this.props.state.periodos_variacao}
                        />}
                    </>
                }
                {this.props.state.tipos.some(option => option.label === 'Comercial') &&
                    <>
                        {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                            tipo='Comercial'
                            tipo_detalhamento='Padrão'
                            titulo='Unidades Vendidas Líquidas Comercial'
                            rows={this.filtroPorTipo(this.props.state.dataVendasPadrao.unidadesVendidas, "Comercial")}
                            detalhesLabel={this.props.state.detalhesLabel}
                            response_period_type={this.props.state.response_period_type}
                            periodos={this.props.state.periodos}
                            setLoading={this.setLoading}
                            variacao={this.props.state.response_variacao}
                            periodos_variacao={this.props.state.periodos_variacao}
                            totais={this.filtroPorTipo(this.props.state.dataVendasTipo.unidadesVendidas, "Comercial")}

                            id="excel15"

                        />}
                        {exibirGraficos && <Grafico
                            tipo='Comercial'
                            tipo_detalhamento='Padrão'
                            titulo='Unidades Vendidas Líquidas Comercial'
                            rows={this.filtroPorTipo(this.props.state.dataVendasPadrao.unidadesVendidas, "Comercial")}
                            detalhesLabel={this.props.state.detalhesLabel}
                            response_period_type={this.props.state.response_period_type}
                            periodos={this.props.state.periodos}
                            setLoading={this.setLoading}
                            variacao={this.props.state.response_variacao}
                            periodos_variacao={this.props.state.periodos_variacao}
                        />}
                        {exibirTabelas && <Table exibirTabelas={this.props.state.exibirTabelas}
                            tipo='Comercial'
                            tipo_detalhamento='Padrão'
                            titulo='VGV Unidades Vendidas Líquidas Comercial'
                            rows={this.filtroPorTipo(this.props.state.dataVendasPadrao.vgv, "Comercial")}
                            detalhesLabel={this.props.state.detalhesLabel}
                            response_period_type={this.props.state.response_period_type}
                            periodos={this.props.state.periodos}
                            setLoading={this.setLoading}
                            variacao={this.props.state.response_variacao}
                            periodos_variacao={this.props.state.periodos_variacao}
                            totais={this.filtroPorTipo(this.props.state.dataVendasTipo.vgv, "Comercial")}

                            id="excel16"
                        />}
                        {exibirGraficos && <Grafico
                            tipo='Comercial'
                            tipo_detalhamento='Padrão'
                            titulo='VGV Unidades Vendidas Líquidas Comercial'
                            rows={this.filtroPorTipo(this.props.state.dataVendasPadrao.vgv, "Comercial")}
                            detalhesLabel={this.props.state.detalhesLabel}
                            response_period_type={this.props.state.response_period_type}
                            periodos={this.props.state.periodos}
                            setLoading={this.setLoading}
                            variacao={this.props.state.response_variacao}
                            periodos_variacao={this.props.state.periodos_variacao}
                        />}
                    </>
                }
            </>
                }
            </>
        );
    }
}

export default TabLancamento;
