import {React, Component} from 'react';


export default class TableFormVendas extends Component{

    render() {
        return (
            <table className="table table-striped table-sm table-bordered">
                <thead>
                <tr style={styles.titleRow}>
                    <th>Mês</th>
                    <th>Tipologia</th>
                    <th>Estoque</th>
                    <th>N° de vendas</th>
                </tr>
                </thead>
                <tbody>
                {this.props.vendas.map((venda, index) => {

                    let rowClassName = "table-success";

                    if( venda.estoque < 0 ){
                        rowClassName = "table-danger";
                    }

                    return (
                        <tr key={index} className={rowClassName}>
                            <td>
                                <span style={{fontWeight: "bold"}}>{venda.mes}</span>
                            </td>
                            <td>
                                <span style={{fontWeight: "bold"}}>{venda.tipologia}</span>
                            </td>
                            <td>
                                <span style={{fontWeight: "bold"}}>{venda.estoque}</span>
                            </td>
                            <td>
                                <input type="number" className="form-control"
                                       value={venda.numero_vendas}
                                       onChange={async(e) => {
                                           venda.old_quantidade_venda = venda.numero_vendas;
                                           await this.props.updateVendaQuantidadeVenda( venda, parseInt(e.target.value), this.props.periodo_vendas )
                                       }}
                            /></td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        );
    }
}

const styles = {
    titleBox: {
        backgroundColor: '#5B7537', color: 'white', padding: '10px',
    },

    titleRow: {
        backgroundColor: '#5B7537', color: 'white', padding: '10px',
    }
}
