const padroes = [
    { 'label': 'Alto', value: 'Alto' },
    { 'label': 'Alto +', value: 'Alto +' },
    { 'label': 'Condomínio de Casas/Sobrados', value: 'Condomínio de Casas/Sobrados' },
    { 'label': 'Condomínio de Chácaras', value: 'Condomínio de Chácaras' },
    { 'label': 'Econômico', value: 'Econômico' },
    { 'label': 'Especial', value: 'Especial' },
    { 'label': 'Loteamento Aberto', value: 'Loteamento Aberto' },
    { 'label': 'Loteamento Comercial', value: 'Loteamento Comercial' },
    { 'label': 'Loteamento Fechado', value: 'Loteamento Fechado' },
    { 'label': 'Luxo', value: 'Luxo' }, { 'label': 'Médio', value: 'Médio' },
    { 'label': 'Própria', value: 'Própria' },
    { 'label': 'Standard', value: 'Standard' },
    { 'label': 'Super Econômico', value: 'Super Econômico' },
    { 'label': 'Super Luxo', value: 'Super Luxo' },
    { 'label': 'Terreno', value: 'Terreno' }
]

module.exports = padroes;