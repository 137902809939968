class IsochroneService {
    constructor() {
        this.service = new google.maps.DistanceMatrixService();
        this.debug = false;
        this.requests = 2;
        this.radius = {
            duration: { walking: 11, bicycling: 30, driving: 60, transit: 10 },
            distance: { walking: 9, bicycling: 8, driving: 7, transit: 7 }
        };
    }

    log(text) {
        if (this.debug) console.log(text);
    }

    compute({ lat, lng, mode, type, value, slices = 8, cycles = 10, precision = 5, callback }) {
        if (!callback) {
            console.error('Forneça o Callback');
            return;
        }

        const positions = [];
        const computation = {
            lat,
            lng,
            mode: mode.toUpperCase(),
            type,
            value: parseFloat(value),
            precision: parseInt(precision) / 100,
            system: google.maps.UnitSystem.METRIC,
            positions,
            cycle: 0,
            cycles: parseInt(cycles),
            slices: parseInt(slices),
            errors: 0
        };

        if (computation.slices < 4 || computation.slices > 25) {
            return callback('KO', 'Valor inválido para slices: entre 4 e 25');
        }
        if (computation.cycles < 2 || computation.cycles > 50) {
            return callback('KO', 'Valor inválido para cycles: entre 2 e 50');
        }
        if (!lat || !lng) {
            return callback('KO', 'Faltando latitude ou longitude');
        }
        if (!mode || !mode.match(/^(walking|bicycling|driving|transit)$/)) {
            return callback('KO', 'Modo inválido ou faltando (walking, bicycling, driving, transit)');
        }
        if (!type || (type !== 'duration' && type !== 'distance')) {
            return callback('KO', 'Tipo inválido ou faltando (duration, distance)');
        }

        if (!computation.value) {
            return callback('KO', 'Valor inválido ou faltando');
        }

        const radius = this.radius[type][mode] * computation.value / 1000000;
        const adjustedRadius =  radius;

        for (let s = 0; s < computation.slices; s++) {
            positions.push({
                radians: 2 * Math.PI * s / computation.slices,
                min: { radius: 0, value: 0 },
                max: { radius: 0, value: 0 },
                radius: 0.01,
                lat: 0,
                lng: 0,
                duration: 0,
                distance: 0,
                found: false
            });
        }

        const cycle = () => {
            if (computation.cycle++ >= computation.cycles) {
                positions.push(positions[0]);
                return callback('OK', positions);
            }

            const destinations = [];
            const relations = [];

            positions.forEach((position, index) => {
                if (!position.found) {
                    position.lat = computation.lat + position.radius * Math.cos(position.radians);
                    position.lng = computation.lng + position.radius * Math.sin(position.radians);
                    destinations.push(new google.maps.LatLng(position.lat, position.lng));
                    relations.push(index);
                }
            });

            if (!destinations.length) return callback('OK', positions);

            this.service.getDistanceMatrix({
                origins: [new google.maps.LatLng(computation.lat, computation.lng)],
                destinations,
                travelMode: computation.mode,
                unitSystem: computation.system,
            }, (data, result) => {
                if (result !== 'OK') {
                    if (result === 'OVER_QUERY_LIMIT' && computation.errors++ <= 10) {
                        computation.cycle--;
                        this.requests = Math.max(0.5, this.requests - 0.5);
                        setTimeout(cycle, 2000);
                        return;
                    }
                    return callback(result);
                }

                if (!data.rows[0].elements || data.rows[0].elements.length !== destinations.length) {
                    return callback('LENGTH');
                }

                data.rows[0].elements.forEach((d, i) => {
                    const position = positions[relations[i]];
                    const value = parseFloat(d[computation.type]?.value);

                    if (d.status !== 'OK') return;

                    if (value < computation.value) {
                        if (!position.min.radius || (position.radius > position.min.radius && value > position.min.value)) {
                            position.min.radius = position.radius;
                            position.min.value = value;
                        }
                    } else if (value > computation.value) {
                        if (!position.max.radius || (position.radius < position.max.radius && value < position.max.value)) {
                            position.max.radius = position.radius;
                            position.max.value = value;
                        }
                    }

                    if (Math.abs(value - computation.value) / computation.value < computation.precision) {
                        position.found = true;
                    } else {
                        if (!position.min.radius) {
                            position.radius = position.max.radius * computation.value / position.max.value;
                        } else if (!position.max.radius) {
                            position.radius = position.min.radius * computation.value / position.min.value;
                        } else {
                            const minWeight = 1 / Math.abs(position.min.value - computation.value);
                            const maxWeight = 1 / Math.abs(position.max.value - computation.value);
                            position.radius = (position.min.radius * minWeight + position.max.radius * maxWeight) / (minWeight + maxWeight);
                        }
                    }
                });

                setTimeout(cycle, parseInt(1000 / this.requests));
            });
        };

        cycle();
    }
}

export default IsochroneService;
