import React, { Component } from "react";
import { render } from "react-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
const formatacoes = require('../../util/formatacoes')


class Grafico extends Component {
    constructor(state) {
        super(state);
        this.state = {};
    }

    componentDidMount = async () => { };

    getName = (tipo_detalhamento, index) => {
        var arrayTipos = {
            "Tipologia": formatacoes.formatTipologia(index.tipologia),
            "Padrão": index.padrao,
            "Tipo": index.tipo
        }

        return arrayTipos[tipo_detalhamento];
    };

    render() {

        let series = [];
        let isIvv = false;
        this.props.rows.map((row, index) => {
            let name = this.getName(this.props.tipo_detalhamento, row)
            let data = [];
            {
                this.props.periodos.map((periodo, index) => {
                    if (row.detalhe == 'ivv') {
                        isIvv = true;
                        return (data.push(parseFloat(formatacoes.formatNumberAsPercentage(row[periodo]).replace(",", "."))))
                    }
                    return (data.push(parseFloat(formatacoes.formatNumber(row[periodo]).replaceAll('.', ''))))
                })
            }

            series.push({ name: name, data: data })
        })

        const options = {
            chart: {
                plotBackgroundImage: 'https://geobrain.com.br/img/thumb-geobrain-mark.png',
            },
            title: {
                text: this.props.titulo,
            },
            yAxis: {
                title: {
                    text: this.props.titulo
                }
            },
            xAxis: {
                categories: this.props.periodos.map((periodo) => { return formatacoes.formatPeriodString(periodo, this.props.response_period_type) })
            },
            exporting: {
                enabled: true
            },
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: true
                }
            },
            series: series
        }

        if (isIvv) {
            options.tooltip = {
                pointFormat: '{series.name}: {point.y}%'
            };
            options.plotOptions.series = {
                dataLabels: {
                    enabled: true,
                    format: '{point.y:.2f}%'
                }
            }
        }
        else {
            options.tooltip = {
                pointFormatter: function () { return this.series.name + " " + Highcharts.numberFormat(this.y, 0, ',', '.') }
            };
            options.plotOptions.series = {
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return Highcharts.numberFormat(this.y, 0, ',', '.');
                    }
                }
            }
        }

        return (
            <>
                <br />
                <div>
                    <div className="card">
                        <div className="card-body">
                            <div>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={options}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Grafico;
