export default {
    domicilios_por_condicao_ocupacao:{
        dados:{
            'alugados' : 'Alugados',
            'cedidos' : 'Cedidos',
            'proprios' : 'Próprios',
        },
        data_label: 'Condição de Ocupação',
        title: 'Domicílios por Condição de Ocupação',
        uses_subheader: true,
    },
    domicilios_por_faixa_de_renda: {
        dados:{
            'ate_um_salario' : 'Até 1 Salário',
            'de_1_a_2_salarios' : 'De 1 a 2 Salários',
            'de_2_a_3_salarios' : 'De 2 a 3 Salários',
            'de_3_a_5_salarios' : 'De 3 a 5 Salários',
            'de_5_a_10_salarios' : 'De 5 a 10 Salários',
            'mais_de_10_salarios' : 'Mais de 10 Salários',
        },
        data_label: 'Faixas de Renda',
        title: 'Domicílios por Faixa de Renda',
        uses_subheader: true,
    },
    domicilios_por_numero_moradores: {
        dados:{
            'um_moradores' : '1 Morador',
            'dois_moradores' : '2 Moradores',
            'tres_moradores' : '3 Moradores',
            'quatro_moradores' : '4 Moradores',
            'cinco_moradores' : '5 Moradores',
            'seis_moradores' : '6 Moradores',
            'sete_moradores' : '7 Moradores',
            'oito_moradores' : '8 Moradores',
            'nove_moradores' : '9 Moradores',
            'dez_moradores' : '10 Moradores',
        },
        data_label: 'Número de Moradores',
        title: 'Domicílios por Número de Moradores',
        uses_subheader: true,
    },
    domicilios_por_tipo_moradia:{
        dados:{
            'apartamentos' : 'Apartamentos',
            'casas' : 'Casas',
        },
        data_label: 'Tipos de Moradia',
        title: 'Domicílios por Tipo de Moradia',
        uses_subheader: true,
    },
    indice_verticalizacao: {
        dados:{
            'apartamentos' : 'Apartamentos',
            'indice_verticalizacao' : 'Índice de Verticalização',
        },
        data_label: 'Tipos de Moradia',
        title: 'Índice de Verticalização',
        uses_subheader: false,
    },
    faixa_etaria:{
        dados: {
            total_0_19: 'de 0 a 19 anos',
            total_20_24: 'de 20-24',
            total_25_34: 'de 25-34',
            total_35_49: 'de 35-49',
            total_50_59: 'de 50-59',
            total_60_mais: 'mais de 60',
        },
        data_label: 'Faixas Etárias',
        title: 'População por Faixa Etária',
        uses_subheader: true,
    },
    populacao_por_faixa_de_renda:{
        dados:{
            'ate_um_salario' : 'Até 1 Salário',
            'de_1_a_2_salarios' : 'De 1 a 2 Salários',
            'de_2_a_3_salarios' : 'De 2 a 3 Salários',
            'de_3_a_5_salarios' : 'De 3 a 5 Salários',
            'de_5_a_10_salarios' : 'De 5 a 10 Salários',
            'de_10_a_15_salarios' : 'De 10 a 15 Salários',
            'de_15_a_20_salarios' : 'De 15 a 20 Salários',
            'mais_de_20_salarios' : 'Mais de 20 Salários',
        },
        data_label: 'Faixas de Renda',
        title: 'População por Faixa de Renda',
        uses_subheader: true,
    },


}
