import collect from "collect.js";


const transform = (data, detalhes, periods, tipos, tipoDeDetalhamento, extraConfig = {} ) => {    
    
    if( tipoDeDetalhamento == 'Estado'){
        return transformDataGroupedByState(data, detalhes, periods, tipos, extraConfig );
    }
    if( tipoDeDetalhamento == 'Padrão'){
        return transformDataGroupedByPattern(data, detalhes, periods, tipos, extraConfig );
    }

    if( tipoDeDetalhamento == 'Tipo'){
        return transformDataGroupedByType(data, detalhes, periods, tipos, extraConfig );
    }

    if( tipoDeDetalhamento == 'Bairro'){
        return transformDataGroupedByNeighborhood(data, detalhes, periods, tipos, extraConfig );
    }

    if( tipoDeDetalhamento == 'Cidade'){
        return transformDataGroupedByCity(data, detalhes, periods, tipos, extraConfig );
    }

    if( tipoDeDetalhamento == 'Tipologia'){
        return transformDataGroupedByTypology(data, detalhes, periods, tipos, extraConfig );
    }

    if( tipoDeDetalhamento == 'Ano de Lançamento'){
        return transformDataGroupedByReleaseYear(data, detalhes, periods, tipos, extraConfig );
    }


    if( tipoDeDetalhamento == 'Incorporadora')
    {
        return transformDataGroupedByIncorporator(data, detalhes, periods, tipos, extraConfig );
    }

    if( tipoDeDetalhamento == 'Estágio de Obra e Padrão'){

        return transformDataGroupedByStageOfWork(data, detalhes, periods, tipos, extraConfig );

    }

    if( tipoDeDetalhamento == 'Estágio de Obra e Tipo'){

        return transformDataGroupedByStageOfWorkAndType(data, detalhes, periods, tipos, extraConfig );

    }

    if( tipoDeDetalhamento == 'Cluster'){
        return transformDataGroupedByCluster(data, detalhes, periods, tipos, extraConfig );
    }


}



var tiposdefaultTipos = ["Vertical", "Horizontal", "Comercial", "Hotel"];

var patternsByType = {
    'Vertical' :[
        'Especial',
        'Econômico',
        'Standard',
        'Médio',
        'Alto',
        'Luxo',
        'Super Luxo'
    ],
    'Horizontal' :[
        'Condomínio de casas e sobrados',
        'Condomínio de Casas/Sobrados',
        'Loteamento Fechado',
        'Condomínios de chácaras',
        'Condomínios de Chácaras',
        'Loteamento Aberto',
        'Loteamento Comercial',
        'Condomínio de Chácaras',

        'Especial',
        'Econômico',
        'Standard',
        'Médio',
        'Alto',
        'Luxo',
        'Super Luxo'
    ],
    'Comercial' :[
        'Econômico',
        'Standard',
        'Médio',
        'Alto',
        'Alto +'
    ]
}


const transformDataGroupedByType = ( data, detalhes, periods, tipos, extraConfig )=>{
    let rows = [];

    data = collect(data);
    
    for( let y =0; y < detalhes.length; y++ ){
        for( let x = 0; x < tipos.length; x++ ) {

            let row = {
                detalhe: detalhes[y],
                tipo: tipos[x],
            };

            let totalSumDetalhe = 0;
        
            for( let i = 0; i < periods.length; i++ ) {

                let period = periods[i];

                if( typeof period == 'number'){
                    period = period.toString();
                }
            
                let value = data.where('tipo', tipos[x]).where('periodo', period).pluck(detalhes[y]).first() || 0;
                row[period] = value;

                totalSumDetalhe += value;                
                
            }
            
            row.total = totalSumDetalhe;
        
            rows.push(row);
        }
    }

    return rows;
}

const sortPatterns = (patterns)=>{

    let sortedPatterns = [];

    let patternsOrder = [
        'Especial',
        'Econômico',
        'Standard',
        'Médio',
        'Alto',
        'Luxo',
        'Super Luxo',
        'Condomínio de casas e sobrados',
        'Condomínio de Casas/Sobrados',
        'Loteamento Fechado',
        'Condomínio de Chácaras',
        'Condomínios de chácaras',
        'Condomínios de Chácaras',
        'Loteamento Aberto',
        'Loteamento Comercial',
        'Alto +'
    ];

    for( let i = 0; i < patternsOrder.length; i++ ){
        if( patterns.includes(patternsOrder[i])){
            sortedPatterns.push(patternsOrder[i]);
        }
    }

    return sortedPatterns;
}



const transformDataGroupedByPattern = ( data, detalhes, periods, tipos, extraConfig  )=>{
    let rows = [];

    let patterns = sortPatterns(collect(data).pluck('padrao').unique().toArray());

    data = collect(data);
    
    for( let i = 0; i < detalhes.length; i++ )
    {
        for( let x = 0; x < tipos.length; x++ )
        {
            for( let z = 0; z < patterns.length; z++ )
            {

                if(tipos[x] === 'Hotel' || !patternsByType[tipos[x]].includes(patterns[z])){
                    continue;
                }

                let row = {
                    detalhe: detalhes[i],
                    tipo: tipos[x],
                    padrao: patterns[z]
                };

                let totalSumDetalhe = 0;
            
                for( let k = 0; k < periods.length; k++ )
                {
                    let period = periods[k];

                    if( typeof period == 'number'){
                        period = period.toString();
                    }
                
                    let value = data
                        .where('tipo', tipos[x])
                        .where('periodo', period)
                        .where('padrao', patterns[z])
                        .pluck(detalhes[i]).first() || 0;
                    
                    row[period] = value;

                    totalSumDetalhe += value;
                }
                
                row.total = totalSumDetalhe;
            
                rows.push(row);
                
            }

        }

    }

    return rows;
}


const transformDataGroupedByNeighborhood = (data, detalhes, periods, tipos, extraConfig)=>{
       

    data = collect(data);
    let cidades = data.pluck('cidade').unique().toArray();

    if( tipos.length == 0){
        tipos = tiposdefaultTipos;
    }

    let rows = [];
    
    for( let x =0; x < detalhes.length; x++ ){
        for( let y = 0; y < cidades.length; y++){
            let bairros = data.where('cidade', cidades[y]).pluck('bairro').unique().toArray().sort();

            for( let z = 0; z < bairros.length; z++){
                

                for( let j = 0; j < tipos.length; j++)
                {
                    let row = {
                        detalhe: detalhes[x],
                        cidade: cidades[y],
                        bairro: bairros[z],
                        tipo: tipos[j]
    
                    };

                    let totalSumDetalhe = 0;
                    for( let i = 0; i < periods.length; i++ ){
                        let period = periods[i];

                        if( typeof period == 'number'){
                            period = period.toString();
                        }
                    
                        let value = data.
                            where('cidade', cidades[y])
                            .where('bairro', bairros[z])
                            .where('periodo', period)
                            .where('tipo', tipos[j])
                            .pluck(detalhes[x])
                            .first() || 0;
                        
                            row[period] = value;

                            totalSumDetalhe += value;
                    }

                    row.total = totalSumDetalhe;
                    rows.push(row);

                }
            }
        }
    }

    return rows;
}

const transformDataGroupedByCity = ( data, detalhes, periods, tipos, extraConfig )=>{
    data = collect(data);

    let cidades = data.pluck('cidade').unique().toArray().sort();

    if( tipos.length == 0){
        tipos = tiposdefaultTipos;
    }

    let rows = [];

    for( let x =0; x < detalhes.length; x++ )
    {
        for( let y = 0; y < cidades.length; y++)
        {
            for( let j = 0; j < tipos.length; j++)
            {
                let row = {
                    detalhe: detalhes[x],
                    cidade: cidades[y],
                    tipo: tipos[j]

                };

                let totalSumDetalhe = 0;
                for( let i = 0; i < periods.length; i++ )
                {
                    let period = periods[i];

                    if( typeof period == 'number'){
                        period = period.toString();
                    }
                
                    let value = data.
                         where('cidade', cidades[y])
                        .where('periodo', period)
                        .where('tipo', tipos[j])
                        .pluck(detalhes[x])
                        .first() || 0;
                    
                        row[period] = value;

                        totalSumDetalhe += value;
                }

                row.total = totalSumDetalhe;
                rows.push(row);

            }

        }       

    }

    return rows;
}

const transformDataGroupedByState = ( data, detalhes, periods, tipos, extraConfig )=>{
    data = collect(data);

    let states = data.pluck('estado').unique().toArray().sort();

    if( tipos.length == 0){
        tipos = tiposdefaultTipos;
    }

    let rows = [];

    for( let x =0; x < detalhes.length; x++ )
    {
        for( let y = 0; y < states.length; y++)
        {
            for( let j = 0; j < tipos.length; j++)
            {
                let row = {
                    detalhe: detalhes[x],
                    estado: states[y],
                    tipo: tipos[j]

                };

                let totalSumDetalhe = 0;
                for( let i = 0; i < periods.length; i++ )
                {
                    let period = periods[i];

                    if( typeof period == 'number'){
                        period = period.toString();
                    }
                
                    let value = data.
                         where('estado', states[y])
                        .where('periodo', period)
                        .where('tipo', tipos[j])
                        .pluck(detalhes[x])
                        .first() || 0;
                    
                        row[period] = value;

                        totalSumDetalhe += value;
                }

                row.total = totalSumDetalhe;
                rows.push(row);

            }

        }       

    }

    return rows;
}


const transformDataGroupedByTypology = ( data, detalhes, periods, tipos, extraConfig  )=>{
    
    data = collect(data);

    let tipologias = [1,2,3,4];

    if( tipos.length == 0){
        tipos = ['Vertical'];
    }

    let rows = [];

    for( let x =0; x < detalhes.length; x++ )
    {
        for( let y = 0; y < tipologias.length; y++)
        {
            
            let row = {
                detalhe: detalhes[x],
                tipologia: tipologias[y],
            };

            let totalSumDetalhe = 0;
            for( let i = 0; i < periods.length; i++ )
            {
                let period = periods[i];

                if( typeof period == 'number'){
                    period = period.toString();
                }
            
                let value = data.
                        where('tipologia', tipologias[y])
                    .where('periodo', period)
                    .pluck(detalhes[x])
                    .first() || 0;
                
                    row[period] = value;

                    totalSumDetalhe += value;
            }

            row.total = totalSumDetalhe;
            rows.push(row);            

        }        
    }

    return rows;
}


const transformDataGroupedByReleaseYear = (data, detalhes, periods, tipos, extraConfig  )=>{

    data = collect(data);

    let years = data.pluck('ano_lancamento').unique().toArray().sort();

    if( tipos.length == 0){
        tipos = tiposdefaultTipos;
    }

    let rows = [];

    for( let x =0; x < detalhes.length; x++ )
    {
        for( let y = 0; y < years.length; y++)
        {
            
            let row = {
                detalhe: detalhes[x],
                ano_lancamento: years[y],
            };

            let totalSumDetalhe = 0;
            for( let i = 0; i < periods.length; i++ )
            {
                let period = periods[i];

                if( typeof period == 'number'){
                    period = period.toString();
                }
            
                let value = data.
                     where('ano_lancamento', years[y])
                    .where('periodo', period)
                    .pluck(detalhes[x])
                    .first() || 0;
                
                    row[period] = value;

                    totalSumDetalhe += value;
            }

            row.total = totalSumDetalhe;
            rows.push(row);

            

        }
    }


    return rows;

}

const transformDataGroupedByIncorporator = (data, detalhes, periods, tipos, extraConfig  )=>{
    data = collect(data);

    let rows = [];
    let incorporators = data.pluck('incorporadora').unique().toArray().sort();

    if( tipos.length == 0){
        tipos = tiposdefaultTipos;
    }

    for( let x =0; x < detalhes.length; x++ )
    {
        for( let y = 0; y < incorporators.length; y++)
        {
            for( let j = 0; j < tipos.length; j++)
            {
                let row = {
                    detalhe: detalhes[x],
                    incorporadora: incorporators[y],
                    tipo: tipos[j]

                };

                let totalSumDetalhe = 0;
                for( let i = 0; i < periods.length; i++ )
                {
                    let period = periods[i];

                    if( typeof period == 'number'){
                        period = period.toString();
                    }
                
                    let value = data.
                         where('incorporadora', incorporators[y])
                        .where('periodo', period)
                        .where('tipo', tipos[j])
                        .pluck(detalhes[x])
                        .first() || 0;
                    
                        row[period] = value;

                        totalSumDetalhe += value;
                }

                row.total = totalSumDetalhe;
                rows.push(row);

            }
        }

    }

    return rows;
}

const transformDataGroupedByStageOfWork = (data, detalhes, periods, tipos, extraConfig  )=>{
    data = collect(data);

    let estagios = data.pluck('estagio').unique().toArray().sort();
    let padroes = data.pluck('padrao').unique().toArray().sort();

    let rows = [];

    if( tipos.length == 0){
        tipos = tiposdefaultTipos;
    }

    for( let x =0; x < detalhes.length; x++ )
    {
        for( let y = 0; y < estagios.length; y++)
        {
            for( let j = 0; j < padroes.length; j++)
            {


                for( let k = 0; k < tipos.length; k++)
                {
                    if(tipos[k] === 'Hotel' || !patternsByType[tipos[k]].includes(padroes[j])){
                        continue;
                    }


                    let row = {
                        detalhe: detalhes[x],
                        estagio: estagios[y],
                        padrao: padroes[j],
                        tipo: tipos[k]
    
                    };
    
                    let totalSumDetalhe = 0;
                    for( let i = 0; i < periods.length; i++ )
                    {
                        let period = periods[i];
    
                        if( typeof period == 'number'){
                            period = period.toString();
                        }
                    
                        let value = data.
                             where('estagio', estagios[y])
                            .where('periodo', period)
                            .where('padrao', padroes[j])
                            .where('tipo', tipos[k])
                            .pluck(detalhes[x])
                            .first() || 0;
                        
                            row[period] = value;
    
                            totalSumDetalhe += value;
                    }
    
                    row.total = totalSumDetalhe;
                    rows.push(row);
    
                }
            }

        }

    }

    return rows;

}

const transformDataGroupedByStageOfWorkAndType = (data, detalhes, periods, tipos, extraConfig  )=>{
    data = collect(data);
    let estagios = data.pluck('estagio').unique().toArray().sort();

    let rows = [];

    if( tipos.length == 0){
        tipos = tiposdefaultTipos;
    }


    for( let x =0; x < detalhes.length; x++ )
    {
        for( let y = 0; y < estagios.length; y++)
        {
            for( let j = 0; j < tipos.length; j++)
            {
                let row = {
                    detalhe: detalhes[x],
                    estagio: estagios[y],
                    tipo: tipos[j]

                };

                let totalSumDetalhe = 0;
                for( let i = 0; i < periods.length; i++ )
                {
                    let period = periods[i];

                    if( typeof period == 'number'){
                        period = period.toString();
                    }
                
                    let value = data.
                         where('estagio', estagios[y])
                        .where('periodo', period)
                        .where('tipo', tipos[j])
                        .pluck(detalhes[x])
                        .first() || 0;
                    
                        row[period] = value;

                        totalSumDetalhe += value;
                }

                row.total = totalSumDetalhe;
                rows.push(row);

            }
        }
    }

    return rows;


}


const transformDataGroupedByCluster = (data, detalhes, periods, tipos, extraConfig  )=>{
    data = collect(data);

    let clusters = data.pluck('cluster').unique().toArray().sort();
    let cidades = data.pluck('cidade').unique().toArray().sort();

    let rows = [];

    if( tipos.length == 0){
        tipos = tiposdefaultTipos;
    }

    for( let x =0; x < detalhes.length; x++ ){
        for( let y = 0; y < cidades.length; y++){
            let clusters = data.where('cidade', cidades[y]).pluck('cluster').unique().toArray().sort();

            for( let z = 0; z < clusters.length; z++){
                

                for( let j = 0; j < tipos.length; j++)
                {
                    let row = {
                        detalhe: detalhes[x],
                        cidade: cidades[y],
                        cluster: clusters[z],
                        tipo: tipos[j]
    
                    };

                    let totalSumDetalhe = 0;
                    for( let i = 0; i < periods.length; i++ ){
                        let period = periods[i];

                        if( typeof period == 'number'){
                            period = period.toString();
                        }
                    
                        let value = data.
                            where('cidade', cidades[y])
                            .where('cluster', clusters[z])
                            .where('periodo', period)
                            .where('tipo', tipos[j])
                            .pluck(detalhes[x])
                            .first() || 0;
                        
                            row[period] = value;

                            totalSumDetalhe += value;
                    }

                    row.total = totalSumDetalhe;
                    rows.push(row);

                }
            }
        }
    }  

    
    return rows;
}

export default transform;




