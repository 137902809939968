import { fromPairs, random } from "lodash";
import React from "react";
const formatacoes = require('../../util/formatacoes')
const style = {
    backgroundColor: "#5B7537",
    color: "#ffffff",
    textAlign: "center",
    fontWeight: "bold",
};
const markDagua = {
    backgroundImage: 'url("https://geobrain.com.br/img/thumb-geobrain-mark.png")',
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    position: "static",
    backgroundPosition: "center 50px",
    textAlign: "center",
}


class Table extends React.Component {
    componentDidMount() { }

    getDetailsColumns = () => {
        let columns = [];

        if (this.props.tipo_detalhamento === "Padrão") {
            columns = ["Padrão"];
        }

        if (this.props.tipo_detalhamento === "Tipologia") {
            columns = ["Tipologia"];
        }

        if (this.props.tipo_detalhamento === "Tipo") {
            columns = ["Tipo"];
        }
        return columns;
    };

    getHeadersColumns = () => {
        let detailsColumns = this.getDetailsColumns();

        let periodsColumns = this.props.periodos.map((periodo, index) => {
            return formatacoes.formatPeriodString(periodo, this.props.response_period_type);
        });

        let columns = detailsColumns.concat(periodsColumns);

        return columns;
    };

    render() {
        let headersColumns = this.getHeadersColumns();

        return (
            <>
                <table className="table table-striped" style={markDagua} id={this.props.id} hidden={!this.props.exibirTabelas}>
                    <thead>
                        <tr style={style}>
                            <th colSpan={headersColumns.length} style={style}>
                                {this.props.titulo}
                            </th>
                        </tr>
                        <tr style={style}>
                            {headersColumns.map((column, index) => {
                                return (
                                    <th key={index} style={style}>
                                        {column}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.rows != undefined && this.props.rows.map((row, index) => {
                            return (
                                <>
                                    <tr key={index}>
                                        {this.props.tipo_detalhamento == "Padrão" && <th>{row.padrao}</th>}
                                        {this.props.tipo_detalhamento == "Tipo" && <th>{row.tipo}</th>}
                                        {this.props.tipo_detalhamento == "Tipologia" && <th>{formatacoes.formatTipologia(row.tipologia)}</th>}
                                        {this.props.periodos.map((periodo, index) => {
                                            if (row.detalhe == 'ivv') {
                                                return (
                                                    <td key={index} style={{ borderColor: "#E3E3E3" }} s>
                                                        {formatacoes.formatNumberAsPercentage(row[periodo])}%
                                                    </td>
                                                )
                                            }
                                            return (
                                                <td key={index} style={{ borderColor: "#E3E3E3" }}>
                                                    {formatacoes.formatNumber(row[periodo])}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                    {this.props.variacao !== '' && this.props.variacao != undefined && <>
                                        <tr>
                                            <th></th>
                                            {this.props.periodos.map((periodo, index) => {

                                                let valor = this.getVariacao(row, periodo);
                                                return (
                                                    <td key={index} style={{ borderColor: "#E3E3E3" }}>
                                                        {valor < 0 && <i className="fas fa-angle-down" style={{ color: "red" }}>&nbsp;</i>}
                                                        {valor > 0 && <i className="fas fa-angle-up" style={{ color: "green" }}>&nbsp;</i>}
                                                        {valor == 0 ? "-" : formatacoes.formatNumberAsPercentage(valor) + '%'}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    </>}
                                </>
                            );
                        })}
                    </tbody>
                    {this.props.totais != undefined && this.props.totais.length > 0 &&
                        <tfoot>
                        {this.props.totais.map((row, index) => {
                            return (
                                <>
                                    <tr key={index}>
                                        <th>Total</th>
                                        {this.props.periodos.map((periodo, index) => {
                                            return (
                                                <td key={index} style={{ borderColor: "#E3E3E3" }}>
                                                    {formatacoes.formatNumber(row[periodo])}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                </>
                            );
                        })}
                    </tfoot>
                    }
                    {this.props.mediasTotais != undefined && this.props.mediasTotais.length > 0 &&
                        <tfoot>
                            {this.props.mediasTotais.map((row, index) => {
                                return (
                                    <>
                                        <tr key={index}>
                                            <th>Média</th>
                                            {this.props.periodos.map((periodo, index) => {
                                                return (
                                                    <td key={index} style={{ borderColor: "#E3E3E3" }}>
                                                        {formatacoes.formatNumber(row[periodo])}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    </>
                                );
                            })}
                        </tfoot>
                    }
                </table>
                <hr hidden={!this.props.exibirTabelas}/>
            </>
        );
    }

    getPeriodoAnterior = (periodo) => {

        return this.props.periodos_variacao[
            this.props.periodos.indexOf(periodo)
        ];
    };

    getVariacao = (row, periodo) => {
        let variacao = 0;
        let periodoAnterior = this.getPeriodoAnterior(periodo);

        let valorAtual = row[periodo] || 0;
        let valorAnterior = row[periodoAnterior] || 0;

        if (valorAtual == 0 && valorAnterior == 0) {
            return 0;
        }

        if (valorAnterior == 0) {
            return 100;
        }

        variacao = (valorAtual / valorAnterior - 1) * 100;

        if (isNaN(variacao)) {
            return 0;
        }

        return variacao;
    };
}

export default Table;
