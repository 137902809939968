import React, { Component } from "react";
import SelectTypeEmpreendimento from "./SelectTypeEmpreendimento";
import Select from "react-select";
import MultiSelect from "react-multi-select-component";
import Legenda from "./Legenda";
import AsyncSelect from "react-select/async";
import Axios from "axios";
import estados from "../util/estados";
import anos_lancamento from "../util/anos_lancamento";
import estagios from "../util/estagios";
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from "react-places-autocomplete";
import IntlCurrencyInput from "react-intl-currency-input";
import DatePicker, { registerLocale } from "react-datepicker";


const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};

class FormFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            estados: estados,
            cidades: [],
            address: "",
            incorporadoras: [],
            types: [],
        };
    }

    loadTypes = async () => {
        try {
            let response = await axios.get(route("polos-geradores-tipos"));

            if (response.status === 200) {
                let types = response.data.types;

                let options = [];

                types.forEach((type) => {
                    options.push({ value: type, label: type });
                });

                this.setState({ types: options });
            }
        } catch (e) {
            console.log(e);
        }
    };

    componentDidMount = async () => {
        await this.props.getProjects();
        await this.loadTypes();
    };

    searchIncorporadora = async (inputQuery) => {
        if (inputQuery.length >= 2) {
            const response = await Axios.get(
                "/incorporadoras-json?incorporadora=" + inputQuery
            );
            return response.data;
        }
    };

    searchCidade = async (inputQuery) => {
        let state =
            this.props.estadosSelecionados != null
                ? this.props.estadosSelecionados.map((index) => {
                    return index.value;
                })
                : null;
        console.log(state);
        if (inputQuery.length >= 2) {
            const response = await Axios.get(
                "/cidades/json-options-by-state/" + state + "/" + inputQuery
            );
            return response.data;
        }
    };

    handleChange = (address) => {
        this.setState({ ...this.state, address: address });
    };

    handleSelect = (address) => {
        this.setState({ ...this.state, address: address });

        geocodeByAddress(address)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => {
                console.log("Success", latLng);

                window.googleMap.panTo(latLng);

                this.props.handleCidadeChanged(latLng.lat, latLng.lng, address);
            })
            .catch((error) => console.error("Error", error));
    };

    refreshIconButton = () => {
        return (
            <>
                <i className="fas fa-sync-alt"></i> Atualizar
            </>
        );
    };

    buscarIconButton = () => {
        return (
            <>
                <i className="fas fa-search"></i> Buscar
            </>
        );
    };

    handleValorMaximoChange = (event, value, maskedValue) => {
        event.preventDefault();

        this.props.handleValorMaximoChanged(value);
    };

    handleValorMinimoChange = (event, value, maskedValue) => {
        event.preventDefault();

        this.props.handleValorMinimoChanged(value);
    };

    handleValorMetroMaximoChange = (event, value, maskedValue) => {
        event.preventDefault();

        this.props.handleValorMetroMaximoChanged(value);
    };

    handleValorMetroMinimoChange = (event, value, maskedValue) => {
        event.preventDefault();

        this.props.handleValorMetroMinimoChanged(value);
    };

    handleValorParcelaMaximaChange = (event, value, maskedValue) => {
        event.preventDefault();

        this.props.handleValorParcelaMaximaChanged(value);
    };

    handleValorParcelaMinimaChange = (event, value, maskedValue) => {
        event.preventDefault();

        this.props.handleValorParcelaMinimaChanged(value);
    };

    render() {
        let statusStates =
            this.props.estadosSelecionados != null &&
            this.props.estadosSelecionados.length === this.state.estados.length;

        return (
            <div
                style={{
                    overflowX: "hidden",
                    height: "90vh",
                }}
            >
                <div className="card filtro-mapa">
                    <div className="card-body">
                    {/* <div className="alert alert-danger alert-dismissible fade show" role="alert">
    Prezado, devido as chuvas e enchentes no Rio Grande do Sul, não atualizaremos a base de dados de Porto Alegre para o mês de Abril.
                    </div> */}
                        <div className="form-group">
                            <div className="form-group">
                                <label>Carregar Projeto</label>
                                <Select
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderRadius: 20,
                                        }),
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#F2F2F2",
                                            primary: "#5B7537",
                                        },
                                    })}
                                    placeholder="Selecione"
                                    options={this.props.projetos}
                                    onChange={this.props.handleProjetoChanged}
                                    isMulti={false}
                                />
                            </div>

                            <label>Tipo de Empreendimento</label>
                            <br />

                            <div className="form-check checkbox-inline">
                                <input
                                    className="form-check-input"
                                    name="check_vertical"
                                    id="check_vertical"
                                    onChange={this.props.handleTipoEmpreendimentoChanged}
                                    checked={this.props.tipo_empreendimento.includes("Vertical")}
                                    type="checkbox"
                                    value="Vertical"
                                />
                                <span className="slider"></span>
                                <label
                                    htmlFor="check_vertical"
                                    className="form-check-label margin-check"
                                >
                                    Vertical
                                </label>
                            </div>

                            <div className="form-check checkbox-inline">
                                <input
                                    className="form-check-input"
                                    name="check_horizontal"
                                    id="check_horizontal"
                                    onChange={this.props.handleTipoEmpreendimentoChanged}
                                    checked={this.props.tipo_empreendimento.includes("Horizontal")}
                                    type="checkbox"
                                    value="Horizontal"
                                />
                                <span className="slider"></span>
                                <label
                                    htmlFor="check_horizontal"
                                    className="form-check-label margin-check"
                                >
                                    Horizontal
                                </label>
                            </div>

                            <div className="form-check checkbox-inline">
                                <input
                                    className="form-check-input"
                                    name="check_comercial"
                                    id="check_comercial"
                                    onChange={this.props.handleTipoEmpreendimentoChanged}
                                    checked={this.props.tipo_empreendimento.includes("Comercial")}
                                    type="checkbox"
                                    value="Comercial"
                                />
                                <span className="slider"></span>
                                <label
                                    htmlFor="check_comercial"
                                    className="form-check-label margin-check"
                                >
                                    Comercial
                                </label>
                            </div>

                            <div className="form-check checkbox-inline">
                                <input
                                    className="form-check-input"
                                    name="check_hotel"
                                    id="check_hotel"
                                    onChange={this.props.handleTipoEmpreendimentoChanged}
                                    checked={this.props.tipo_empreendimento.includes("Hotel")}
                                    type="checkbox"
                                    value="Hotel"
                                />
                                <span className="slider"></span>
                                <label
                                    htmlFor="check_hotel"
                                    className="form-check-label margin-check"
                                >
                                    Hotel
                                </label>
                            </div>
                        </div>

                        <div className="form-group">
                            <label>Nome</label>
                            <AsyncSelect
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: 20,
                                    }),
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#F2F2F2",
                                        primary: "#5B7537",
                                    },
                                })}
                                cacheOptions
                                isMulti
                                value={this.props.nome_empreendimento}
                                defaultOptions
                                loadOptions={this.props.searchEmpreendimentos}
                                onChange={this.props.handleNomeEmpreendimentoChanged}
                            />
                        </div>
                        <div className="form-group">
                            <label>Padrão</label>
                            <Select
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: 20,
                                    }),
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#F2F2F2",
                                        primary: "#5B7537",
                                    },
                                })}
                                placeholder="Selecione"
                                options={this.props.getPadroes()}
                                value={this.props.padrao}
                                onChange={this.props.handlePadraoChanged}
                                isMulti={true}
                            />
                        </div>
                        <div className="form-group">
                            <label>Status</label>

                            <Select
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: 20,
                                    }),
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#F2F2F2",
                                        primary: "#5B7537",
                                    },
                                })}
                                value={this.props.status}
                                placeholder="Selecione"
                                isMulti={true}
                                onChange={this.props.handleStatusChanged}
                                options={[
                                    {value: "Todos",label: "Selecionar Todos",},
                                    {value: "Comercialização",label: "Comercialização",},
                                    {value: "Esgotado", label: "Esgotado" },
                                    {value: "Futuro", label: "Futuro" },
                                    {value: "Suspenso", label: "Suspenso" },
                                ]}
                            />
                        </div>
                        <div className="form-group">
                            <label>Incorporadora</label>
                            <AsyncSelect
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: 20,
                                    }),
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#F2F2F2",
                                        primary: "#5B7537",
                                    },
                                })}
                                cacheOptions
                                defaultOptions
                                isMulti
                                value={this.props.incorporators}
                                loadOptions={this.searchIncorporadora}
                                onChange={this.props.handleIncorporadoraChanged}
                            />
                        </div>
                        <div className="form-group">
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <label style={{ marginRight: "10px" }}>
                                    Estado
                                </label>

                                <div className="form-check checkbox-inline">
                                    <input
                                        className="form-check-input"
                                        name="check_state"
                                        id="check_state"
                                        checked={statusStates}
                                        onChange={() =>
                                            this.props.handleEstadosChanged(
                                                this.state.estados,
                                                statusStates
                                            )
                                        }
                                        type="checkbox"
                                    />
                                    <span className="slider"></span>
                                    <label
                                        htmlFor="check_state"
                                        className="form-check-label margin-check"
                                    >
                                        Todos
                                    </label>
                                </div>
                            </div>

                            <Select
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: 20,
                                    }),
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#F2F2F2",
                                        primary: "#5B7537",
                                    },
                                })}
                                cacheOptions
                                isMulti={true}
                                options={this.state.estados}
                                placeholder="Selecione"
                                value={this.props.estadosSelecionados}
                                onChange={(value) =>
                                    this.props.handleEstadosChanged(value)
                                }
                            />
                        </div>

                        <div className="form-group">
                            <label>Cidade</label>
                            <AsyncSelect
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: 20,
                                    }),
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#F2F2F2",
                                        primary: "#5B7537",
                                    },
                                })}
                                cacheOptions
                                placeholder="Selecione"
                                isMulti
                                value={this.props.cidade}
                                loadOptions={this.searchCidade}
                                onChange={this.props.handleCidadeChanged}
                            />
                        </div>

                        <div className="form-group">
                            <label>Bairros</label>

                            <Select
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: 20,
                                    }),
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#F2F2F2",
                                        primary: "#5B7537",
                                    },
                                })}
                                value={this.props.bairros}
                                placeholder="Selecione"
                                isMulti={true}
                                onChange={this.props.handleBairrosChanged}
                                options={this.props.bairrosOptions}
                            />
                        </div>

                        <div className="form-group row">
                            <div className="col-md-6 col-sm-6 col-lg-6">
                                <label>Área Mínima</label>
                                <input
                                    type="number"
                                    name="area_minima"
                                    value={this.props.area_minima}
                                    onChange={
                                        this.props.handleAreaMinimaChanged
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                            <div className="col-md-6 col-sm-6 col-lg-6">
                                <label>Área Máxima</label>
                                <input
                                    type="number"
                                    name="area_maxima"
                                    value={this.props.area_maxima}
                                    onChange={
                                        this.props.handleAreaMaximaChanged
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6 col-sm-6 col-lg-6">
                                <label>% Vendida Mínima</label>
                                <input
                                    type="number"
                                    name="porcentagem_vendida_minima"
                                    value={
                                        this.props.porcentagem_vendida_minima
                                    }
                                    onChange={
                                        this.props
                                            .handlePorcentagemVendidaMinimaChanged
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                            <div className="col-md-6 col-sm-6 col-lg-6">
                                <label>% Vendida Máxima</label>
                                <input
                                    type="number"
                                    name="area_maxima"
                                    value={
                                        this.props.porcentagem_vendida_maxima
                                    }
                                    onChange={
                                        this.props
                                            .handlePorcentagemVendidaMaximaChanged
                                    }
                                    style={{ padding: "3px" }}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-6 col-sm-6 col-lg-6">
                                <label>Valor Mínimo</label>

                                <IntlCurrencyInput
                                    currency="BRL"
                                    config={currencyConfig}
                                    value={this.props.valor_maior_que}
                                    onChange={this.handleValorMinimoChange}
                                    style={{ padding: "3px" }}
                                    className="valor form-control"
                                />
                            </div>
                            <div className="col-md-6 col-sm-6 col-lg-6">
                                <label>Valor Máximo</label>
                                <IntlCurrencyInput
                                    currency="BRL"
                                    config={currencyConfig}
                                    onChange={this.handleValorMaximoChange}
                                    style={{ padding: "3px" }}
                                    className="valor form-control"
                                    value={this.props.valor_menor_que}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6 col-sm-6 col-lg-6">
                                <label>
                                    Valor R$/m<sup>2</sup> Mínimo
                                </label>
                                <IntlCurrencyInput
                                    currency="BRL"
                                    config={currencyConfig}
                                    onChange={this.handleValorMetroMinimoChange}
                                    style={{ padding: "3px" }}
                                    value={this.props.valor_metro_maior_que}
                                    className="valor form-control"
                                />
                            </div>
                            <div className="col-md-6 col-sm-6 col-lg-6">
                                <label>
                                    Valor R$/m<sup>2</sup> Máximo
                                </label>
                                <IntlCurrencyInput
                                    currency="BRL"
                                    config={currencyConfig}
                                    onChange={this.handleValorMetroMaximoChange}
                                    style={{ padding: "3px" }}
                                    value={this.props.valor_metro_menor_que}
                                    className="valor form-control"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Quartos</label>
                            <br />
                            <div className="form-check checkbox-inline">
                                <input
                                    className="form-check-input"
                                    name="check_quarto1"
                                    id="check_quarto1"
                                    checked={this.props.quartos.includes(1)}
                                    onChange={this.props.handleQuartosChanged}
                                    type="checkbox"
                                    value="1"
                                />
                                <span className="slider"></span>
                                <label
                                    htmlFor="check_quarto1"
                                    className="form-check-label margin-check"
                                >
                                    1
                                </label>
                            </div>
                            <div className="form-check checkbox-inline">
                                <input
                                    className="form-check-input"
                                    name="check_quarto2"
                                    id="check_quarto2"
                                    onChange={this.props.handleQuartosChanged}
                                    checked={this.props.quartos.includes(2)}
                                    type="checkbox"
                                    value="2"
                                />
                                <span className="slider"></span>
                                <label
                                    htmlFor="check_quarto2"
                                    className="form-check-label margin-check"
                                >
                                    2
                                </label>
                            </div>
                            <div className="form-check checkbox-inline">
                                <input
                                    className="form-check-input"
                                    name="check_quarto3"
                                    id="check_quarto3"
                                    checked={this.props.quartos.includes(3)}
                                    onChange={this.props.handleQuartosChanged}
                                    type="checkbox"
                                    value="3"
                                />
                                <span className="slider"></span>
                                <label
                                    htmlFor="check_quarto3"
                                    className="form-check-label margin-check"
                                >
                                    3
                                </label>
                            </div>
                            <div className="form-check checkbox-inline">
                                <input
                                    className="form-check-input"
                                    name="check_quarto4"
                                    id="check_quarto4"
                                    checked={this.props.quartos.includes(4)}
                                    onChange={this.props.handleQuartosChanged}
                                    type="checkbox"
                                    value="4"
                                />
                                <span className="slider"></span>
                                <label
                                    htmlFor="check_quarto4"
                                    className="form-check-label margin-check"
                                >
                                    4
                                </label>
                            </div>
                        </div>
                        {this.props.showResaleOrLocationMarkers && (
                            <div className="form-group">
                                <label>Vagas</label>
                                <br />
                                <div className="form-check checkbox-inline">
                                    <input
                                        className="form-check-input"
                                        name="check_vagas1"
                                        id="check_vagas1"
                                        checked={this.props.vagas.includes(1)}
                                        onChange={this.props.handleVagasChanged}
                                        type="checkbox"
                                        value="1"
                                    />
                                    <span className="slider"></span>
                                    <label
                                        htmlFor="check_vagas1"
                                        className="form-check-label margin-check"
                                    >
                                        1
                                    </label>
                                </div>
                                <div className="form-check checkbox-inline">
                                    <input
                                        className="form-check-input"
                                        name="check_vagas2"
                                        id="check_vagas2"
                                        onChange={this.props.handleVagasChanged}
                                        checked={this.props.vagas.includes(2)}
                                        type="checkbox"
                                        value="2"
                                    />
                                    <span className="slider"></span>
                                    <label
                                        htmlFor="check_vagas2"
                                        className="form-check-label margin-check"
                                    >
                                        2
                                    </label>
                                </div>
                                <div className="form-check checkbox-inline">
                                    <input
                                        className="form-check-input"
                                        name="check_vagas3"
                                        id="check_vagas3"
                                        checked={this.props.vagas.includes(3)}
                                        onChange={this.props.handleVagasChanged}
                                        type="checkbox"
                                        value="3"
                                    />
                                    <span className="slider"></span>
                                    <label
                                        htmlFor="check_vagas3"
                                        className="form-check-label margin-check"
                                    >
                                        3
                                    </label>
                                </div>
                                <div className="form-check checkbox-inline">
                                    <input
                                        className="form-check-input"
                                        name="check_vagas4"
                                        id="check_vagas4"
                                        checked={this.props.vagas.includes(4)}
                                        onChange={this.props.handleVagasChanged}
                                        type="checkbox"
                                        value="4"
                                    />
                                    <span className="slider"></span>
                                    <label
                                        htmlFor="check_vagas4"
                                        className="form-check-label margin-check"
                                    >
                                        4+
                                    </label>
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <div className="col-12">
                                <label htmlFor="ano_lancamento">
                                    Ano de Lançamento
                                </label>

                                <Select
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderRadius: 20,
                                        }),
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#F2F2F2",
                                            primary: "#5B7537",
                                        },
                                    })}
                                    value={this.props.ano_lancamento}
                                    placeholder="Selecione"
                                    isMulti={true}
                                    onChange={
                                        this.props.handleAnoLancamentoChanged
                                    }
                                    options={anos_lancamento}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-12">
                                <label htmlFor="ano_lancamento">
                                   Mês e Ano de Lançamento
                                </label>

                                <DatePicker
                                    locale="pt-BR"
                                    name="ano_lancamento"
                                    className="form-control"
                                    style={{ width: "155%" }}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    isClearable={true}
                                    autoComplete="off"
                                    selected={this.props.mes_ano_lancamento}
                                    onChange={
                                        this.props.handleMesAnoLancamentoChanged
                                    }
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-12">
                                <label htmlFor="ano_lancamento">
                                    Estágio
                                </label>

                                <Select
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderRadius: 20,
                                        }),
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#F2F2F2",
                                            primary: "#5B7537",
                                        },
                                    })}
                                    value={this.props.estagio}
                                    placeholder="Selecione"
                                    isMulti={true}
                                    onChange={
                                        this.props.handleEstagioChanged
                                    }
                                    options={estagios}
                                />
                            </div>
                        </div>
                        <div className="form-check checkbox-inline">
                            <input
                                className="form-check-input"
                                name="check_show_emp"
                                id="check_show_emp"
                                checked={this.props.showMarkers}
                                onChange={(e) => {
                                    let checked = e.target.checked;
                                    this.props.setShowMarkers(checked);
                                }}
                                type="checkbox"
                                value="true"
                            />
                            <span className="slider"></span>
                            <label
                                htmlFor="check_show_emp"
                                className="form-check-label margin-check"
                            >
                                Mostrar Empreendimentos
                            </label>
                        </div>
                        <div className="form-check checkbox-inline">
                            <input
                                className="form-check-input"
                                name="check_use_cluster"
                                id="check_use_cluster"
                                checked={this.props.use_cluster}
                                onChange={(e) => {
                                    this.props.toggleUseCluster();
                                }}
                                type="checkbox"
                                value="true"
                            />
                            <span className="slider"></span>
                            <label
                                htmlFor="check_use_cluster"
                                className="form-check-label margin-check"
                            >
                                Usar Agrupamento de Marcadores
                            </label>
                        </div>
                        {window.verMarcadoresRevenda == true && (
                            <>
                                <div className="form-check checkbox-inline">
                                    <input
                                        className="form-check-input"
                                        name="check_show_revenda"
                                        id="check_show_revenda"
                                        checked={this.props.showResaleMarkers}
                                        onChange={(e) => {
                                            let checked = e.target.checked;
                                            this.props.setShowResaleMarkers(
                                                checked
                                            );
                                        }}
                                        type="checkbox"
                                        value="true"
                                    />
                                    <span className="slider"></span>
                                    <label
                                        htmlFor="check_show_revenda"
                                        className="form-check-label margin-check"
                                    >
                                        Mostrar Revenda
                                    </label>
                                </div>
                                <div className="form-check checkbox-inline">
                                    <input
                                        className="form-check-input"
                                        name="check_show_rent"
                                        id="check_show_rent"
                                        checked={this.props.showLocationMarkers}
                                        onChange={(e) => {
                                            let checked = e.target.checked;
                                            this.props.setShowLocationMarkers(
                                                checked
                                            );
                                        }}
                                        type="checkbox"
                                        value="true"
                                    />
                                    <span className="slider"></span>
                                    <label
                                        htmlFor="check_show_rent"
                                        className="form-check-label margin-check"
                                    >
                                        Mostrar Locação
                                    </label>
                                </div>
                            </>
                        )}
                        {window.authenticatedUserCanQueryPolos == true && (
                            <div className="form-check checkbox-inline">
                                <input
                                    className="form-check-input"
                                    name="check_poles"
                                    id="check_poles"
                                    checked={this.props.showPolosGeradores}
                                    onChange={(e) => {
                                        let checked = e.target.checked;
                                        this.props.setShowPolosGeradores(
                                            checked
                                        );
                                    }}
                                    type="checkbox"
                                    value="true"
                                />
                                <span className="slider"></span>
                                <label
                                    htmlFor="check_poles"
                                    className="form-check-label margin-check"
                                >
                                    Mostrar Polos Geradores
                                </label>
                            </div>
                        )}
                        {this.props.showPolosGeradores && (
                            <>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <Select
                                            isMulti
                                            value={
                                                this.props
                                                    .polos_geradores_types_selected
                                            }
                                            onChange={
                                                this.props
                                                    .setPolosGeradoresTypes
                                            }
                                            name="tipos"
                                            options={this.state.types}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        {this.props.tipo_empreendimento.includes(
                            "Horizontal"
                        ) && (
                                <>
                                    <div className="form-group row">
                                        <div className="col-md-6 col-sm-6 col-lg-6">
                                            <label>Taxas de Juros Mínima</label>
                                            <input
                                                type="number"
                                                name="juros_min"
                                                value={this.props.juros_minimo}
                                                onChange={
                                                    this.props
                                                        .handleJurosMinimoChanged
                                                }
                                                style={{ padding: "3px" }}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-lg-6">
                                            <label>Taxa de Juros Máxima</label>
                                            <input
                                                type="number"
                                                name="juros_max"
                                                value={this.props.juros_maximo}
                                                onChange={
                                                    this.props
                                                        .handleJurosMaximoChanged
                                                }
                                                style={{ padding: "3px" }}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6 col-sm-6 col-lg-6">
                                            <label>Número de Parcelas Mínimo</label>
                                            <input
                                                type="number"
                                                name="parcelas_min"
                                                value={this.props.parcelas_minimo}
                                                onChange={
                                                    this.props
                                                        .handleParcelasMinimoChanged
                                                }
                                                style={{ padding: "3px" }}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-lg-6">
                                            <label>Número de Parcelas Máximo</label>
                                            <input
                                                type="number"
                                                name="parcelas_max"
                                                value={this.props.parcelas_maximo}
                                                onChange={
                                                    this.props
                                                        .handleParcelasMaximoChanged
                                                }
                                                style={{ padding: "3px" }}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6 col-sm-6 col-lg-6">
                                            <label>Valor Parcela Mínima</label>
                                            <IntlCurrencyInput
                                                currency="BRL"
                                                config={currencyConfig}
                                                onChange={
                                                    this
                                                        .handleValorParcelaMinimaChange
                                                }
                                                style={{ padding: "3px" }}
                                                value={
                                                    this.props.valor_parcela_minima
                                                }
                                                className="valor form-control"
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-lg-6">
                                            <label>Valor Parcela Máxima</label>
                                            <IntlCurrencyInput
                                                currency="BRL"
                                                config={currencyConfig}
                                                onChange={
                                                    this
                                                        .handleValorParcelaMaximaChange
                                                }
                                                value={
                                                    this.props.valor_parcela_maxima
                                                }
                                                style={{ padding: "3px" }}
                                                className="valor form-control"
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                    </div>
                    <div
                        className="card-footer btn-filtro-mobile"
                        style={{
                            position: "sticky",
                            bottom: "14px",
                            backgroundColor: "#FFF",
                            padding: "5px 0",
                            boxShadow: "0, 0, 3px, rgba(0,0,0, .2",
                        }}
                    >
                        <button
                            className="btn btn-search btn-round background-brain border-brain"
                            style={{ marginTop: "15px" }}
                            onClick={this.props.handleSubmit}
                        >
                            {this.props.overlays.length > 0
                                ? this.refreshIconButton()
                                : this.buscarIconButton()}
                        </button>
                        <div className="filter-btn-view">
                            <button
                                className="btn filter-btn btn-primary button-brain btn-round background-brain2 border-brain2"
                                style={{
                                    marginTop: "12px",
                                    marginRight: "6px",
                                    backgroundColor: "#FFBB27",
                                    borderColor: "#FFBB27",
                                }}
                                onClick={this.props.cleanState}
                            >
                                <i className="far fa-file"></i> Limpar
                            </button>
                            <button
                                className="btn filter-btn btn-primary button-brain btn-round background-brain2 border-brain2"
                                style={{ marginTop: "12px", marginLeft: "6px" }}
                                onClick={this.props.downloadKml}
                            >
                                Download KML
                            </button>
                        </div>
                        <div className="filter-btn-view">
                            <button
                                className="btn filter-btn btn-primary button-brain btn-round background-brain2 border-brain2"
                                style={{
                                    marginTop: "12px",
                                    marginRight: "6px",
                                }}
                                onClick={this.props.openModalWork}
                            >
                                Salvar Trabalho
                            </button>
                            {this.props.projeto != "" && (
                                <button
                                    className="btn filter-btn btn-primary button-brain btn-round background-brain2 border-brain2"
                                    style={{
                                        marginTop: "12px",
                                        marginLeft: "6px",
                                    }}
                                    onClick={this.props.deleteProject}
                                >
                                    Deletar Projeto Carregado
                                </button>
                            )}
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}

export default FormFilters;
