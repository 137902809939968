import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select';
import IntlCurrencyInput from "react-intl-currency-input"
import Markers from './Markers';

var currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                //style: "currency",
                //currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};

var currencyConfigInteger = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                //style: "currency",
                //currency: "BRL",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            },
        },
    },
};

const labels = {
    "area_media": "Área média",
    "ticket_medio_metro": "Ticket Médio R$/m²",
    "total_unidades": "Oferta Inicial",
    "oferta_final": "Estoque",
}

const valuesForMarkers = [];

Markers.forEach(marker => {
    valuesForMarkers.push({

        label: <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
        }}>
            <div style={{
                backgroundColor: marker.hexa,
                width: '30px',
                height: '30px',
                borderRadius: '15px',
                borderColor: '#cccccc',
                borderWidth: '1px',
                borderStyle: 'solid',
                }}></div>
            <span style={{marginLeft: '15px'}}>
                {marker.nome}
            </span>
        </div>,
        value: marker.marker
    });
});

class ModalRanges extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            min: null,
            max: null,
            type : null,
            marker: null,
            errorMessage: null,
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount = ()=> {

    }

    handleOptionsChange = async (selectedAreas) => {

    }

    handleMarkerChange = (selectedMarker) => {
        this.setState({
            ...this.state,
            marker: selectedMarker
        });
    }

    submit = async ()=> {

    }

    openModal(area) {
        this.setState({
            modalIsOpen: true,
            area: area
        });
    }

    handleValorMinimumChange = async(event, value, maskedValue) => {

        event.preventDefault();
        console.log(value);
        console.log(maskedValue);

        await this.setState({
            ...this.state,
            min: value

        });
    }

    handleValorMaximumChange = async (event, value, maskedValue) => {

        event.preventDefault();
        console.log(value);
        console.log(maskedValue);
        await this.setState({
            ...this.state,
            max: value

        });
    }

    handleTipoChange = (event) => {
        //console.log(event);

        this.setState({
            ...this.state,
            type : event
        });
    }

    needsIntegerInputRange = () => {
        return this.state.type?.value === "total_unidades" || this.state.type?.value === "oferta_final";
    }

    closeModal() {
        this.setState({
            modalIsOpen: false,
            area: null
        });
    }

    addRange = async () => {

        if(this.state.max == null){
            this.setState({
                ...this.state,
                max: 99999999
            });

            return;
        }



        if(this.state.min == null || this.state.type == null || this.state.marker == null){
            this.setState({
                ...this.state,
                errorMessage: "Preencha todos os campos"
            });

            return;
        }



        if(this.state.max < this.state.min){
            this.setState({
                ...this.state,
                errorMessage: "Valor máximo deve ser maior que o mínimo"
            });

            return;
        }


        let rangeExitis = this.props.ranges.find(range => {
            return range.min === this.state.min && range.max === this.state.max && range.type === this.state.type.value;
        });

        if(rangeExitis){
            this.setState({
                ...this.state,
                errorMessage: "Já existe um range com esses valores"
            });

            return;
        }


        let markerExists = this.props.ranges.find(range => {
            return range.marker === this.state.marker.value;
        });

        if( markerExists ){
            this.setState({
                ...this.state,
                errorMessage: "Já existe um range com esse marcador"
            });

            return;
        }


        let overlaps = this.props.ranges.find(range => {
            return (this.state.min >= range.min && this.state.min <= range.max) || (this.state.max >= range.min && this.state.max <= range.max);
        });

        if(overlaps){
            this.setState({
                ...this.state,
                errorMessage: "O range se sobrepõe a outro range"
            });

            return;
        }


        console.log(this.state);
        let range = {
            min: this.state.min,
            max: this.state.max,
            type: this.state.type.value,
            marker: this.state.marker.value,
            id : this.generateRandomString()
        }

       await this.props.addRange(range);

       this.setState({
            ...this.state,
            min: null,
            max: null,
            errorMessage: null,
            marker: null,
        });


    }

    generateRandomString = () => {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }


    render() {

        let configRangeInput = currencyConfig;

        if(this.needsIntegerInputRange()){
            configRangeInput = currencyConfigInteger;
        }

        return (
            <div style={{ zIndex: '9999'}}>
                <Modal show={this.props.show} onHide={this.props.closeModal} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Criar Ranges</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">

                                    {this.state.errorMessage &&
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.errorMessage}
                                        </div>
                                    }
                                    <div className="panel-body">
                                        <div className="form-group">
                                            <label>Range Por:</label>
                                            <Select
                                                value={this.state.type}
                                                placeholder="Selecione o tipo"
                                                onChange={this.handleTipoChange}
                                                options={[
                                                    { value: 'area_media', label: 'Área Média' },
                                                    { value: 'ticket_medio_metro', label: 'Ticket Médio R$/m²' },
                                                    { value: 'total_unidades', label: 'Oferta Inicial' },
                                                    { value: 'oferta_final', label: 'Estoque' },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6 col-sm-6 col-lg-6">
                                            <label>A partir de</label>

                                            <IntlCurrencyInput
                                                currency="BRL"
                                                config={configRangeInput}
                                                value={this.state.min}
                                                onChange={this.handleValorMinimumChange}
                                                style={{ padding: '3px' }}
                                                className="valor form-control"

                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-lg-6">
                                            <label>Até</label>
                                            <IntlCurrencyInput
                                                currency="BRL"
                                                config={configRangeInput}
                                                onChange={this.handleValorMaximumChange}
                                                style={{ padding: '3px' }}
                                                className="valor form-control"
                                                value={this.state.max}
                                            />

                                        </div>
                                        <div className="col-md-12 col-sm-12 col-lg-12">
                                            <label>Cor do Marcador</label>
                                            <Select value={this.state.marker}
                                                onChange={this.handleMarkerChange}
                                                options={valuesForMarkers}
                                                placeholder="Selecione um marcador"
                                            />

                                        </div>

                                        <div className="col-md-12 col-sm-12 col-lg-12" style={{marginTop: '10px'}}>
                                            <div style={{display : "flex", justifyContent: "space-between"}}>
                                                <button className="btn btn-xs btn-primary button-brain btn-round" onClick={this.addRange}>
                                                    Adicionar Range
                                                </button>
                                                <button className="btn btn-xs btn-success border-brain background-brain btn-round" onClick={()=>{
                                                    this.props.replaceMarkers();
                                                    this.props.closeModal();
                                                }}>
                                                    Aplicar
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-12">
                                    <div style={{height: '250px', overflowY: 'scroll'}}>
                                        <table className="table table-striped"  >
                                            <thead>
                                                <tr>
                                                    <th>A partir de</th>
                                                    <th>Até</th>
                                                    <th>Tipo</th>
                                                    <th>Marcador</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.props.ranges.map((range, index) => {
                                                    let hexaBackground = Markers.find(marker => {
                                                        return marker.marker === range.marker;
                                                    }).hexa;

                                                    return (
                                                        <tr key={index}>
                                                            <td>{Number(range.min).toLocaleString()}</td>
                                                            <td>{(() => {
                                                                 if (range.max === 99999999){
                                                                        return (
                                                                            null
                                                                        )
                                                                    }
                                                                    return Number(range.max).toLocaleString();
                                                                    })()}</td>
                                                            <td>{labels[range.type]}</td>
                                                            <td>
                                                                <div style={{
                                                                    backgroundColor: hexaBackground,
                                                                    width: '30px',
                                                                    height: '30px',
                                                                    borderRadius: '50%',
                                                                    display: 'inline-block',
                                                                    marginRight: '10px'
                                                                }}></div>
                                                            </td>
                                                            <td>

                                                                <button className="btn btn-xs btn-danger" onClick={() => this.props.removeRange(range)}>
                                                                    <i className="fa fa-trash"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {/*<ModalFooter>
                        <Button variant="secondary" onClick={this.props.closeModal}>
                            Fechar
                        </Button>
                        <Button variant="primary" onClick={this.props.closeModal}>

                        </Button>
                    </ModalFooter>*/}
                </Modal>
            </div>
        );
    }
}

export default ModalRanges;
