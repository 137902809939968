import React from 'react';
import {Button, Modal} from "react-bootstrap";
import InnerLoading from "./InnerLoading";
import './estilos.css';
import IndiceVerticalizacao from "./IndiceVerticalizacao/IndiceVerticalizacao";
import GenericTable from "./GenericTable/GenericTable";
import GenericDataFormatter from "./GenericTable/GenericDataFormatter";
import config from "./config";

const useGenericTableFor = [
    'faixa_etaria', 'domicilios_por_numero_moradores', 'domicilios_por_tipo_moradia',
    'domicilios_por_condicao_ocupacao', 'domicilios_por_numero_moradores', 'domicilios_por_faixa_de_renda',
    'populacao_por_faixa_de_renda'
];

class SocioDemografiaModal extends React.Component {

    constructor(props) {
        super(props);

        let formatter = new GenericDataFormatter([], props.overlays, config['faixa_etaria']);

        let opcoesKeys = Object.keys(config);

        let opcoes = opcoesKeys.map((key) => {
            return {
                value: key,
                label: config[key].title
            }
        });

        this.state = {
            opcoes: opcoes,
            loading: false,
            tipoInfo: {},
            errors: {},
            dados: [],
            formatter: formatter
        };
    }

    handleSubmit = async () => {
        let tipoInfo = this.state.tipoInfo;

        if (!tipoInfo || tipoInfo === '') {
            this.setState({
                errors: {
                    tipoInfo: 'O campo informação é obrigatório'
                }
            });
        }

        let dados = await this.props.loadDadosSensoDemografico(tipoInfo);


        let configuracao = config[tipoInfo];

        console.log(configuracao, config);
        let formatter = new GenericDataFormatter(dados, this.props.overlays, configuracao);

        await this.setState({
            ...this.state,
            dados: dados || [],
            formatter: formatter
        });

    }

    onChangeFormFieldValue = async (field, value) => {
        await this.setState({
            [field]: value
        });

        await this.handleSubmit();
    }

    render() {
        return (
            <div>
                <Modal size={'lg'} show={this.props.show} onHide={this.props.handleClose} aria-labelledby="example-custom-modal-styling-title">
                    <Modal.Body>
                        <h3>Dados de Sociodemografia</h3>
                        <div className="card">
                            <div className="card-body">
                                <div className="form-group">
                                    <label htmlFor="informacao">Informação</label>
                                    <select className="form-control"
                                            value={this.state.tipoInfo?.value}
                                            onChange={(e) => this.onChangeFormFieldValue('tipoInfo', e.target.value)}
                                            id="informacao">
                                        <option>Selecione</option>
                                        {this.state.opcoes.map((opcao, index) => {
                                            return <option key={index} value={opcao.value}>{opcao.label}</option>
                                        })}
                                    </select>
                                    {this.state.errors.tipoInfo &&
                                        <small className="text-danger">{this.state.errors.tipoInfo}</small>}
                                </div>

                                {this.props.loading && <>
                                    <InnerLoading/>
                                </>}

                                {useGenericTableFor.includes(this.state.tipoInfo) && !this.props.loading && <>
                                    <GenericTable
                                        formatter={this.state.formatter}
                                    />
                                </>
                                }

                                {this.state.tipoInfo === 'indice_verticalizacao' && !this.props.loading && <>
                                    <IndiceVerticalizacao
                                        overlays={this.props.overlays}
                                        data={this.state.dados}
                                        formatter={this.state.formatter}
                                    />
                                </>
                                }

                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" className="btn-block" onClick={async (e) => {
                            await this.setState({
                                ...this.state,
                                tipoInfo: ''
                            });

                            this.props.handleClose(e);
                        }}>
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default SocioDemografiaModal;
