import {React, Component}  from "react";
import { v4 as uuidv4 } from 'uuid';
import { InputNumber } from 'primereact/inputnumber';

class FormAdicionarUnidade extends Component {

    constructor(props) {
        super(props);

        this.state = {
            nome: '',
            preco: 0.00,
            area_privativa: 0.00,
            quantidade: 0,
            uuid: ''
        }


    }

    componentDidMount() {

    }

    formIsValid = ()=>{
        if( this.state.nome === '' || this.state.area_privativa === '' || this.state.quantidade === '' ||  this.state.preco === 0.00 ){
            return false;
        }

        return true;
    }

    onAddUnit = () => {

        let isValid = this.formIsValid();

        if( !isValid ){
            alert("Preencha todos os campos para adicionar uma unidade");

            return;
        }


        this.props.onAddUnit({
            nome: this.state.nome,
            area_privativa: this.state.area_privativa,
            preco: this.state.preco,
            quantidade: this.state.quantidade,
            uuid: this.state.uuid ? this.state.uuid : uuidv4()
        });

        this.setState({
            nome: '',
            area_privativa: 0.00,
            quantidade: 0,
            uuid: ''
        });
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
    }

    static getDerivedStateFromProps(props, state) {
        if( props.selectedUnidade && props.selectedUnidade.uuid !== state.uuid ){
            return {
                nome: props.selectedUnidade.nome,
                area_privativa: props.selectedUnidade.area_privativa,
                preco: props.selectedUnidade.preco,
                quantidade: props.selectedUnidade.quantidade,
                uuid: props.selectedUnidade.uuid
            };
        }

        return null;
    }



    render() {
        return (
            <div className="add_unit">
                <div className="d-flex justify-content-between" style={{marginBottom: '2px'}}>
                    <div>
                        <label htmlFor="name">Tipo de Unidade</label>
                        <input type="text"
                               onChange={this.onChange}
                               value={this.state.nome}
                               className="form-control"
                               id="tipo_unidade" name="nome"/>
                    </div>
                    <div className="ml-2">
                        <label htmlFor="name">Área Privativa</label>

                        <InputNumber
                            value={this.state.area_privativa}
                            onValueChange={(e)=>{
                                this.setState({
                                    ...this.state,
                                    area_privativa: e.value
                                });
                            }}
                            inputClassName="form-control"
                            name="area_privativa"
                            id="area_privativa"
                            locale="pt-BR" minFractionDigits={2} />
                    </div>
                    <div className="ml-2">
                        <label htmlFor="name">Preço</label>

                        <InputNumber
                            value={this.state.preco}
                            onValueChange={(e)=>{
                                this.setState({
                                    ...this.state,
                                    preco: e.value
                                });
                            }}
                            inputClassName="form-control"
                            name="area_privativa"
                            id="preco"
                            locale="pt-BR" minFractionDigits={2} />

                    </div>
                    <div className="ml-2">
                        <label htmlFor="name">Unidades</label>
                        <input type="number"
                               onChange={this.onChange}
                                 value={this.state.quantidade}
                               className="form-control"
                               id="numero_unidades" name="quantidade"/>
                    </div>
                </div>
                <button className="btn btn-success btn-block" onClick={this.onAddUnit}>
                    <i className="fa fa-plus"></i>
                    Adicionar novo tipo
                </button>
            </div>

        );
    }
}

export default FormAdicionarUnidade;
