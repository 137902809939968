import { React, Component } from 'react';
import ReactDOM from "react-dom";
import { Button, Modal } from "react-bootstrap";
import SimpleMap from "../SimpleMap/SimpleMap";
import { Marker } from "react-google-maps";
import './styles.css';
import InnerLoading from "../Viabilidade/InnerLoading";
import HereMapsService from "../../services/HereMapsService";
class BuscaGeolocalizacao extends Component {

    constructor(props) {
        super(props);
        this.state = {
            latitude: null,
            longitude: null,
            error: null,
            results: [],
            loading: false,
            showModal: false,
            mapCenter: {
                lat: -23.5505199,
                lng: -46.63330939999999
            }
        };
    }

    handleClose = () => {
        this.reloadPanorama();
        this.setState({ showModal: false });
    }

    reloadPanorama = () => {

        const lat = parseFloat(document.querySelector('#latitude').value)
        const lng = parseFloat(document.querySelector('#longitude').value)

        const myLatlng = new google.maps.LatLng(lat, lng);

        const marker = new google.maps.Marker({
            position: myLatlng,
            title: "Point",
            draggable: true,
        });

        const mapOptions = {
            zoom: 15,
            center: myLatlng,
            scrollwheel: false,
        }

        var map = new google.maps.Map(document.getElementById("map_form"), mapOptions);

        var panorama = new google.maps.StreetViewPanorama(
            document.getElementById('map_form'), {
            position: {
                lat: lat,
                lng: lng
            },
            pov: {
                heading: 165,
                pitch: 0
            },
            visible: false,
            linksControl: true,
            panControl: true,
            enableCloseButton: true,
        });

        let contentString = `<div>
                              <p> Latitude: ${marker.getPosition().lat()}</p>
                              <p> Longitude: ${marker.getPosition().lng()}</p>
                            </div>`;

        const infowindow = new window.google.maps.InfoWindow({
            content: contentString
        });

        google.maps.event.addListener(marker, 'dragend', function (ev) {
            contentString = contentString;

            infowindow.setContent(contentString);

            $('#latitude').val(marker.getPosition().lat());
            $('#longitude').val(marker.getPosition().lng());
        });

        marker.addListener('click', function () {
            infowindow.open(map, marker);
        });

        map.setStreetView(panorama);
        marker.setMap(map);

        map.panTo(myLatlng);

        window.map = map;
        window.panorama = panorama;
        window.marker = marker;
    }

    getEnderecoFromForm = () => {
        let endereco = document.querySelector('#endereco').value;
        let bairro = document.querySelector('#bairro').value;
        let cidade = document.querySelector('#cidade').value;
        let estado = document.querySelector('#estado').value;
        let numero = document.querySelector('#numero').value;
        let cep = document.querySelector('#cep').value;

        let addressComponents = {
            endereco: endereco,
            numero: numero,
            bairro: bairro,
            cidade: cidade,
            estado: estado,
            cep: cep
        };

        let values = Object.values(addressComponents);

        let enderecoFormatado = values
            .filter(value => value !== '')
            .join(", ")

        return {
            endereco: enderecoFormatado.trim(),
            addressComponents: addressComponents
        }
    }

    buscar = async (event) => {
        event.preventDefault();

        this.setState({ showModal: true, loading: true });

        try {
            let address = this.getEnderecoFromForm().endereco;
            let response = await HereMapsService.getGeolocation(address);

            let center = response.data?.items[0].position;

            this.setState({
                ...this.state,
                mapCenter: center,
                results: response.data?.items,
            })

        } catch (error) {
            console.log(error);
        } finally {
            this.setState({ loading: false });
        }
    }

    selecionar(result) {
        document.querySelector('#latitude').value = result.position.lat;
        document.querySelector('#longitude').value = result.position.lng;

        //mapa do form de cadastro do empreendimento
        window.marker.setPosition(result.position);

        window.map.panTo(result.position);
        this.handleClose();
    }



    render() {
        let endereco = this.getEnderecoFromForm().endereco;


        return (
            <div className="col-12">
                <button type="button" onClick={this.buscar} className="btn btn-default">
                    <i className="fa fa-map-marker"></i>
                    Buscar Geolocalização
                </button>

                <Modal size="lg" show={this.state.showModal} onHide={this.handleClose}
                    aria-labelledby="example-custom-modal-styling-title">
                    <Modal.Body>
                        <div className="panel">
                            <p className="alert alert-info">
                                {endereco}
                            </p>
                        </div>
                        {this.state.loading && <InnerLoading />}
                        {!this.state.loading && <>
                            <p>Foram encontrados {this.state.results.length} resultados</p>
                            <div id="results-container">

                                <ul className="list-group">
                                    {this.state.results.map((result, index) => {
                                        return <li key={result.id}
                                            className="list-group-item">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    {index + 1} - {result.title}
                                                </div>
                                                <div>
                                                    <button className="btn btn-sm btn-default"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            this.selecionar(result);
                                                        }}
                                                        type="button" style={{ marginRight: '3px' }}>
                                                        Selecionar
                                                    </button>

                                                    <button className="btn btn-sm btn-default" type="button"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            window.googleMap.panTo(result.position)
                                                        }}>
                                                        Ver no Mapa
                                                    </button>
                                                </div>

                                            </div>
                                        </li>
                                    })}
                                </ul>
                            </div>
                            <hr />
                        </>}

                        {!this.state.loading &&
                            <SimpleMap
                                currentPosition={this.state.mapCenter} zoom={13}>
                                {this.state.results.map((result, index) => {
                                    let label = index + 1 + '';
                                    return <Marker key={`marker-${result.id}`}
                                        position={{
                                            lat: result.position.lat,
                                            lng: result.position.lng
                                        }}
                                        label={label}
                                    />
                                })}
                            </SimpleMap>}
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="pull-right">
                            <Button variant="primary" className="btn-block" onClick={this.handleClose}>
                                Fechar
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}

if (document.getElementById('busca-geolocalizacao')) {
    ReactDOM.render(<BuscaGeolocalizacao />, document.getElementById('busca-geolocalizacao'));
}
