import React, {Component} from "react";
import SlidingPanel from "react-sliding-side-panel";
import ModalEmpreendimentos from "./ModalEmpreendimentos";
import ModalRevendaEmpreendimentos from "./MapaRevendaLocacao/ModalEmpreendimentos";
import InfoCard from "./InfoCard";
import TablePadroes from "./TablePadroes";
import TablePrecoPadroes from "./TablePrecosPadroes";
import TableQuartos from "./TableQuartos";
import TableQuartosOferta from "./TableQuartosOferta";
import TableVGV from "./TableVGV";
import ModalCompareArea from "./ModalCompareArea";
import ModalComparePolosGeradores from "./ModalComparePolosGeradores";
import ModalRanges from "./ModalRanges";
import ModalPadraoInfo from "./ModalPadraoInfo";
import ModalPadraoInfoComercial from "./ModalPadraoInfoComercial";
import ModalPadraoInfoCasas from "./ModalPadraoInfoCasas";
import ModalHeatMap from "./ModalHeatMap";
import estados from "../util/estados";

class SlidingPanelEmpreendimento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPaneOpen: props.isOpen,
            isPaneOpenLeft: false,
            selectedAreas: this.props.selectedAreas,
            panel_empreendimento_visible: this.props.panel_empreendimento_visible,
            estados: estados,
            cidades: [],
            modalCompareAreasOpen: false,
            modalRangesOpen: false,
            modalPadraoInfo: false,
            modalPadraoInfoComercial: false,
            ModalPadraoInfoCasas: false,
            ranges: [],
            modalHeatMapOpen: false,
            show_polos_geradores: this.props.show_polos_geradores,
            modalComparePolosOpen: false,
        };
    }

    componentDidMount() {}

    print = () => {
        var element = document.getElementById("pdf");

        axios
            .post("/map-print", {
                html: element.innerHTML, empreendimentos: this.props.empreendimentos,
            }, {
                responseType: "arraybuffer", headers: {
                    "Content-Type": "application/json", Accept: "application/pdf",
                },
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "relatorio.pdf"); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((e) => {
                console.log(e);
            });
    };

    showCompareModal = () => {
        this.setState({
            ...this.state, modalCompareAreasOpen: true,
        });

        $("#btn-results").click();
    };


    showCompareModalPolos = () => {
        this.setState({
            ...this.state, modalComparePolosOpen: true,
        });

        $("#btn-results").click();
    };

    showRangesModal = () => {
        this.setState({
            ...this.state, modalRangesOpen: true,
        });

        $("#btn-results").click();
    };

    showModalPadraoInfo = () => {
        this.setState({
            ...this.state, modalPadraoInfo: true,
        });

        $("#btn-results").click();
    };

    showModalPadraoInfoComercial = () => {
        this.setState({
            ...this.state, modalPadraoInfoComercial: true,
        });

        $("#btn-results").click();
    };

    showModalPadraoInfoCasas = () => {
        this.setState({
            ...this.state, ModalPadraoInfoCasas: true,
        });

        $("#btn-results").click();
    };

    filterHorizontal = (meta_data, useCasas = false) => {
        if (useCasas) {
            return meta_data.filter((index) => {
                return index.padrao != "Loteamento Fechado" && index.padrao != "Loteamento Aberto" &&  index.padrao != 'Condomínio de Casas/Sobrados'
            })
        }
        return meta_data.filter((index) => {
            return index.padrao == "Loteamento Fechado" || index.padrao == "Loteamento Aberto"
        })
    }

    render = () => {
        let selectedAreas = this.props.overlays;
        let setPolosGeradoresCurrentShowing = this.props.setPolosGeradoresCurrentShowing;
        return (<>
                <div
                    className="col-md-4"
                    style={{
                        backgroundColor: "#ffffff", overflow: "scroll", height: 0,
                    }}
                >
                    <ModalCompareArea
                        show={this.state.modalCompareAreasOpen}
                        closeModal={() => this.setState({modalCompareAreasOpen: false})}
                        selectedAreas={selectedAreas}
                    ></ModalCompareArea>

                    <ModalHeatMap
                        show={this.state.modalHeatMapOpen}
                        closeModal={() => this.setState({modalHeatMapOpen: false})}
                        addHeatMap={this.props.addHeatMap}
                    />

                    <ModalComparePolosGeradores
                        show={this.state.modalComparePolosOpen}
                        closeModal={() => this.setState({modalComparePolosOpen: false})}
                        selectedAreas={selectedAreas}
                    ></ModalComparePolosGeradores>

                    <ModalRanges
                        show={this.state.modalRangesOpen}
                        ranges={this.props.ranges}
                        closeModal={() => this.setState({modalRangesOpen: false})}
                        addRange={this.props.addRange}
                        removeRange={this.props.removeRange}
                        replaceMarkers={this.props.replaceMarkers}
                    ></ModalRanges>


                    <div ref={(ref) => (this.el = ref)}>
                        <SlidingPanel
                            style={{backgroundColor: "#ffffff"}}
                            className="some-custom-class"
                            panelContainerClassName="side-panel slider-align"
                            panelClassName=""
                            isOpen={this.props.isOpen}
                            title="Informaçoes"
                            // size={25}
                            size={35}
                            noBackdrop={true}
                            type={"right"}
                        >
                            <>
                                <button
                                    id="btn-results"
                                    className="btn btn-sm background-brain"
                                    style={{
                                        position: "absolute",
                                        float: "left",
                                        top: "48%",
                                        right: "35%",
                                        zIndex: 1500,
                                        height: 80,
                                        width: 20,
                                        borderTopLeftRadius: 5,
                                        borderBottomLeftRadius: 5,
                                        borderBottomRightRadius: 0,
                                        borderTopRightRadius: 0,
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        $("#card-results").toggleClass("collapsed");
                                        $("#btn-results").toggleClass("btn-results-off");
                                        $(".side-panel").toggleClass("side-panel-off");
                                        $(".sliding-panel-container").toggleClass("active");
                                        if ($("#results-icon").hasClass("fa-angle-double-left")) {
                                            $("#results-icon").removeClass("fa-angle-double-left");
                                            $("#results-icon").addClass("fa-angle-double-right");
                                        } else if ($("#results-icon").hasClass("fa-angle-double-right")) {
                                            $("#results-icon").removeClass("fa-angle-double-right");
                                            $("#results-icon").addClass("fa-angle-double-left");
                                        }
                                    }}
                                >
                                    <i
                                        id="results-icon"
                                        className="fas fa-angle-double-right"
                                        aria-hidden="true"
                                        style={{
                                            position: "relative", left: -5,
                                        }}
                                    ></i>
                                </button>

                                <ModalPadraoInfo
                                    show={this.state.modalPadraoInfo}
                                    closeModal={() => this.setState({
                                        modalPadraoInfo: false,
                                    })}
                                ></ModalPadraoInfo>

                                <ModalPadraoInfoComercial
                                    show={this.state.modalPadraoInfoComercial}
                                    closeModal={() => this.setState({
                                        modalPadraoInfoComercial: false,
                                    })}
                                />
                                <ModalPadraoInfoCasas
                                    show={this.state.ModalPadraoInfoCasas}
                                    closeModal={() => this.setState({
                                        ModalPadraoInfoCasas: false,
                                    })}
                                />

                                <div
                                    id="card-results"
                                    className="card card-results"
                                    style={{marginBottom: 0}}
                                >
                                    <div
                                        className="card-header"
                                        style={{paddingTop: 40}}
                                    >
                                        {/* <button className="close" style={{float : 'left'}} onClick={this.props.closePane}>x</button> */}
                                    </div>
                                    <div
                                        className="card-body"
                                        style={{
                                            paddingRight: 25, backgroundColor: "white",
                                        }}
                                    >
                                        <div
                                            className="container"
                                            style={{overflowX: "visible"}}
                                        >
                                            {this.props.empreendimento !== undefined && (<>
                                                    {this.props.empreendimento.tipo_negocio && (
                                                        <ModalRevendaEmpreendimentos
                                                            closeModalEmpreendimento={this.props.closeModalEmpreendimento}
                                                            show={this.props.showModalEmpreendimento}
                                                            empreendimento={this.props.empreendimento}
                                                            togglePainelEmpreendimento={this.props.togglePainelEmpreendimento}
                                                            showModalEmpreendimento={this.props.showModalEmpreendimento}
                                                            aria-labelledby="example-custom-modal-styling-title"
                                                        ></ModalRevendaEmpreendimentos>)}
                                                    {this.props.empreendimento.tipo_empreendimento && (
                                                        <ModalEmpreendimentos
                                                            closeModalEmpreendimento={this.props.closeModalEmpreendimento}
                                                            show={this.props.showModalEmpreendimento}
                                                            empreendimento={this.props.empreendimento}
                                                            togglePainelEmpreendimento={this.props.togglePainelEmpreendimento}
                                                            showModalEmpreendimento={this.props.showModalEmpreendimento}
                                                            aria-labelledby="example-custom-modal-styling-title"
                                                        ></ModalEmpreendimentos>)}
                                                </>)}
                                            <div id="pdf">
                                                {this.props.loadingMetadata && (<>
                                                        <h3>Carregando Meta Dados</h3>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                height: "100%",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <img src="/img/loader.gif"/>
                                                        </div>
                                                    </>)}
                                                {!this.props.loadingMetadata && (<>
                                                        <h3 className="sliding-map-title">
                                                            Áreas Selecionadas
                                                        </h3>
                                                        <hr/>

                                                        <div
                                                            className="container-fluid"
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <div className="row">
                                                                {this.props.kml_bairros.length > 0 && (
                                                                    <div className="col-6">
                                                                        <button
                                                                            className="btn btn-sm btn-block btn-round btn-info-darkblue"
                                                                            onClick={this.props.kmlFunction}
                                                                        >
                                                                            {this.props.showKMl === false ? "Mostrar" : "Esconder"}{" "}
                                                                            Bairros
                                                                        </button>
                                                                    </div>)}

                                                                <div className="col-6">
                                                                    <button
                                                                        className="btn btn-sm btn-info-blue btn-block btn-round"
                                                                        onClick={this.showCompareModal}
                                                                    >
                                                                        Comparar
                                                                        Áreas
                                                                    </button>
                                                                </div>

                                                                <div className="col-6">
                                                                    <button
                                                                        className="btn btn-sm btn-default btn-info-darkblue btn-block btn-round"
                                                                        onClick={this.showRangesModal}
                                                                    >
                                                                        Personalizar
                                                                        Marcadores
                                                                    </button>
                                                                </div>
                                                                {window.user_can_view_faixa_etaria && <>
                                                                    <div className="col-6">
                                                                        <button
                                                                            className="btn btn-sm btn-default btn-info-darkblue btn-block btn-round"
                                                                            onClick={this.props.openModalDadosSocioDemograficos}
                                                                        >
                                                                            Dados de Sociodemográfia
                                                                        </button>
                                                                    </div>
                                                                </>}


                                                                {window.authenticatedUserCanQueryPolos && (<>
                                                                        <div className="col-6">
                                                                            <button
                                                                                className="btn btn-sm btn-info-blue btn-block btn-round"
                                                                                onClick={this.showCompareModalPolos}
                                                                            >
                                                                                Comparar
                                                                                Polos
                                                                                Geradores
                                                                            </button>
                                                                        </div>
                                                                    </>)}

                                                                {window.authenticatedUserCanMakeHeatMap && (<>
                                                                        <div
                                                                            className={"col-6"}
                                                                        >
                                                                            <button
                                                                                className="btn btn-sm btn-block btn-info-darkblue btn-secondary btn-round"
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        modalHeatMapOpen: true,
                                                                                    });
                                                                                    $("#btn-results").click();
                                                                                }}
                                                                            >
                                                                                Mapa
                                                                                de
                                                                                Calor
                                                                            </button>
                                                                        </div>
                                                                    </>)}
                                                                {window.authenticatedUserCanSeeRenda &&
                                                                <>
                                                                    <div
                                                                        className={"col-6"}
                                                                    >
                                                                        <button
                                                                            className="btn btn-sm btn-block btn-info-darkblue btn-secondary btn-round"
                                                                            onClick={() => {
                                                                                this.props.showIbgeInfo()
                                                                            }}
                                                                        >
                                                                            Dados de Renda
                                                                        </button>
                                                                    </div>
                                                                </>}

                                                            </div>
                                                        </div>
                                                        {selectedAreas.map((selectedArea, index) => {
                                                            let type = selectedArea.type;

                                                            if (type === "cidade") {
                                                                if (this.props.cidades.length === 0 && this.props.estados.length > 0) {
                                                                    type = "Estado";
                                                                }
                                                            }

                                                            return (<div
                                                                    key={`selected-area-${index}`}
                                                                >
                                                                    {selectedArea.meta_data !== undefined && (<>
                                                                            <InfoCard
                                                                                key={selectedArea.meta_data.cache_key}
                                                                                index={index}
                                                                                selectedArea={selectedArea}
                                                                                color={selectedArea.color}
                                                                                meta_data={selectedArea.meta_data}
                                                                                type={type}
                                                                                totalEmpreendimentos={selectedArea.meta_data.total_empreendimentos}
                                                                                oferta_final={selectedArea.meta_data.oferta_final}
                                                                                oferta_lancada={selectedArea.meta_data.oferta_lancada}
                                                                                unidadesVendidas={selectedArea.meta_data.total_unidades_vendidas}
                                                                                unidadesDisponiveis={selectedArea.meta_data.total_unidades - selectedArea.meta_data.total_unidades_vendidas}
                                                                                valorMedioUnidades={selectedArea.meta_data.valor_medio_unidades}
                                                                                valorMedioMetroQuadrado={selectedArea.meta_data.valor_medio_metro_quadrado}
                                                                                setPolosGeradoresCurrentShowing={setPolosGeradoresCurrentShowing}
                                                                                polos={selectedArea.polos_geradores}
                                                                                polos_cache_key={selectedArea.polos_geradores_cache_key}
                                                                                cidades={this.props.cidades}
                                                                                estados={this.props.estados}
                                                                                editShapeName={this.props.editShapeName}
                                                                                showRenda={this.props.showRenda}
                                                                                rendasPorArea={this.props.rendasPorArea}
                                                                                setLoading={this.props.setLoading}
                                                                            />

                                                                            {selectedArea.meta_data.tipos_selecionados.includes("Vertical") && (<>
                                                                                    <TablePadroes
                                                                                        padroes={selectedArea.meta_data.infos_padrao}
                                                                                        totalEmpreendimentos={selectedArea.meta_data.total_empreendimentos}
                                                                                        oferta_lancada={selectedArea.meta_data.oferta_lancada}
                                                                                        oferta_final={selectedArea.meta_data.oferta_final}
                                                                                        color={selectedArea.color}
                                                                                        title={"Vertical"}
                                                                                        unidadesDisponiveis={selectedArea.meta_data.total_unidades - selectedArea.meta_data.total_unidades_vendidas}
                                                                                        showModalPadraoInfo={this.showModalPadraoInfo}
                                                                                    />

                                                                                    <TablePrecoPadroes
                                                                                        padroes={selectedArea.meta_data.precos_padrao}
                                                                                        color={selectedArea.color}
                                                                                        title={"Vertical"}
                                                                                        showModalPadraoInfo={this.showModalPadraoInfo}
                                                                                    />
                                                                                    <TableQuartos
                                                                                        tipologias={selectedArea.meta_data.infos_quartos}
                                                                                        color={selectedArea.color}
                                                                                    />
                                                                                    <TableQuartosOferta
                                                                                        tipologias={selectedArea.meta_data.infos_quartos_oferta}
                                                                                        color={selectedArea.color}
                                                                                    />
                                                                                    <TableVGV
                                                                                        padroes={selectedArea.meta_data.infos_padrao}
                                                                                        title={"Vertical"}
                                                                                        type={"de Oferta Final"}
                                                                                        color={selectedArea.color}
                                                                                        showModalPadraoInfo={this.showModalPadraoInfo}
                                                                                    />
                                                                                    <TableVGV
                                                                                        padroes={selectedArea.meta_data.infos_padrao}
                                                                                        title={"Vertical"}
                                                                                        type={"Lançado"}
                                                                                        color={selectedArea.color}
                                                                                        showModalPadraoInfo={this.showModalPadraoInfo}
                                                                                    />
                                                                                </>)}

                                                                            {selectedArea.meta_data.tipos_selecionados.includes("Horizontal") && (<>
                                                                                    <TablePadroes
                                                                                        padroes={this.filterHorizontal(selectedArea.meta_data.infos_padrao_horizontal)}
                                                                                        totalEmpreendimentos={selectedArea.meta_data.total_empreendimentos}
                                                                                        oferta_lancada={selectedArea.meta_data.oferta_lancada}
                                                                                        oferta_final={selectedArea.meta_data.oferta_final}
                                                                                        color={selectedArea.color}
                                                                                        title={"Horizontal"}
                                                                                        unidadesDisponiveis={selectedArea.meta_data.total_unidades - selectedArea.meta_data.total_unidades_vendidas}
                                                                                    />

                                                                                    <TablePrecoPadroes
                                                                                    padroes={this.filterHorizontal(selectedArea.meta_data.precos_padrao_horizontal)}
                                                                                        color={selectedArea.color}
                                                                                        title={"Horizontal"}
                                                                                    />
                                                                                    <TableVGV
                                                                                        padroes={this.filterHorizontal(selectedArea.meta_data.infos_padrao_horizontal)}

                                                                                        title={"Horizontal"}
                                                                                        type={"de Oferta Final"}
                                                                                        color={selectedArea.color}
                                                                                    />
                                                                                    <TableVGV
                                                                                        padroes={this.filterHorizontal(selectedArea.meta_data.infos_padrao_horizontal)}
                                                                                        title={"Horizontal"}
                                                                                        type={"Lançado"}
                                                                                        color={selectedArea.color}
                                                                                    />

                                                                                    <TablePadroes
                                                                                        padroes={this.filterHorizontal(selectedArea.meta_data.infos_padrao_horizontal, true)}
                                                                                        totalEmpreendimentos={selectedArea.meta_data.total_empreendimentos}
                                                                                        oferta_lancada={selectedArea.meta_data.oferta_lancada}
                                                                                        oferta_final={selectedArea.meta_data.oferta_final}
                                                                                        color={selectedArea.color}
                                                                                        title={"Horizontal - Condomínio de Casas/Sobrados"}
                                                                                        unidadesDisponiveis={selectedArea.meta_data.total_unidades - selectedArea.meta_data.total_unidades_vendidas}
                                                                                        showModalPadraoInfoCasas={this.showModalPadraoInfoCasas}
                                                                                    />

                                                                                    <TablePrecoPadroes
                                                                                    padroes={this.filterHorizontal(selectedArea.meta_data.precos_padrao_horizontal, true)}
                                                                                        color={selectedArea.color}
                                                                                        title={"Horizontal - Condomínio de Casas/Sobrados"}
                                                                                        showModalPadraoInfoCasas={this.showModalPadraoInfoCasas}
                                                                                    />
                                                                                    <TableVGV
                                                                                        padroes={this.filterHorizontal(selectedArea.meta_data.infos_padrao_horizontal, true)}

                                                                                        title={"Horizontal - Condomínio de Casas/Sobrados"}
                                                                                        type={"de Oferta Final"}
                                                                                        color={selectedArea.color}
                                                                                        showModalPadraoInfoCasas={this.showModalPadraoInfoCasas}
                                                                                    />
                                                                                    <TableVGV
                                                                                        padroes={this.filterHorizontal(selectedArea.meta_data.infos_padrao_horizontal, true)}
                                                                                        title={"Horizontal - Condomínio de Casas/Sobrados"}
                                                                                        type={"Lançado"}
                                                                                        color={selectedArea.color}
                                                                                        showModalPadraoInfoCasas={this.showModalPadraoInfoCasas}
                                                                                    />
                                                                                </>)}

                                                                            {selectedArea.meta_data.tipos_selecionados.includes("Comercial") && (<>
                                                                                    <TablePadroes
                                                                                        padroes={selectedArea.meta_data.infos_padrao_comercial}
                                                                                        totalEmpreendimentos={selectedArea.meta_data.total_empreendimentos}
                                                                                        oferta_lancada={selectedArea.meta_data.oferta_lancada}
                                                                                        oferta_final={selectedArea.meta_data.oferta_final}
                                                                                        color={selectedArea.color}
                                                                                        title={"Comercial"}
                                                                                        unidadesDisponiveis={selectedArea.meta_data.total_unidades - selectedArea.meta_data.total_unidades_vendidas}
                                                                                        showModalPadraoInfoComercial={this.showModalPadraoInfoComercial}

                                                                                    />

                                                                                    <TablePrecoPadroes
                                                                                        padroes={selectedArea.meta_data.precos_padrao_comercial}
                                                                                        color={selectedArea.color}
                                                                                        title={"Comercial"}
                                                                                        showModalPadraoInfoComercial={this.showModalPadraoInfoComercial}

                                                                                    />
                                                                                    <TableVGV
                                                                                        padroes={selectedArea.meta_data.infos_padrao_comercial}
                                                                                        title={"Comercial"}
                                                                                        type={"de Oferta Final"}
                                                                                        color={selectedArea.color}
                                                                                        showModalPadraoInfoComercial={this.showModalPadraoInfoComercial}

                                                                                    />
                                                                                     <TableVGV
                                                                                        padroes={selectedArea.meta_data.infos_padrao_comercial}
                                                                                        title={"Comercial"}
                                                                                        type={"Lançado"}
                                                                                        color={selectedArea.color}
                                                                                        showModalPadraoInfoComercial={this.showModalPadraoInfoComercial}

                                                                                    />
                                                                                </>)}
                                                                        </>)}
                                                                </div>);
                                                        })}
                                                    </>)}
                                            </div>
                                            <hr/>
                                        </div>
                                    </div>
                                </div>
                            </>
                        </SlidingPanel>
                    </div>
                </div>
            </>);
    };
}

export default SlidingPanelEmpreendimento;
