import {Component, React} from "react";
import _ from "lodash";
import FormModalEvolucao from "./FormModalEvolucaoObra";
import InnerLoading from "./InnerLoading";

export default class FluxoTotalTabela extends Component {
    constructor(props) {
        super(props);

        let range = _.range(0, 420);

        this.state = {
            loading: false,
            data: [],
            meses: range,
            itens: {
                despesas: [

                    {label: "Desembolso Financeiro", key: "desembolso_financeiro"},
                    {label: "Valor do terreno", key: "valor_terreno"},
                    {label: "Comissão sobre terreno", key: "comissao_sobre_terreno"},
                    {label: "Custo de manutenções futuras", key: "custo_manutencao_futura"},
                    {
                        label: "% de Admin. sobre escritório central",
                        key: "administracao_escritorio"
                    },
                    {label: "Custo com publicidade", key: "custo_publicidade"},
                    {label: "Pagamento do financiamento", key: "pagamento_financiamento"},
                    {label: "Comissão sobre vendas", key: "comissao_sobre_vendas"},
                    {label: "Impostos federais sobre o faturamento", key: "impostos_sobre_faturamento"},
                    {label: "Outros custos", key: "outros_custos"},
                    {label: "Total dos custos", key: "total_custos"},
                ],
                receitas: [
                    {label: "Receita proveniente do financiamento", key: "receita_financiamento"},
                    {label: "Receita proveniente de vendas", key: "receita_vendas"},
                    {label: "Receita proveniente de vendas pós a obra", key: "receita_venda_pos_obra"},
                    {label: "Total de Receitas", key: "total_receitas"},
                ],
                obra: [
                    {label: "Evolução fisica da Obra", key: "evolucao_fisica_obra"},
                    {label: "Evolução fisica da Obra Acumulado", key: "evolucao_fisica_obra_acumulada"},
                ],
                fechamento: [
                    {label: "Fluxo Mensal", key: "fluxo_mensal"},
                ],
            },
            showFormModalEvolucao: false,
            evolucao: [],
            imovel_uuid: '',
            downloading: false,
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            data: {
                ...props.fluxo_total
            },
            loading: props.loading_fluxo_total,
            imovel_uuid: props.imovel_uuid,
            evolucao: props.evolucao_obra,
            analise_uuid: props.analise_uuid,
        }

    }

    evolucaoItemChanged = (e, value) => {
        let evolucao = this.state.evolucao;

        let index = evolucao.findIndex((ev) => {
            return ev.id === e.id;
        });

        evolucao[index].evolucao = value;

        this.setState({
            ...this.state,
            evolucao: evolucao
        });
    };

    componentDidMount = async () => {

    }


    formatNumber = (number) => {

        if (!number || number == 0) {
            return "-";
        }

        number = Number(number);

        let formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });

        return formatter.format(number).replace("R$", "");
    }
    showFormModalEvolucao = () => {
        this.setState({
            ...this.state,
            showFormModalEvolucao: true
        });
    };

    handleCloseFormModalEvolucao = () => {
        this.setState({
            ...this.state,
            showFormModalEvolucao: false
        });
    };


    saveEvolucao = async () => {
        let acumulado = Number(this.state.evolucao[0]?.evolucao);
        let evolucaoPayload = this.state.evolucao.map((e, index) => {

            let newItem = {
                id: e.id,
                evolucao: e.evolucao,
                acumulado: acumulado,
                imovel_id: e.imovel_id,
                mes: e.mes,
            }

            acumulado += Number(e.evolucao);

            return newItem;

        });

        let response = await axios.post(route('viabilidade.evolucao_obra.post'), {
            evolucao: evolucaoPayload,
        });

        await this.props.loadData();

        this.setState({
            ...this.state,
            showFormModalEvolucao: false
        })
    };

    downloadExcel = async () => {
        try {
            this.setState({
                ...this.state,
                downloading: true
            });

            let response = await axios.get(route('viabilidade.fluxo_total.excel', this.props.analise_uuid), {
                responseType: 'blob',
            });

            let blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');

            a.href = url;
            a.download = response.headers['content-disposition'].split('filename=')[1];
            a.click();





        }catch (e) {
            console.log(e);
        }finally {
            this.setState({
                ...this.state,
                downloading: false
            });
        }
    }

    render() {

        if (this.state.loading) {
            return <InnerLoading/>
        }

        return (
            <div>
                <div style={{overflowX: "scroll", overflowY: "scroll"}} id="fluxo-total-tabela">
                    <div>
                        <button className="btn btn-lg btn-success"
                                onClick={this.showFormModalEvolucao}
                        >
                        <span className="font-weight-bold">
                            ATUALIZAR EVOLUÇÃO FÍSICA DA OBRA
                        </span>
                        </button>
                    </div>

                    {this.state.imovel_uuid !== '' && <>
                        <FormModalEvolucao
                            show={this.state.showFormModalEvolucao}
                            handleClose={this.handleCloseFormModalEvolucao}
                            save={this.saveEvolucao}
                            imovel_uuid={this.state.imovel_uuid}
                            evolucao={this.state.evolucao}
                            onChange={this.evolucaoItemChanged}
                        />
                    </>}

                    <table className="table" id="fluxo_total_table">
                        <thead>
                        <tr style={{
                            backgroundColor: '#5B7537',
                            borderColor: '#5B7537',
                            color: 'white',
                        }}>
                            <th className="horizontal_header fixed_column" style={{
                                backgroundColor: '#5B7537',
                                borderColor: '#5B7537',
                                color: 'white',
                            }}>Mês
                            </th>
                            {this.state.meses.map((mes, index) => {
                                return <th key={index}
                                           style={{
                                               backgroundColor: '#5B7537',
                                               borderColor: '#5B7537',
                                               color: 'white',
                                           }}
                                           className="text-center"
                                >
                                <span className="font-weight-bold text-center">
                                    {mes}
                                </span>
                                </th>
                            })}
                        </tr>
                        </thead>

                        <tbody>
                        {this.state.itens.obra.map((item, index) => {

                            let rowStyle = {
                                backgroundColor: "white"
                            }

                            if (index === this.state.itens.obra.length - 1) {
                                rowStyle = {
                                    borderBottom: "12px solid #5B7537",
                                    backgroundColor: "#c3e6cb"
                                }
                            }

                            return (
                                <tr key={item.key} style={{...rowStyle}}>
                                    <td className="fixed_column" style={{backgroundColor: rowStyle.backgroundColor}}>
                                    <span className="text-success font-weight-bold">
                                        <strong>
                                            {item.label}
                                        </strong>
                                    </span>
                                    </td>
                                    {this.state.meses.map((mes, index) => {
                                        let mesKey = "mes_" + mes;
                                        return <td key={index} className="text-right"
                                                   style={{backgroundColor: rowStyle.backgroundColor}}>
                                        <span>
                                            {this.formatNumber(this.state?.data[mesKey]?.[item.key])}
                                        </span>
                                        </td>
                                    })}
                                </tr>
                            )
                        })}

                        {this.state.itens.despesas.map((item, index) => {
                            let rowStyle = {
                                backgroundColor: "white"
                            }

                            let spanTextClassName = "text-danger";

                            if (index === this.state.itens.despesas.length - 1) {
                                rowStyle = {
                                    borderBottom: "12px solid #5B7537",
                                    backgroundColor: "#f5c6cb"
                                }

                                spanTextClassName = "text-dark";
                            }

                            return (
                                <tr key={item.key} style={{...rowStyle}}>
                                    <td className="fixed_column" style={{backgroundColor: rowStyle.backgroundColor}}>
                                    <span className="text-danger font-weight-bold">
                                        {item.label}
                                    </span>
                                    </td>
                                    {this.state.meses.map((mes, index) => {
                                        let mesKey = "mes_" + mes;


                                        return <td key={index} className="text-right"
                                                   style={{backgroundColor: rowStyle.backgroundColor}}>
                                            <span className={spanTextClassName}>
                                                {this.formatNumber(this.state?.data[mesKey]?.despesas?.[item.key])}
                                            </span>
                                        </td>
                                    })}
                                </tr>
                            )
                        })}

                        {this.state.itens.receitas.map((item, index) => {
                            let rowStyle = {
                                backgroundColor: "white"
                            }

                            let spanTextClassName = "text-primary";

                            if (index === this.state.itens.receitas.length - 1) {
                                rowStyle = {
                                    borderBottom: "12px solid #5B7537",
                                    backgroundColor: "#b8daff"
                                }

                                spanTextClassName = "text-dark";
                            }


                            return (
                                <tr key={item.key} style={{...rowStyle}}>
                                    <td className="fixed_column" style={{backgroundColor: rowStyle.backgroundColor}}>
                                    <span className="text-primary font-weight-bold">
                                        {item.label}
                                    </span>
                                    </td>
                                    {this.state.meses.map((mes, index) => {
                                        let mesKey = "mes_" + mes;


                                        return <td key={index} className="text-right"
                                                   style={{backgroundColor: rowStyle.backgroundColor}}>
                                            <span className={spanTextClassName}>
                                                {this.formatNumber(this.state?.data[mesKey]?.receitas?.[item.key])}
                                            </span>
                                        </td>
                                    })}
                                </tr>
                            )
                        })}

                        {this.state.itens.fechamento.map((item, index) => {
                            let rowStyle = {
                                backgroundColor: "white"
                            }


                            if (index === this.state.itens.fechamento.length - 1) {
                                rowStyle = {
                                    borderBottomWidth: "12px solid #5B7537",
                                    backgroundColor: "#c3e6cb"
                                }
                            }

                            return (
                                <tr key={item.key} style={{...rowStyle}}>
                                    <td className="fixed_column" style={{backgroundColor: rowStyle.backgroundColor}}>
                                    <span className="text-dark font-weight-bold">
                                        {item.label}
                                    </span>
                                    </td>
                                    {this.state.meses.map((mes, index) => {
                                        let mesKey = "mes_" + mes;


                                        return <td key={index} className="text-right"
                                                   style={{backgroundColor: rowStyle.backgroundColor}}>
                                            <span className="text-dark">
                                                {this.formatNumber(this.state?.data[mesKey]?.[item.key])}
                                            </span>
                                        </td>
                                    })}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>

                <hr/>
                <button disabled={this.state.downloading} className="btn btn-sm btn-success"
                        style={{height: '60px'}} onClick={this.downloadExcel}>
                    <i className="fas fa-file-excel-o"></i> &nbsp; Exportar Fluxo para Excel &nbsp;
                    {this.state.downloading && <i className="fas fa-spinner fa-spin"></i>}
                    {!this.state.downloading && <>&nbsp;</>}
                </button>
            </div>
        );
    }
}

const styles = {
    fixedCollumn: {
        position: "fixed",
        backgroundColor: "white",
        width: "350px",
        minWidth: "340px",
        top: "auto",
    }
}
