import React, { Component } from 'react';
import ReactDOM from "react-dom";
import Loader from "../Loader";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt_BR from 'date-fns/locale/pt-BR';
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import transform from '../../services/RelatorioAcompanhamentoService';
import { Accordion, Card, Button } from "react-bootstrap";
import padroes from '../../util/padroes';
import agrupamento from '../../util/agrupamento';
import detalhes from '../../util/detalhes_options';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TabLancamento from './TabLancamento';
import TabVendas from './TabVendas';
import TabOfertaFinal from './TabOfertaFinal';
import TabIVV from './TabIVV';
import TabTicketMedio from './TabTicketMedio';
import TabTicketMedioMetro from './TabTicketMedioMetro';
import exportData from './Excel';




registerLocale('pt-BR', pt_BR);

let reportsServiceBaseUrl = process.env.MIX_BASE_URL_REPORTS;


export default class Index extends Component {

    defaultTiposOptions = [
        { label: 'Vertical', value: 'Vertical' },
        { label: 'Horizontal', value: 'Horizontal' },
        { label: 'Comercial', value: 'Comercial' },
    ];


    constructor(props) {
        super();

        this.state = {
            periodo_inicial: '',
            periodo_final: '',
            tipos: this.defaultTiposOptions,
            estados: [],
            cidadesOptions: [],
            cidades: [],
            bairrosOptions: [],
            bairros: [],
            clusterOptions: [],
            clusters: [],
            empreendimentosOptions: [],
            empreendimentos: [],
            incorporadorasOptions: [],
            incorporadoras: [],
            padrao: [],
            agrupamento: { label: 'Mensal', value: 'Mensal' },
            detalhes_para_exibir: [],
            detalhesParaExibirOptions: detalhes,
            tiposOptions: this.defaultTiposOptions,
            estadosOptions: [],
            errors: [],
            loading: false,
            periodos: [],
            periodos_variacao: [],
            padraoOptions: padroes,
            agrupamentoOptions: agrupamento,
            variacaoOptions: [
                { label: "Não Mostrar", value: "" },
                { label: "Mesmo Período do Ano anterior", value: "period_ano_anterior" },
                { label: "Período Imediatamente Anterior", value: "periodo_imediatamente_anterior" }
            ],
            variacao: '',
            submited: false,

            dataLancamentosPadrao: [],
            dataLancamentosTipologia: [],
            dataLancamentosTipo: [],

            dataVendasPadrao: [],
            dataVendasTipologia: [],
            dataVendasTipo: [],

            dataOfertaFinalPadrao: [],
            dataOfertaFinalTipologia: [],
            dataOfertaFinalTipo: [],

            dataIVVPadrao: [],
            dataIVVTipologia: [],
            dataIVVTipo: [],

            dataTicketMedioPadrao: [],
            dataTicketMedioTipologia: [],
            dataTicketMedioTipo: [],

            dataTicketMedioMetroPadrao: [],
            dataTicketMedioMetroTipo: [],
            dataTicketMedioMetroTipologia: [],

            projetos: [],
            projetosOptions: [],

            exibirGraficos: true,
            exibirTabelas: false,

        }
    }

    getRequestHeader = () => {
        return {
            headers: {
                Authorization: `Bearer ${this.state.user.jwt_token}`
            }
        }
    }

    setLoading = async (loading) => {
        await this.setState({
            loading: loading
        });
    }

    loadAuthUser = async () => {
        try {

            let response = await axios.get(route('user.auth'));

            if (response.status === 200) {
                this.setState({
                    ...this.state, user: response.data
                });
            }

        } catch (e) {
            console.log(e);
        }
    }

    loadEstados = async () => {
        try {

            let response = await axios.get(route('web.todos_estados.index'));

            if (response.status === 200) {
                this.setState({
                    ...this.state, estadosOptions: response.data
                });
            }

        } catch (e) {
            console.log(e);
        }
    }

    loadCidades = async () => {
        try {

            if (this.state.estados.length === 0) {
                return;
            }

            this.setLoading(true);

            let siglasEstados = this.state.estados.map(estado => estado.value);


            let response = await axios.post(route('web.cidades_por_estado.index'), {
                estados: siglasEstados
            });

            if (response.status === 200) {
                this.setState({
                    ...this.state,
                    cidadesOptions: [
                        { value: "Todos", label: "Selecionar Todos" },
                        ...response.data
                    ]
                });
            }

        } catch (e) {
            console.log(e);
        } finally {
            this.setLoading(false);
        }
    }

    loadBairros = async () => {
        try {

            if (this.state.cidades.length === 0) {
                return;
            }

            this.setLoading(true);

            let cidades = this.state.cidades.map(cidade => cidade.value);

            let response = await axios.post(route('web.bairros_por_cidade.index'), {
                cidades: cidades
            });

            if (response.status === 200) {
                this.setState({
                    ...this.state, bairrosOptions: response.data
                });
            }

        } catch (e) {
            console.log(e);
        } finally {
            this.setLoading(false);
        }
    }

    loadClusters = async () => {
        if (this.state.cidades.length === 0) {
            return;
        }

        try {
            this.setLoading(true);

            let cidades = this.state.cidades.map(cidade => cidade.value);

            let response = await axios.post(route('web.clusters_por_cidade.index'), {

                cidades: cidades
            });

            if (response.status === 200) {
                this.setState({
                    ...this.state, clusterOptions: response.data
                });
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.setLoading(false);
        }
    }
    loadEmpreendimentos = async () => {
        if (this.state.cidades.length === 0) {
            return;
        }

        try {
            this.setLoading(true);

            let cidades = this.state.cidades.map(cidade => cidade.value);

            let response = await axios.post(route('analise.temporal.v2.index.empreendimentos'), {

                cidades: cidades
            });

            if (response.status === 200) {
                this.setState({
                    ...this.state, empreendimentosOptions: response.data
                });
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.setLoading(false);
        }
    }

    buscaIncorporadoras = async (query) => {

        try {

            if (query.length < 2) {
                return [];
            }

            let response = await axios.get(route('web.busca_incorporadoras.index'), {
                params: {
                    query: query
                }
            });

            if (response.status === 200) {
                return response.data;
            }

        } catch (e) {
            console.log(e);
        } finally {

        }
    }

    getProjects = async () => {

        try {

            await this.setState({
                ...this.state,
                projetosOptions: [],
                projetos: []
            });


            let projetos = await axios.get(route('getMapaProjetos'))

            this.state.projetosOptions.push({ value: null, label: 'Selecione' });

            for (let index = 0; index < projetos.data.projects.length; index++) {
                console.log(projetos.data.projects[index]);
                this.state.projetosOptions.push({
                    value: projetos.data.projects[index].value,
                    label: projetos.data.projects[index].label
                });
            }

            console.log(this.state.projetosOptions);
        } catch (e) {
            console.log("Erro ao trabalhossalvos", e);
            console.log(e)
        }

    }

    changeProjeto = async (e) => {

        let response = await axios.get(route('getMapaProjetoByName', e.label))
        console.log(response);
        if (response.status == 200) {
            let projeto = response.data.project
            console.log("projeto");
            console.log(projeto);
            if (projeto.tipo_empreendimento != null) {
                let tipos = JSON.parse(projeto.tipo_empreendimento).map((index => {
                    console.log(index);
                    return { value: index, label: index }
                }))

                await this.setState({
                    ...this.state,
                    tipos: tipos
                })
            }

            if (projeto.nome_empreendimento != null) {
                let empreendimentos = JSON.parse(projeto.nome_empreendimento).map((empreendimento) => {
                    return { label: empreendimento, value: empreendimento };
                });
                this.setState({
                    empreendimentos: empreendimentos,
                });
            }


            if (projeto.padrao != null) {

                let padroes = JSON.parse(projeto.padrao);

                await this.setState({
                    ...this.state,
                    padrao: padroes,

                });
            }

            if (projeto.estado != null) {

                let estados = this.state.estadosOptions.filter((index) => {
                    return JSON.parse(projeto.estado).includes(index.value)
                });

                await this.setState({
                    ...this.state,
                    estados: estados,
                });

                await this.loadCidades();
            }

            if (projeto.cidade != null) {

                let projetoCidades = JSON.parse(projeto.cidade);

                let cidades = [];
                this.state.cidadesOptions.map((option) => {
                    console.log(option.options);
                    if (option.options != undefined) {
                        option.options.map((index) => {
                            console.log(index)
                            if (projetoCidades.some(cidade => cidade.label == index.label)) {
                                cidades.push(index);
                            }
                        })
                    }
                });

                await this.setState({
                    ...this.state,
                    cidades: cidades,
                });
                this.loadBairros();
                this.loadClusters();
                this.loadEmpreendimentos();
            }

            if (projeto.bairros != null) {
                let bairros = JSON.parse(projeto.bairros);

                await this.setState({
                    ...this.state, bairros: bairros,

                });
            }

            await this.setState({
                ...this.state,
                projetos: e
            })
        }
    }


    componentDidMount = async () => {
        try {
            Promise.all([this.loadAuthUser(), this.loadEstados(), this.getProjects()]);

            //map options
            let detalhesLabel = {};
            let detalhes_para_exibir = [];
            for (let i = 0; i < this.state.detalhesParaExibirOptions.length; i++) {
                for (let j = 0; j < this.state.detalhesParaExibirOptions[i].options.length; j++) {
                    detalhesLabel[this.state.detalhesParaExibirOptions[i].options[j].value] = this.state.detalhesParaExibirOptions[i].options[j].label;
                    detalhes_para_exibir.push(this.state.detalhesParaExibirOptions[i].options[j]);
                }
            }

            this.setState({
                ...this.state, detalhesLabel: detalhesLabel,
                ...this.state, detalhes_para_exibir: detalhes_para_exibir
            });

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Validação do formulário
     */
    validate = async () => {

        let errors = {};

        if (!this.state.periodo_inicial) {
            errors['periodo_inicial'] = ['O campo período inicial é obrigatório'];
        }


        if (!this.state.periodo_final) {
            errors['periodo_final'] = ['O campo período final é obrigatório'];
        }


        if (this.state.tipos.length === 0) {
            errors['tipos'] = ['O campo tipo é obrigatório'];
        }


        if (this.state.estados.length === 0) {
            errors['estados'] = ['O campo estado é obrigatório'];
        }


        if (this.state.cidades.length === 0) {
            errors['cidades'] = ['O campo cidade é obrigatório'];
        }

        if (Object.keys(errors).length > 0) {
            await this.setState({
                ...this.state,
                errors: errors
            });

            throw new Error('Validation Error');
        }
    }

    clearDatas = async () => {
        this.setState({
            ...this.state,
            errors: [],
            dataLancamentosPadrao: [],
            dataLancamentosTipologia: [],
            dataLancamentosTipo: [],
            dataVendasPadrao: [],
            dataVendasTipologia: [],
            dataVendasTipo: [],
            dataOfertaFinalPadrao: [],
            dataOfertaFinalTipologia: [],
            dataOfertaFinalTipo: [],
            dataIVVPadrao: [],
            dataIVVTipologia: [],
            dataIVVTipo: [],
            dataTicketMedioPadrao: [],
            dataTicketMedioTipologia: [],
            dataTicketMedioTipo: [],
            dataTicketMedioMetroPadrao: [],
            dataTicketMedioMetroTipo: [],
            dataTicketMedioMetroTipologia: [],
            submited: false,
        });
    }
    submit = async () => {

        try {

            await this.validate();

            await this.clearDatas();

            let periods = await axios.post(route('web.periods_util.index'), {
                "periodo_inicial": this.state.periodo_inicial,
                "periodo_final": this.state.periodo_final,
                "tipo_periodo": this.state.agrupamento.value.toLowerCase(),
                "variacao": this.state.variacao?.value
            });

            if (periods.status === 200) {
                this.setState({
                    ...this.state, periodos: periods.data.periodos_normal, periodos_variacao: periods.data.periodos_variacao
                });
            }

            this.setLoading(true);

            await this.getData("Padrão");
            await this.getData("Tipo");
            await this.getData("Tipologia");

        } catch (e) {
            console.log(e);

            if (e.response && e.response.status === 422) {

                let errors = e.response.data.errors;

                this.setState({
                    ...this.state, errors: errors
                });
            }
        } finally {
            this.setLoading(false);
            this.setState({
                ...this.state,
                submited: true
            });
        }
    }

    getData = async (tipo_detalhamento) => {

        let payload = this.getPayloadForRequest(tipo_detalhamento);

        await Promise.all([
            this.getLancamentos(payload),
            this.getVendas(payload),
            this.getOfertaFinal(payload),
            this.getIvv(payload),
            this.getPrecos(payload),
            this.getPrecosMetros(payload),
        ]);

        await this.setState({
            ...this.state,
            response_period_type: this.state.agrupamento.value.toLowerCase(),
            response_tipo_detalhamento: payload.tipo_detalhamento,
            response_variacao: payload.variacao,
        });
    }

    filtroPorDetalhe = async (rows, detalhe) => {
        return rows.filter((index) => { return index.detalhe == detalhe });
    }

    getLancamentos = async (payload) => {

        let lancamentosOptions = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Lançamentos')[0].options;

        //paylod precisa ter pelo menos uma das opções de lançamentos para fazer o request
        if (payload.detalhes_para_exibir.filter(detalhe => lancamentosOptions.map(option => option.value).includes(detalhe)).length > 0) {

            let headers = this.getRequestHeader();
            let response = await axios.post(`${reportsServiceBaseUrl}/bases-report/lancamentos`, payload, headers);

            if (response.status === 200 && response.data.length > 0) {

                let detalhes = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Lançamentos')[0].options.map(option => option.value);

                let periodos = this.state.periodos.concat(this.state.periodos_variacao);

                let rows = transform(response.data, detalhes, periodos, payload.tipos, payload.tipo_detalhamento);

                let unidadesLancadas = await this.filtroPorDetalhe(rows, 'unidades_lancadas');

                let vgv = await this.filtroPorDetalhe(rows, 'vgv_unidades_lancadas');


                let arrayTipoDetalhamento = { "Padrão": "dataLancamentosPadrao", "Tipologia": "dataLancamentosTipologia", "Tipo": "dataLancamentosTipo" };

                let tipo_detalhamento = arrayTipoDetalhamento[payload.tipo_detalhamento];
                await this.setState({
                    ...this.state,
                    [tipo_detalhamento]: { unidadesLancadas: unidadesLancadas, vgv: vgv }

                })
            }
        }
    }

    getVendas = async (payload) => {

        let vendasOptions = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Vendas')[0].options;

        //retira o ivv de options pois ele é obtido em um request separado para outro endpoint
        vendasOptions = vendasOptions.filter(option => option.value !== 'ivv');

        if (payload.detalhes_para_exibir.filter(detalhe => vendasOptions.map(option => option.value).includes(detalhe)).length > 0) {

            payload = { ...payload, detalhes_para_exibir: payload.detalhes_para_exibir.filter(detalhe => detalhe !== 'ivv') }

            let headers = this.getRequestHeader();
            let response = await axios.post(`${reportsServiceBaseUrl}/bases-report/vendas`, payload, headers);

            if (response.status === 200 && response.data.length > 0) {
                let detalhes = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Vendas')[0].options.map(option => option.value).filter(option => option !== 'ivv');

                let periodos = this.state.periodos.concat(this.state.periodos_variacao);
                let rows = transform(response.data, detalhes, periodos, payload.tipos, payload.tipo_detalhamento);

                let unidadesVendidas = await this.filtroPorDetalhe(rows, 'unidades_vendidas_liquido');

                let vgv = await this.filtroPorDetalhe(rows, 'vgv_unidades_vendidas_liquido');

                let arrayTipoDetalhamento = { "Padrão": "dataVendasPadrao", "Tipologia": "dataVendasTipologia", "Tipo": "dataVendasTipo" };

                let tipo_detalhamento = arrayTipoDetalhamento[payload.tipo_detalhamento];

                await this.setState({
                    ...this.state,
                    [tipo_detalhamento]: { unidadesVendidas: unidadesVendidas, vgv: vgv }

                })
            }
        }

        return [];
    }

    getOfertaFinal = async (payload) => {

        let ofertaFinalOptions = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Oferta Final')[0].options;

        //paylod precisa ter pelo menos uma das opções de lançamentos para fazer o request
        if (payload.detalhes_para_exibir.filter(detalhe => ofertaFinalOptions.map(option => option.value).includes(detalhe)).length > 0) {

            let headers = this.getRequestHeader();
            let response = await axios.post(`${reportsServiceBaseUrl}/bases-report/ofertas-final`, payload, headers);

            if (response.status === 200 && response.data.length > 0) {
                let detalhes = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Oferta Final')[0].options.map(option => option.value);

                let periodos = this.state.periodos.concat(this.state.periodos_variacao);

                let rows = transform(response.data, detalhes, periodos, payload.tipos, payload.tipo_detalhamento);

                let ofertaFinal = await this.filtroPorDetalhe(rows, 'oferta_final');

                let vgv = await this.filtroPorDetalhe(rows, 'vgv_oferta_final');


                let arrayTipoDetalhamento = { "Padrão": "dataOfertaFinalPadrao", "Tipologia": "dataOfertaFinalTipologia", "Tipo": "dataOfertaFinalTipo" };

                let tipo_detalhamento = arrayTipoDetalhamento[payload.tipo_detalhamento];
                await this.setState({
                    ...this.state,
                    [tipo_detalhamento]: { ofertaFinal: ofertaFinal, vgv: vgv }

                })
            }
        }

        return [];
    }

    getIvv = async (payload) => {


        let ivvOptions = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Vendas')[0].options.filter(option => option.value === 'ivv');        //pega somente o ivv de options

        if (payload.detalhes_para_exibir.filter(detalhe => ivvOptions.map(option => option.value).includes(detalhe)).length > 0) {

            let headers = this.getRequestHeader();
            let response = await axios.post(`${reportsServiceBaseUrl}/bases-report/ivv`, payload, headers);

            if (response.status === 200 && response.data.length > 0) {
                let detalhes = ivvOptions.map(option => option.value).filter(detalhe => payload.detalhes_para_exibir.includes(detalhe));

                let periodos = this.state.periodos.concat(this.state.periodos_variacao);
                let rows = transform(response.data, detalhes, periodos, payload.tipos, payload.tipo_detalhamento);

                let ivv = await this.filtroPorDetalhe(rows, 'ivv');

                let arrayTipoDetalhamento =
                    { "Padrão": "dataIVVPadrao", "Tipologia": "dataIVVTipologia", "Tipo": "dataIVVTipo" };

                let tipo_detalhamento = arrayTipoDetalhamento[payload.tipo_detalhamento];

                await this.setState({
                    ...this.state,
                    [tipo_detalhamento]: { ivv: ivv }

                })
            }
        }

        return [];
    }

    getPrecos = async (payload) => {
        let precosOptions = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Preços')[0].options.filter(option => option.value == 'preco_medio');

        if (payload.detalhes_para_exibir.filter(detalhe => precosOptions.map(option => option.value).includes(detalhe)).length > 0) {

            payload = { ...payload, detalhes_para_exibir: payload.detalhes_para_exibir.filter(detalhe => detalhe == 'preco_medio') }
            let headers = this.getRequestHeader();
            let response = await axios.post(`${reportsServiceBaseUrl}/bases-report/precos`, payload, headers);

            if (response.status === 200 && response.data.length > 0) {
                let detalhes = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Preços')[0].options.map(option => option.value);

                let periodos = this.state.periodos.concat(this.state.periodos_variacao);

                let rows = transform(response.data, detalhes, periodos, payload.tipos, payload.tipo_detalhamento);

                let ticketMedio = await this.filtroPorDetalhe(rows, 'preco_medio');

                let arrayTipoDetalhamento =
                    { "Padrão": "dataTicketMedioPadrao", "Tipologia": "dataTicketMedioTipologia", "Tipo": "dataTicketMedioTipo" };

                let tipo_detalhamento = arrayTipoDetalhamento[payload.tipo_detalhamento];

                await this.setState({
                    ...this.state,
                    [tipo_detalhamento]: { ticketMedio: ticketMedio }

                })
            }
        }

        return [];

    }

    getPrecosMetros = async (payload) => {

        let precosOptions = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Preços')[0].options.filter(option => option.value != 'preco_medio');

        if (payload.detalhes_para_exibir.filter(detalhe => precosOptions.map(option => option.value).includes(detalhe)).length > 0) {
            let headers = this.getRequestHeader();
            payload = { ...payload, detalhes_para_exibir: payload.detalhes_para_exibir.filter(detalhe => detalhe != 'preco_medio') }
            let response = await axios.post(`${reportsServiceBaseUrl}/bases-report/precos-metro`, payload, headers);

            if (response.status === 200 && response.data.length > 0) {
                let detalhes = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Preços')[0].options.map(option => option.value);

                let periodos = this.state.periodos.concat(this.state.periodos_variacao);

                let rows = transform(response.data, detalhes, periodos, payload.tipos, payload.tipo_detalhamento);

                let ticketMedio = await this.filtroPorDetalhe(rows, 'preco_metro_medio');

                let arrayTipoDetalhamento =
                    { "Padrão": "dataTicketMedioMetroPadrao", "Tipologia": "dataTicketMedioMetroTipologia", "Tipo": "dataTicketMedioMetroTipo" };

                let tipo_detalhamento = arrayTipoDetalhamento[payload.tipo_detalhamento];


                await this.setState({
                    ...this.state,
                    [tipo_detalhamento]: { ticketMedio: ticketMedio }

                })
            }
        }

        return [];

    }

    getPayloadForRequest = (tipo_detalhamento) => {
        let payload = {};

        payload.periodo_inicial = this.state.periodo_inicial;
        payload.periodo_final = this.state.periodo_final;

        payload.tipos = this.state.tipos.map(tipo => tipo.value);
        payload.padrao = this.state.padrao.length > 0 ? this.state.padrao.map(padrao => padrao.value) : this.state.padraoOptions.map(padrao => padrao.value);
        if (payload.padrao && payload.padrao.includes('Condomínio de Casas/Sobrados')) {
            let padroesCasa = ['Econômico', 'Standard', 'Médio', 'Alto', 'Luxo', 'Super Luxo'];
            padroesCasa.map((index) => {
                if (!payload.padrao.includes(index)) { payload.padrao.push(index) }
            })
        }
        payload.estados = this.state.estados.map(estado => estado.value);
        payload.cidades = this.state.cidades.map(cidade => cidade.value);
        payload.bairros = this.state.bairros.map(bairro => bairro.value);
        payload.clusters = this.state.clusters.map(cluster => cluster.value);
        payload.incorporadoras = this.state.incorporadoras.map(incorporadora => incorporadora.value);
        payload.tipo_detalhamento = tipo_detalhamento;
        payload.agrupamento = this.state.agrupamento.value;
        payload.detalhes_para_exibir = this.state.detalhes_para_exibir.map(detalhe => detalhe.value);
        payload.variacao = this.state.variacao?.value;
        payload.empreendimentos = this.state.empreendimentos.map(empreendimento => empreendimento.label);

        return payload;
    }

    render() {

        return (<div className="card">
            {this.state.loading && <Loader />}
            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                        {/* <div class="alert alert-danger alert-dismissible fade show" role="alert">
                            Prezado, devido as chuvas e enchentes no Rio Grande do Sul, não atualizaremos a base de dados de Porto Alegre para o mês de Abril.
                        </div> */}
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                            Análise Temporal
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <form action="">
                                <div className='form-grou row'>
                                    <div className="col-4">
                                        <label>Carregar Projeto</label>
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderRadius: 20,
                                                }),
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: "#F2F2F2",
                                                    primary: "#5B7537",
                                                },
                                            })}
                                            placeholder="Selecione"
                                            value={this.state.projetos}
                                            options={this.state.projetosOptions}
                                            onChange={(value) => this.changeProjeto(value)}
                                            isMulti={false}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-3">
                                        <label htmlFor="status">Período Inicial</label>
                                        <br />
                                        <div className='customDatePickerWidth'>
                                            <DatePicker
                                                locale="pt-BR"
                                                name="periodo_inicial"
                                                className="form-control"
                                                style={{ width: "100%" }}
                                                dateFormat="dd/MM/yyyy"
                                                showMonthYearPicker
                                                autoComplete="off"
                                                selected={this.state.periodo_inicial}
                                                onChange={async (e) => {
                                                    await this.setState({
                                                        ...this.state, periodo_inicial: e
                                                    });
                                                }}
                                            />
                                            {this.state.errors['periodo_inicial'] &&
                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                    {this.state.errors['periodo_inicial'][0]}
                                                </div>}

                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <label htmlFor="status">Período Final</label>
                                        <br />
                                        <div className='customDatePickerWidth'>
                                            <DatePicker
                                                locale="pt-BR"
                                                name="periodo_final"
                                                autoComplete="off"
                                                className="form-control"
                                                style={{ width: "100%" }}
                                                dateFormat="dd/MM/yyyy"
                                                showMonthYearPicker
                                                selected={this.state.periodo_final}
                                                onChange={async (e) => {
                                                    await this.setState({
                                                        ...this.state, periodo_final: e
                                                    });
                                                }}
                                            />
                                            {this.state.errors['periodo_final'] &&
                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                    {this.state.errors['periodo_final'][0]}
                                                </div>}
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <label htmlFor="status">Tipo</label>
                                        <Select
                                            isMulti
                                            name="tipo"
                                            closeMenuOnSelect={false}
                                            options={this.state.tiposOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione os Tipos"
                                            value={this.state.tipos}
                                            onChange={async (e) => {

                                                let value = e;
                                                await this.setState({
                                                    ...this.state, tipos: e || []
                                                });


                                            }}></Select>

                                        {this.state.errors['tipos'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['tipos'][0]}
                                            </div>}
                                    </div>
                                    <div className="col-3">
                                        <label htmlFor="status">Padrão</label>
                                        <Select
                                            name="padrao"
                                            isMulti
                                            options={this.state.padraoOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione o padrão"
                                            value={this.state.padrao}
                                            onChange={async (e) => {
                                                let value = e;

                                                if (e === null) {
                                                    value = [];
                                                }


                                                await this.setState({
                                                    ...this.state, padrao: value
                                                });
                                            }}></Select>

                                        {this.state.errors['padrao'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['padrao'][0]}
                                            </div>}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-3">
                                        <label htmlFor="status">Estado</label>
                                        <Select
                                            name="estado"
                                            isMulti
                                            options={this.state.estadosOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione o Estado"
                                            value={this.state.estados}
                                            onChange={async (e) => {
                                                let value = e;

                                                if (e === null) {
                                                    value = [];
                                                }
                                                console.log(value)
                                                await this.setState({
                                                    ...this.state, estados: value
                                                });

                                                this.loadCidades();
                                            }}></Select>

                                        {this.state.errors['estados'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['estados'][0]}
                                            </div>}
                                    </div>
                                    <div className="col-3">
                                        <label htmlFor="status">Cidades</label>
                                        <Select
                                            name="cidades"
                                            isMulti
                                            options={this.state.cidadesOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione as cidades"
                                            value={this.state.cidades}
                                            onChange={async (e) => {
                                                let value = e;

                                                if (e === null) {
                                                    value = [];
                                                }

                                                if (value.some(item => item.value === 'Todos')) {
                                                    value = [];
                                                    this.state.cidadesOptions.map((index) => {
                                                        if (index.options != undefined) {
                                                            value = value.concat(index.options)
                                                        }
                                                    })
                                                }
                                                await this.setState({
                                                    ...this.state, cidades: value
                                                });

                                                this.loadBairros();
                                                this.loadClusters();
                                                this.loadEmpreendimentos();
                                            }}></Select>

                                        {this.state.errors['cidades'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['cidades'][0]}
                                            </div>}
                                    </div>
                                    <div className="col-3">
                                        <label htmlFor="status">Bairros</label>
                                        <Select
                                            name="bairros"
                                            isMulti
                                            options={this.state.bairrosOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione os Bairros"
                                            value={this.state.bairros}
                                            onChange={async (e) => {
                                                await this.setState({
                                                    ...this.state, bairros: e || []
                                                });
                                            }}></Select>

                                        {this.state.errors['bairros'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['bairros'][0]}
                                            </div>}
                                    </div>
                                    <div className="col-3">
                                        <label htmlFor="status">Cluster</label>
                                        <Select
                                            name="cluster"
                                            isMulti
                                            options={this.state.clusterOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione os Clusters"
                                            value={this.state.clusters}
                                            onChange={async (e) => {
                                                await this.setState({
                                                    ...this.state, clusters: e || []
                                                });
                                            }}></Select>

                                        {this.state.errors['clusters'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['clusters'][0]}
                                            </div>}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className='col-3'>
                                        <label htmlFor='incorporadoras'>Incorporadoras</label>
                                        <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            loadOptions={this.buscaIncorporadoras}
                                            value={this.state.incorporadoras}
                                            onChange={async (e) => {
                                                let values = e;

                                                if (e === null) {
                                                    values = [];
                                                }
                                                await this.setState({
                                                    ...this.state, incorporadoras: values
                                                });
                                            }}></AsyncSelect>
                                    </div>
                                    <div className='col-3'>
                                        <label htmlFor='empreendimentos'>Empreendimentos</label>

                                        <Select
                                            name="cidades"
                                            isMulti
                                            options={this.state.empreendimentosOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione os Empreendimentos"
                                            value={this.state.empreendimentos}
                                            onChange={async (e) => {
                                                let values = e;

                                                if (e === null) {
                                                    values = [];
                                                }
                                                await this.setState({
                                                    ...this.state, empreendimentos: values
                                                });
                                            }}></Select>
                                    </div>
                                    <div className='col-3'>
                                        <label htmlFor='agrupamento'>Agrupamento</label>
                                        <Select
                                            name="agrupamento"
                                            options={this.state.agrupamentoOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione o agrupamento"
                                            value={this.state.agrupamento}
                                            onChange={async (e) => {
                                                await this.setState({
                                                    ...this.state, agrupamento: e
                                                });
                                            }}></Select>
                                        {this.state.errors['agrupamento'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['agrupamento'][0]}
                                            </div>}
                                    </div>
                                    <div className="col-3">
                                        <label>Variação</label>
                                        <Select
                                            name="variacao"
                                            options={this.state.variacaoOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione a variação"
                                            value={this.state.variacao}
                                            onChange={async (e) => {
                                                await this.setState({
                                                    ...this.state, variacao: e
                                                });
                                            }}></Select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-3 form-group">
                                        <div class=" form-check">
                                            <label>&nbsp;</label>
                                            <input class="form-check-input"
                                                name="exibir_graficos"
                                                id="exibir_graficos"
                                                type="checkbox"
                                                checked={this.state.exibirGraficos}
                                                onChange={async () => {
                                                    await this.setState({
                                                        ...this.state,
                                                        exibirGraficos: !this.state.exibirGraficos
                                                    })
                                                }} />
                                            <span class="slider"></span>
                                            <label for="exibir_graficos" class="form-check-label slider-padding">
                                                Exibir Gráficos
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-3 form-group">
                                        <div class=" form-check">
                                            <label>&nbsp;</label>
                                            <input class="form-check-input"
                                                name="exibir_tabelas"
                                                id="exibir_tabelas"
                                                type="checkbox"
                                                checked={this.state.exibirTabelas}
                                                onChange={async () => {
                                                    await this.setState({
                                                        ...this.state,
                                                        exibirTabelas: !this.state.exibirTabelas
                                                    })
                                                }} />
                                            <span class="slider"></span>
                                            <label for="exibir_tabelas" class="form-check-label slider-padding">
                                                Exibir Tabelas
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <button className={"btn btn-primary button-brain btn-round btn-block"}
                                            onClick={async () => {
                                                await this.submit();
                                            }} type="button">Gerar Relatório
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <div className="card">
                <div className="card-body">
                    <div style={{ overflowY: "auto" }}>
                        {this.state.submited &&
                            <>
                                <button
                                    onClick={exportData}
                                    className="btn btn-sucess btn-link"
                                >
                                    Exportar para Excel
                                </button>
                                <div>
                                    <p className="alert alert-info font-weight-bold">
                                        <i className="fa fa-info-circle"></i>
                                        &nbsp; Resultados de VGV são apresentados em mil R$.
                                    </p>
                                </div>
                                <hr />
                                <Tabs
                                    defaultActiveKey="Lançamentos"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="Lançamentos" title="Lançamentos">
                                        <TabLancamento state={this.state} />
                                    </Tab>
                                    <Tab eventKey="Vendas" title="Vendas">
                                        <TabVendas state={this.state} />
                                    </Tab>
                                    <Tab eventKey="Oferta Final" title="Oferta Final">
                                        <TabOfertaFinal state={this.state} />
                                    </Tab>
                                    <Tab eventKey="IVV" title="IVV">
                                        <TabIVV state={this.state} />
                                    </Tab>
                                    <Tab eventKey="Ticket Médio" title="Ticket Médio">
                                        <TabTicketMedio state={this.state} />
                                    </Tab>
                                    <Tab eventKey="Ticket Médio M²" title="Ticket Médio M²">
                                        <TabTicketMedioMetro state={this.state} />
                                    </Tab>
                                </Tabs>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>);
    }
}

if (document.getElementById('analise_temporal_v2')) {
    ReactDOM.render(<Index />, document.getElementById('analise_temporal_v2'));
}
