import React, { Component } from 'react';
import ReactDOM from "react-dom";
import Loader from "../Loader";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt_BR from 'date-fns/locale/pt-BR';
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import transform from '../../services/RelatorioAcompanhamentoService';
import TableRelatorioAcompanhamento from './TableRelatorioAcompanhamento';
import { Accordion, Card, Button } from "react-bootstrap";
import { conforms } from 'lodash';

registerLocale('pt-BR', pt_BR);

let reportsServiceBaseUrl = process.env.MIX_BASE_URL_REPORTS;


export default class RelatorioAcompanhamentoForm extends Component {

    defaultTiposOptions = [{
        label: 'Vertical', value: 'Vertical'
    }, {
        label: 'Horizontal', value: 'Horizontal'
    }, {
        label: 'Comercial', value: 'Comercial'
    }, {
        label: 'Hotel', value: 'Hotel'
    }];


    constructor(props) {
        super();

        this.state = {
            periodo_inicial: '',
            data_lancamento_inicial: '',
            periodo_final: '',
            data_lancamento_final: '',
            tempo_de_venda: '',
            tipos: this.defaultTiposOptions.filter(tipo => tipo.value != 'Hotel'),
            estagio: [],
            estados: [],
            cidadesOptions: [],
            cidades: [],
            bairros: [],
            clusters: [],
            incorporadoras: [],
            tipo_detalhamento: '',
            padrao: [],
            agrupamento: {
                label: 'Mensal', value: 'Mensal'
            },
            detalhes_para_exibir: [],
            tiposOptions: this.defaultTiposOptions,
            estadosOptions: [],
            errors: [],
            clusterOptions: [],
            incorporadorasOptions: [],
            loading: false,
            periodos: [],
            periodos_variacao: [],
            padraoOptions: [{ 'label': 'Alto', value: 'Alto' }, {
                'label': 'Alto +', value: 'Alto +'
            }, {
                'label': 'Condomínio de Casas/Sobrados', value: 'Condomínio de Casas/Sobrados'
            }, { 'label': 'Condomínio de Chácaras', value: 'Condomínio de Chácaras' }, {
                'label': 'Econômico', value: 'Econômico'
            }, { 'label': 'Especial', value: 'Especial' }, {
                'label': 'Loteamento Aberto', value: 'Loteamento Aberto'
            }, { 'label': 'Loteamento Comercial', value: 'Loteamento Comercial' }, {
                'label': 'Loteamento Fechado', value: 'Loteamento Fechado'
            }, { 'label': 'Luxo', value: 'Luxo' }, { 'label': 'Médio', value: 'Médio' }, {
                'label': 'Própria', value: 'Própria'
            }, { 'label': 'Standard', value: 'Standard' }, {
                'label': 'Super Econômico', value: 'Super Econômico'
            }, { 'label': 'Super Luxo', value: 'Super Luxo' }, { 'label': 'Terreno', value: 'Terreno' },],
            agrupamentoOptions: [{
                label: 'Mensal', value: 'Mensal'
            }, {
                label: 'Trimestre', value: 'Trimestre'
            }, {
                label: 'Semestre', value: 'Semestre'
            }, {
                label: 'Anual', value: 'Anual'
            }],
            estagioOptions: [{
                label: "Planta", value: "Planta"
            }, {
                label: "Construção", value: "Construção"
            }, {
                label: "Pronto", value: "Pronto"
            }],
            detalhesParaExibirOptions: [{
                label: 'Lançamentos', options: [{
                    label: 'Empreendimentos Lançados', value: 'empreendimentos_lancados'
                }, {
                    label: 'Unidades Lançadas', value: 'unidades_lancadas'
                }, {
                    label: 'VGV Unidades Lançadas', value: 'vgv_unidades_lancadas'
                }]
            }, {
                label: 'Vendas', options: [{
                    label: 'Empreendimentos Vendidos', value: 'empreendimentos_vendidos'
                }, {
                    label: 'Unidades Vendidas Bruto', value: 'unidades_vendidas_bruto'
                }, {
                    label: 'Unidades Vendidas Líquido', value: 'unidades_vendidas_liquido'
                }, {
                    label: 'Unidades Distratadas', value: 'unidades_distratadas'
                }, {
                    label: 'VGV Unidades Vendidas Bruto', value: 'vgv_unidades_vendidas_bruto'
                }, {
                    label: 'VGV Unidades Vendidas Líquido', value: 'vgv_unidades_vendidas_liquido'
                }, {
                    label: 'VGV Unidades Distratadas', value: 'vgv_unidades_distratadas'
                }, {
                    label: 'IVV - Indice de Velocidade de Vendas', value: 'ivv'
                }]
            }, {
                label: 'Área', options: [{
                    label: 'Área Privativa Mínima', value: 'area_privativa_minima'
                }, {
                    label: 'Área Privativa Máxima', value: 'area_privativa_maxima'
                }, {
                    label: 'Área Privativa Média', value: 'area_privativa_media'
                }]
            }, {
                label: "Preços", options: [{
                    label: "Preço Médio", value: "preco_medio"
                }, {
                    label: "Preço Metro Médio", value: "preco_metro_medio"
                }, {
                    label: "Preço Metro Mínimo", value: "preco_metro_minimo"
                }, {
                    label: "Preço Metro Máximo", value: "preco_metro_maximo"
                }]
            }, {
                label: "Oferta Lançada", options: [{
                    label: "Oferta Lançada", value: "oferta_lancada"
                }, {
                    label: "VGV Oferta Lançada", value: "vgv_oferta_lancada"
                }, {
                    label: "Empreendimentos Ativos", value: "empreendimentos_ativos"
                },]
            }, {
                label: "Oferta Final", options: [{
                    label: "Oferta Final", value: "oferta_final"
                }, {
                    label: "VGV Oferta Final", value: "vgv_oferta_final"
                },]
            }, {
                label: "Esgotados", options: [{
                    label: "Empreendimentos Esgotados", value: "empreendimentos_esgotados"
                }, {
                    label: "Unidades Esgotadas", value: "unidades_esgotadas"
                }]
            }],

            bairrosOptions: [],
            tipoDetalhamentoOptions: [{
                label: 'Padrão', value: 'Padrão'
            }, {
                label: 'Tipo', value: 'Tipo'
            }, {
                label: 'Bairro', value: 'Bairro'
            }, {
                label: 'Cluster', value: 'Cluster'
            }, {
                label: 'Cidade', value: 'Cidade'
            }, {
                label: 'Tipologia', value: 'Tipologia'
            }, {
                label: 'Incorporadora', value: 'Incorporadora'
            }, {
                label: 'Ano de Lançamento', value: 'Ano de Lançamento'
            }, {
                label: 'Estágio de Obra e Padrão', value: 'Estágio de Obra e Padrão'
            },
            {
                label: 'Estágio de Obra e Tipo', value: 'Estágio de Obra e Tipo'
            },
            {
                label: 'Estado', value: 'Estado'
            },

            ],
            variacaoOptions: [
                {
                    label: "Não Mostrar",
                    value: ""
                },
                {
                    label: "Mesmo Período do Ano anterior",
                    value: "period_ano_anterior"
                },
                {
                    label: "Período Imediatamente Anterior",
                    value: "periodo_imediatamente_anterior"
                }


            ],
            tempo_de_venda_options: [
                {
                    label: 'Até 6 meses',
                    value: 'Até 6 meses',
                },
                {
                    label: 'De 6 a 24 meses',
                    value: 'De 6 a 24 meses',
                },
                {
                    label: 'De 24 a 48 meses',
                    value: 'De 24 a 48 meses',
                },
                {
                    label: 'Acima de 48 meses',
                    value: 'Acima de 48 meses',
                }
            ],
            variacao: '',
            rows: [],
            submited: false

        }
    }

    getRequestHeader = () => {
        return {
            headers: {
                Authorization: `Bearer ${this.state.user.jwt_token}`
            }
        }
    }


    loadData = async () => {

        let requestExtraParams = {};

        try {

            await this.setState({
                ...this.state, errors: [],
            })

            await this.setLoading(true);


        } catch (e) {
            if (e.response && e.response.status === 422) {

                if (e.response.headers['content-type'] === 'application/json') {

                    let errors = e.response.data.errors;

                    if (requestExtraParams.responseType === 'blob') {
                        let responseBody = JSON.parse(await e.response.data.text());
                        errors = responseBody.errors;
                    }

                    this.setState({
                        ...this.state, errors: errors
                    });
                }
            }

            console.log(e);
        } finally {
            await this.setLoading(false);
        }
    }

    setLoading = async (loading) => {

        await this.setState({
            loading: loading
        });
    }

    loadAuthUser = async () => {
        try {

            let response = await axios.get(route('user.auth'));

            if (response.status === 200) {
                this.setState({
                    ...this.state, user: response.data
                });
            }

        } catch (e) {
            console.log(e);
        }
    }

    loadEstados = async () => {
        try {

            let response = await axios.get(route('web.todos_estados.index'));

            if (response.status === 200) {
                this.setState({
                    ...this.state, estadosOptions: response.data
                });
            }

        } catch (e) {
            console.log(e);
        }
    }

    loadCidades = async () => {
        try {

            if (this.state.estados.length === 0) {
                return;
            }

            this.setLoading(true);

            let siglasEstados = this.state.estados.map(estado => estado.value);


            let response = await axios.post(route('web.cidades_por_estado.index'), {
                estados: siglasEstados
            });

            if (response.status === 200) {
                this.setState({
                    ...this.state,
                    cidadesOptions: [
                        { value: "Todos", label: "Selecionar Todos" },
                        ...response.data
                    ]
                });
            }

            if (this.state.tipo_detalhamento?.value == "Estado") {
                let value = [];
                this.state.cidadesOptions.map((index) => {
                    if (index.options != undefined) {
                        value = value.concat(index.options)
                    }
                })
                await this.setState({
                    ...this.state,
                    cidades: value
                });
            }

        } catch (e) {
            console.log(e);
        } finally {
            this.setLoading(false);
        }
    }

    loadRegioesAdministrativas = async () => {
        if (this.state.estados.length === 0) {
            return;
        }

        try {
            this.setLoading(true);

            let siglasEstados = this.state.estados.map(estado => estado.value);

            let response = await axios.post(route('web.regioes_administrativas_por_estado.index'), {
                estados: siglasEstados
            });

            if (response.status === 200) {

                this.setState({
                    ...this.state, regioesAdministrativasOptions: response.data
                });
            }
        } catch (e) {
            console.log(e);
        } finally {

        }
    }

    loadCidadesFromAdministrativas = async () => {
        try {
            if (this.state.regioesAdministrativas == undefined || this.state.regioesAdministrativas == "") {
                return;
            }

            this.setLoading(true);

            let cidades = await axios.post(route('web.cidades-regioes-administrativas.index'), {
                regiao: this.state.regioesAdministrativas.value
            });

            let value = []

            this.state.cidadesOptions.map((index) => {
                if (index.options != undefined) {
                    index.options.map((citidade) => {
                        if (cidades.data.includes(citidade.label)) {
                            value.push(citidade)
                        }
                    })
                }
            })

            await this.setState({
                ...this.state,
                cidades: value
            })
        }
        catch (e) {
            console.log(e);
        }
        finally {
            this.setLoading(false);
        }
    }

    loadBairros = async () => {
        try {

            if (this.state.cidades.length === 0) {
                return;
            }


            this.setLoading(true);

            let cidades = this.state.cidades.map(cidade => cidade.value);

            let response = await axios.post(route('web.bairros_por_cidade.index'), {
                cidades: cidades
            });

            if (response.status === 200) {
                this.setState({
                    ...this.state, bairrosOptions: response.data
                });
            }

        } catch (e) {
            console.log(e);
        } finally {
            this.setLoading(false);
        }
    }


    loadClusters = async () => {
        if (this.state.cidades.length === 0) {
            return;
        }

        try {
            this.setLoading(true);

            let cidades = this.state.cidades.map(cidade => cidade.value);

            let response = await axios.post(route('web.clusters_por_cidade.index'), {

                cidades: cidades
            });

            if (response.status === 200) {
                this.setState({
                    ...this.state, clusterOptions: response.data
                });
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.setLoading(false);
        }
    }

    buscaIncorporadoras = async (query) => {

        try {

            if (query.length < 2) {
                return [];
            }

            let response = await axios.get(route('web.busca_incorporadoras.index'), {
                params: {
                    query: query
                }
            });

            if (response.status === 200) {
                return response.data;
            }


        } catch (e) {
            console.log(e);
        } finally {

        }
    }


    componentDidMount = async () => {


        try {
            Promise.all([this.loadAuthUser(), this.loadEstados(),]);

            //map options
            let detalhesLabel = {};

            for (let i = 0; i < this.state.detalhesParaExibirOptions.length; i++) {
                for (let j = 0; j < this.state.detalhesParaExibirOptions[i].options.length; j++) {
                    detalhesLabel[this.state.detalhesParaExibirOptions[i].options[j].value] = this.state.detalhesParaExibirOptions[i].options[j].label;
                }

            }

            this.setState({
                ...this.state, detalhesLabel: detalhesLabel
            });


        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Validação do formulário
     */
    validate = async () => {

        let errors = {};

        if (!this.state.periodo_inicial) {
            errors['periodo_inicial'] = ['O campo período inicial é obrigatório'];
        }


        if (!this.state.periodo_final) {
            errors['periodo_final'] = ['O campo período final é obrigatório'];
        }


        if (this.state.tipos.length === 0) {
            errors['tipos'] = ['O campo tipo é obrigatório'];
        }


        if (this.state.estados.length === 0) {
            errors['estados'] = ['O campo estado é obrigatório'];
        }


        if (this.state.cidades.length === 0) {
            errors['cidades'] = ['O campo cidade é obrigatório'];
        }


        if (this.state.detalhes_para_exibir.length === 0) {
            errors['detalhes_para_exibir'] = ['O campo detalhes para exibir é obrigatório'];
        }


        if (!this.state.tipo_detalhamento) {
            errors['tipo_detalhamento'] = ['O campo tipo de detalhamento é obrigatório'];
        }

        if (Object.keys(errors).length > 0) {
            await this.setState({
                ...this.state,
                errors: errors
            });

            throw new Error('Validation Error');
        }


    }


    submit = async () => {
        console.log(this.state);
        try {

            await this.validate();

            this.setState({
                ...this.state, errors: [], rows: []
            });

            let periods = await axios.post(route('web.periods_util.index'), {
                "periodo_inicial": this.state.periodo_inicial,
                "periodo_final": this.state.periodo_final,
                "tipo_periodo": this.state.agrupamento.value.toLowerCase(),
                "variacao": this.state.variacao?.value
            });

            if (periods.status === 200) {
                this.setState({
                    ...this.state, periodos: periods.data.periodos_normal, periodos_variacao: periods.data.periodos_variacao
                });
            }

            this.setLoading(true);



            let payload = this.getPayloadForRequest();

            let rows = [];

            let [dataLancamentos, dataVendas, dataAreas, dataPrecos, dataPrecosMetro, dataOfertaLancada,
                dataOfertaFinal, dataUnidadesEsgotadas, dataEmpreendimentosEsgotados, dataIvv
            ] = await Promise.all([
                this.getLancamentos(payload),
                this.getVendas(payload),
                this.getAreas(payload),
                this.getPrecos(payload),
                this.getPrecosMetros(payload),
                this.getOfertaLancada(payload),
                this.getOfertaFinal(payload),
                this.getUnidadesEsgotadas(payload),
                this.getEmpreendimentosEsgotados(payload),
                this.getIvv(payload)
            ]);

            rows = dataLancamentos
                .concat(dataVendas)
                .concat(dataAreas)
                .concat(dataPrecos)
                .concat(dataPrecosMetro)
                .concat(dataOfertaLancada)
                .concat(dataOfertaFinal)
                .concat(dataUnidadesEsgotadas)
                .concat(dataEmpreendimentosEsgotados)
                .concat(dataIvv);


            this.setState({
                ...this.state,
                rows: rows,
                response_period_type: this.state.agrupamento.value.toLowerCase(),
                response_tipo_detalhamento: payload.tipo_detalhamento,
                response_variacao: payload.variacao,
                submited: true
            });


        } catch (e) {
            console.log(e);

            if (e.response && e.response.status === 422) {

                let errors = e.response.data.errors;


                this.setState({
                    ...this.state, errors: errors
                });

            }


        } finally {
            this.setLoading(false);
            this.setState({
                ...this.state,
                submited: true
            });
        }
    }

    getLancamentos = async (payload) => {

        let lancamentosOptions = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Lançamentos')[0].options;

        //paylod precisa ter pelo menos uma das opções de lançamentos para fazer o request
        if (payload.detalhes_para_exibir.filter(detalhe => lancamentosOptions.map(option => option.value).includes(detalhe)).length > 0) {

            let headers = this.getRequestHeader();
            let response = await axios.post(`${reportsServiceBaseUrl}/bases-report/lancamentos`, payload, headers);

            if (response.status === 200 && response.data.length > 0) {

                let optionsLancamentos = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Lançamentos')[0].options.map(option => option.value);
                let detalhes = optionsLancamentos.filter(detalhe => payload.detalhes_para_exibir.includes(detalhe));

                let periodos = this.state.periodos.concat(this.state.periodos_variacao);

                let rows = transform(response.data, detalhes, periodos, payload.tipos, payload.tipo_detalhamento);
                console.log(rows);
                return rows;
            }
        }

        return [];

    }


    getOfertaLancada = async (payload) => {

        let ofertaLancadaOptions = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Oferta Lançada')[0].options;

        //paylod precisa ter pelo menos uma das opções de lançamentos para fazer o request
        if (payload.detalhes_para_exibir.filter(detalhe => ofertaLancadaOptions.map(option => option.value).includes(detalhe)).length > 0) {

            let headers = this.getRequestHeader();
            let response = await axios.post(`${reportsServiceBaseUrl}/bases-report/ofertas-lancada`, payload, headers);

            if (response.status === 200 && response.data.length > 0) {
                let optionsOfertaLancada = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Oferta Lançada')[0].options.map(option => option.value);
                let detalhes = optionsOfertaLancada.filter(detalhe => payload.detalhes_para_exibir.includes(detalhe));

                let periodos = this.state.periodos.concat(this.state.periodos_variacao);

                let rows = transform(response.data, detalhes, periodos, payload.tipos, payload.tipo_detalhamento);


                return rows;
            }
        }

        return [];
    }

    getOfertaFinal = async (payload) => {

        let ofertaFinalOptions = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Oferta Final')[0].options;

        //paylod precisa ter pelo menos uma das opções de lançamentos para fazer o request
        if (payload.detalhes_para_exibir.filter(detalhe => ofertaFinalOptions.map(option => option.value).includes(detalhe)).length > 0) {

            let headers = this.getRequestHeader();
            let response = await axios.post(`${reportsServiceBaseUrl}/bases-report/ofertas-final`, payload, headers);

            if (response.status === 200 && response.data.length > 0) {
                let optionsOfertaFinal = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Oferta Final')[0].options.map(option => option.value);
                let detalhes = optionsOfertaFinal.filter(detalhe => payload.detalhes_para_exibir.includes(detalhe));

                let periodos = this.state.periodos.concat(this.state.periodos_variacao);

                let rows = transform(response.data, detalhes, periodos, payload.tipos, payload.tipo_detalhamento);


                return rows;
            }
        }

        return [];
    }


    getVendas = async (payload) => {


        let vendasOptions = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Vendas')[0].options;

        //retira o ivv de options pois ele é obtido em um request separado para outro endpoint
        vendasOptions = vendasOptions.filter(option => option.value !== 'ivv');

        if (payload.detalhes_para_exibir.filter(detalhe => vendasOptions.map(option => option.value).includes(detalhe)).length > 0) {

            payload = { ...payload, detalhes_para_exibir: payload.detalhes_para_exibir.filter(detalhe => detalhe !== 'ivv') }

            let headers = this.getRequestHeader();
            let response = await axios.post(`${reportsServiceBaseUrl}/bases-report/vendas`, payload, headers);

            if (response.status === 200 && response.data.length > 0) {
                let optionsVendas = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Vendas')[0].options.map(option => option.value).filter(option => option !== 'ivv');
                let detalhes = optionsVendas.filter(detalhe => payload.detalhes_para_exibir.includes(detalhe));

                let periodos = this.state.periodos.concat(this.state.periodos_variacao);
                let rows = transform(response.data, detalhes, periodos, payload.tipos, payload.tipo_detalhamento);

                return rows;
            }
        }

        return [];
    }

    getIvv = async (payload) => {


        let ivvOptions = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Vendas')[0].options.filter(option => option.value === 'ivv');        //pega somente o ivv de options

        if (payload.detalhes_para_exibir.filter(detalhe => ivvOptions.map(option => option.value).includes(detalhe)).length > 0) {

            let headers = this.getRequestHeader();
            let response = await axios.post(`${reportsServiceBaseUrl}/bases-report/ivv`, payload, headers);

            if (response.status === 200 && response.data.length > 0) {
                let detalhes = ivvOptions.map(option => option.value).filter(detalhe => payload.detalhes_para_exibir.includes(detalhe));
                let periodos = this.state.periodos.concat(this.state.periodos_variacao);
                console.log()
                let rows = transform(response.data, detalhes, periodos, payload.tipos, payload.tipo_detalhamento);
                console.log(rows);
                return rows;
            }
        }

        return [];
    }

    getPrecos = async (payload) => {
        let precosOptions = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Preços')[0].options.filter(option => option.value == 'preco_medio');

        if (payload.detalhes_para_exibir.filter(detalhe => precosOptions.map(option => option.value).includes(detalhe)).length > 0) {

            payload = { ...payload, detalhes_para_exibir: payload.detalhes_para_exibir.filter(detalhe => detalhe == 'preco_medio') }
            let headers = this.getRequestHeader();
            let response = await axios.post(`${reportsServiceBaseUrl}/bases-report/precos`, payload, headers);

            if (response.status === 200 && response.data.length > 0) {
                let precosOptions = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Preços')[0].options.map(option => option.value);
                let detalhes = precosOptions.filter(detalhe => payload.detalhes_para_exibir.includes(detalhe));

                let periodos = this.state.periodos.concat(this.state.periodos_variacao);

                let rows = transform(response.data, detalhes, periodos, payload.tipos, payload.tipo_detalhamento);


                return rows;
            }
        }

        return [];

    }


    getPrecosMetros = async (payload) => {

        let precosOptions = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Preços')[0].options.filter(option => option.value != 'preco_medio');

        if (payload.detalhes_para_exibir.filter(detalhe => precosOptions.map(option => option.value).includes(detalhe)).length > 0) {
            let headers = this.getRequestHeader();
            payload = { ...payload, detalhes_para_exibir: payload.detalhes_para_exibir.filter(detalhe => detalhe != 'preco_medio') }
            let response = await axios.post(`${reportsServiceBaseUrl}/bases-report/precos-metro`, payload, headers);

            if (response.status === 200 && response.data.length > 0) {
                let precosOptions = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Preços')[0].options.map(option => option.value);
                let detalhes = precosOptions.filter(detalhe => payload.detalhes_para_exibir.includes(detalhe));

                let periodos = this.state.periodos.concat(this.state.periodos_variacao);

                let rows = transform(response.data, detalhes, periodos, payload.tipos, payload.tipo_detalhamento);


                return rows;
            }
        }

        return [];

    }


    getUnidadesEsgotadas = async (payload) => {
        let esgotadosOptions = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Esgotados')[0].options.filter(option => option.value == 'unidades_esgotadas');

        //paylod precisa ter pelo menos uma das opções de vendas para fazer o request
        if (payload.detalhes_para_exibir.filter(detalhe => esgotadosOptions.map(option => option.value).includes(detalhe)).length > 0) {
            let headers = this.getRequestHeader();
            let response = await axios.post(`${reportsServiceBaseUrl}/bases-report/esgotados`, payload, headers);

            if (response.status === 200 && response.data.length > 0) {
                let optionsEsgotados = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Esgotados')[0].options.map(option => option.value).filter(option => option == 'unidades_esgotadas');
                let detalhes = optionsEsgotados.filter(detalhe => payload.detalhes_para_exibir.includes(detalhe));

                let periodos = this.state.periodos.concat(this.state.periodos_variacao);

                let rows = transform(response.data, detalhes, periodos, payload.tipos, payload.tipo_detalhamento);

                return rows;
            }
        }

        return [];
    }

    getEmpreendimentosEsgotados = async (payload) => {
        let esgotadosOptions = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Esgotados')[0].options.filter(option => option.value == 'empreendimentos_esgotados');

        //paylod precisa ter pelo menos uma das opções de vendas para fazer o request
        if (payload.detalhes_para_exibir.filter(detalhe => esgotadosOptions.map(option => option.value).includes(detalhe)).length > 0) {
            let headers = this.getRequestHeader();
            let response = await axios.post(`${reportsServiceBaseUrl}/bases-report/empreendimentos-esgotados`, payload, headers);

            if (response.status === 200 && response.data.length > 0) {
                let optionsEsgotados = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Esgotados')[0].options.map(option => option.value).filter(option => option == 'empreendimentos_esgotados');
                let detalhes = optionsEsgotados.filter(detalhe => payload.detalhes_para_exibir.includes(detalhe));

                let periodos = this.state.periodos.concat(this.state.periodos_variacao);

                let rows = transform(response.data, detalhes, periodos, payload.tipos, payload.tipo_detalhamento);

                return rows;
            }
        }

        return [];

    }


    getAreas = async (payload) => {
        let areasOptions = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Área')[0].options;

        //paylod precisa ter pelo menos uma das opções de vendas para fazer o request
        if (payload.detalhes_para_exibir.filter(detalhe => areasOptions.map(option => option.value).includes(detalhe)).length > 0) {
            let headers = this.getRequestHeader();
            let response = await axios.post(`${reportsServiceBaseUrl}/bases-report/areas`, payload, headers);

            if (response.status === 200 && response.data.length > 0) {
                let optionsAreas = this.state.detalhesParaExibirOptions.filter(detalhe => detalhe.label === 'Área')[0].options.map(option => option.value);
                let detalhes = optionsAreas.filter(detalhe => payload.detalhes_para_exibir.includes(detalhe));

                let periodos = this.state.periodos.concat(this.state.periodos_variacao);
                let rows = transform(response.data, detalhes, periodos, payload.tipos, payload.tipo_detalhamento);


                return rows;
            }
        }

        return [];
    }

    getPayloadForRequest = () => {
        let payload = {};

        payload.periodo_inicial = this.state.periodo_inicial;
        payload.periodo_final = this.state.periodo_final;

        payload.data_lancamento_inicial = this.state.data_lancamento_inicial;
        payload.data_lancamento_final = this.state.data_lancamento_final;

        payload.tempoDeVenda = this.state.tempo_de_venda?.value;
        console.log(payload.tempo_de_venda, this.state.tempo_de_venda);
        payload.tipos = this.state.tipos.map(tipo => tipo.value);
        payload.estagio = this.state.estagio.map(estagio => estagio.value);
        payload.estados = this.state.estados.map(estado => estado.value);
        payload.cidades = this.state.cidades.map(cidade => cidade.value);
        payload.bairros = this.state.bairros.map(bairro => bairro.value);
        payload.clusters = this.state.clusters.map(cluster => cluster.value);
        payload.incorporadoras = this.state.incorporadoras.map(incorporadora => incorporadora.value);
        payload.tipo_detalhamento = this.state.tipo_detalhamento.value;
        payload.padrao = this.state.padrao.map(padrao => padrao.value);
        if (payload.padrao && payload.padrao.includes('Condomínio de Casas/Sobrados')) {
            let padroesCasa = ['Econômico', 'Standard', 'Médio', 'Alto', 'Luxo', 'Super Luxo'];
            padroesCasa.map((index) => {
                if (!payload.padrao.includes(index)) { payload.padrao.push(index) }
            })
        }
        payload.agrupamento = this.state.agrupamento.value;
        payload.detalhes_para_exibir = this.state.detalhes_para_exibir.map(detalhe => detalhe.value);
        payload.variacao = this.state.variacao?.value;

        return payload;
    }


    render() {

        return (<div className="card">
            {this.state.loading && <Loader />}

            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                            Relatório de Acompanhamento
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <form action="">
                                <div className="form-group row">
                                    <div className="col-3">

                                        <label htmlFor="status">Período Inicial</label>
                                        <DatePicker
                                            locale="pt-BR"
                                            name="periodo_inicial"
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            showMonthYearPicker
                                            autoComplete="off"
                                            selected={this.state.periodo_inicial}
                                            onChange={async (e) => {
                                                await this.setState({
                                                    ...this.state, periodo_inicial: e
                                                });
                                            }}
                                        />
                                        {this.state.errors['periodo_inicial'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['periodo_inicial'][0]}
                                            </div>}

                                    </div>
                                    <div className="col-3">

                                        <label htmlFor="status">Período Final</label>
                                        <DatePicker
                                            locale="pt-BR"
                                            name="periodo_final"
                                            autoComplete="off"
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            showMonthYearPicker
                                            selected={this.state.periodo_final}
                                            onChange={async (e) => {
                                                await this.setState({
                                                    ...this.state, periodo_final: e
                                                });
                                            }}
                                        />
                                        {this.state.errors['periodo_final'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['periodo_final'][0]}
                                            </div>}

                                    </div>

                                    <div className="col-3">

                                        <label htmlFor="status">Data de Lançamento Inicial</label>
                                        <DatePicker
                                            locale="pt-BR"
                                            name="data_lancamento_inicial"
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            showMonthYearPicker
                                            autoComplete="off"
                                            selected={this.state.data_lancamento_inicial}
                                            onChange={async (e) => {
                                                await this.setState({
                                                    ...this.state, data_lancamento_inicial: e
                                                });
                                            }}
                                        />
                                        {this.state.errors['data_lancamento_inicial'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['data_lancamento_inicial'][0]}
                                            </div>}

                                    </div>
                                    <div className="col-3">

                                        <label htmlFor="status">Data de Lançamento Final</label>
                                        <DatePicker
                                            locale="pt-BR"
                                            name="data_lancamento_final"
                                            autoComplete="off"
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            showMonthYearPicker
                                            selected={this.state.data_lancamento_final}
                                            onChange={async (e) => {
                                                await this.setState({
                                                    ...this.state, data_lancamento_final: e
                                                });
                                            }}
                                        />
                                        {this.state.errors['data_lancamento_final'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['data_lancamento_final'][0]}
                                            </div>}

                                    </div>

                                </div>
                                <div className='form-group row'>
                                    <div className="col-4">
                                        <label htmlFor="status">Tipo</label>
                                        <Select
                                            isMulti
                                            name="tipo"
                                            closeMenuOnSelect={false}
                                            options={this.state.tiposOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione os Tipos"
                                            value={this.state.tipos}
                                            onChange={async (e) => {

                                                let value = e;
                                                await this.setState({
                                                    ...this.state, tipos: e || []
                                                });


                                            }}></Select>

                                        {this.state.errors['tipos'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['tipos'][0]}
                                            </div>}
                                    </div>
                                    <div className="col-4">
                                        <label htmlFor="status">Padrão</label>
                                        <Select
                                            name="padrao"
                                            isMulti
                                            options={this.state.padraoOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione o padrão"
                                            value={this.state.padrao}
                                            onChange={async (e) => {
                                                let value = e;

                                                if (e === null) {
                                                    value = [];
                                                }


                                                await this.setState({
                                                    ...this.state, padrao: value
                                                });
                                            }}></Select>

                                        {this.state.errors['padrao'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['padrao'][0]}
                                            </div>}
                                    </div>
                                    <div className="col-4">
                                        <label htmlFor="status">Tempo de Venda</label>
                                        <Select
                                            name="padrao"
                                            isMulti={false}
                                            options={this.state.tempo_de_venda_options}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione o Tempo de Venda"
                                            value={this.state.tempo_de_venda}
                                            onChange={async (e) => {
                                                console.log(e);
                                                let value = e;

                                                if (e === null) {
                                                    value = [];
                                                }


                                                await this.setState({
                                                    ...this.state, tempo_de_venda: value
                                                });
                                                console.log(this.state.tempo_de_venda);
                                            }}></Select>

                                        {this.state.errors['tempo_de_venda'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['tempo_de_venda'][0]}
                                            </div>}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-4">
                                        <label htmlFor="status">Estado</label>
                                        <Select
                                            name="estado"
                                            isMulti
                                            options={this.state.estadosOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione o tipo de relatório"
                                            value={this.state.type}
                                            onChange={async (e) => {
                                                let value = e;

                                                if (e === null) {
                                                    value = [];
                                                }

                                                await this.setState({
                                                    ...this.state, estados: value
                                                });

                                                this.loadCidades();
                                                this.loadRegioesAdministrativas();
                                            }}></Select>

                                        {this.state.errors['estados'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['estados'][0]}
                                            </div>}
                                    </div>
                                    <div className="col-4">
                                        <label htmlFor="status">Cidades</label>
                                        <Select
                                            isDisabled={this.state.tipo_detalhamento.value == 'Estado'}
                                            name="cidades"
                                            isMulti
                                            options={this.state.cidadesOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione as cidades"
                                            value={this.state.cidades}
                                            onChange={async (e) => {
                                                let value = e;

                                                if (e === null) {
                                                    value = [];
                                                }
                                                if (value.some(item => item.value === 'Todos')) {
                                                    value = [];
                                                    this.state.cidadesOptions.map((index) => {
                                                        if (index.options != undefined) {
                                                            console.log(index.options);
                                                            value = value.concat(index.options)
                                                        }
                                                    })
                                                }
                                                await this.setState({
                                                    ...this.state, cidades: value
                                                });

                                                this.loadBairros();
                                                this.loadClusters();
                                            }}></Select>

                                        {this.state.errors['cidades'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['cidades'][0]}
                                            </div>}
                                    </div>
                                    <div className="col-4">
                                        <label htmlFor="status">Bairros</label>
                                        <Select
                                            isDisabled={this.state.tipo_detalhamento.value == 'Estado'}
                                            name="bairros"
                                            isMulti
                                            options={this.state.bairrosOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione os Bairros"
                                            value={this.state.bairros}
                                            onChange={async (e) => {
                                                await this.setState({
                                                    ...this.state, bairros: e || []
                                                });
                                            }}></Select>

                                        {this.state.errors['bairros'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['bairros'][0]}
                                            </div>}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-4">
                                        <label htmlFor="status">Cluster</label>
                                        <Select
                                            name="cluster"
                                            isMulti
                                            options={this.state.clusterOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione os Clusters"
                                            value={this.state.clusters}
                                            onChange={async (e) => {
                                                await this.setState({
                                                    ...this.state, clusters: e || []
                                                });
                                            }}></Select>

                                        {this.state.errors['clusters'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['clusters'][0]}
                                            </div>}
                                    </div>
                                    <div className="col-4">
                                        <label htmlFor='regioes_administrativas'>Regiões Administrativas</label>
                                        <Select
                                            name="regioes_administrativas"
                                            options={this.state.regioesAdministrativasOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione as Regiões Administrativas"
                                            value={this.state.regioesAdministrativas}
                                            onChange={async (e) => {
                                                await this.setState({
                                                    ...this.state, regioesAdministrativas: e || ''
                                                });
                                                this.loadCidadesFromAdministrativas()
                                            }}></Select>
                                    </div>
                                    <div className='col-4'>
                                        <label htmlFor='incorporadoras'>Incorporadoras</label>
                                        <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            loadOptions={this.buscaIncorporadoras}
                                            value={this.state.incorporadoras}
                                            onChange={async (e) => {
                                                let values = e;

                                                if (e === null) {
                                                    values = [];
                                                }
                                                await this.setState({
                                                    ...this.state, incorporadoras: values
                                                });
                                            }}></AsyncSelect>
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-3">
                                        <label htmlFor="status">Tipo de Detalhamento</label>
                                        <Select
                                            name="tipo_detalhamento"
                                            options={this.state.tipoDetalhamentoOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione o tipo de detalhamento"
                                            value={this.state.tipo_detalhamento}
                                            onChange={async (e) => {
                                                if (e.value == "Estado") {
                                                    let value = [];
                                                    this.state.cidadesOptions.map((index) => {
                                                        if (index.options != undefined) {
                                                            value = value.concat(index.options)
                                                        }
                                                    })
                                                    await this.setState({
                                                        ...this.state,
                                                        cidades: value
                                                    });
                                                }
                                                await this.setState({
                                                    ...this.state,
                                                    tipo_detalhamento: e,
                                                });
                                            }}></Select>

                                        {this.state.errors['tipo_detalhamento'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['tipo_detalhamento'][0]}
                                            </div>}
                                    </div>

                                    <div className="col-3">
                                        <label htmlFor="status">Estágio da Obra</label>
                                        <Select
                                            name="estagio"
                                            isMulti
                                            options={this.state.estagioOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione o Estágio da Obra"
                                            value={this.state.estagio}
                                            onChange={async (e) => {
                                                let value = e;

                                                if (e === null) {
                                                    value = [];
                                                }


                                                await this.setState({
                                                    ...this.state, estagio: value
                                                });
                                            }}></Select>

                                        {this.state.errors['estagio'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['estagio'][0]}
                                            </div>}
                                    </div>
                                    <div className='col-3'>
                                        <label htmlFor='agrupamento'>Agrupamento</label>
                                        <Select

                                            name="agrupamento"
                                            options={this.state.agrupamentoOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione o agrupamento"
                                            value={this.state.agrupamento}
                                            onChange={async (e) => {
                                                await this.setState({
                                                    ...this.state, agrupamento: e
                                                });
                                            }}></Select>
                                        {this.state.errors['agrupamento'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['agrupamento'][0]}
                                            </div>}
                                    </div>

                                    <div className="col-3">
                                        <label>Variação</label>
                                        <Select
                                            name="variacao"
                                            options={this.state.variacaoOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione a variação"
                                            value={this.state.variacao}
                                            onChange={async (e) => {
                                                await this.setState({
                                                    ...this.state, variacao: e
                                                });
                                            }}></Select>

                                    </div>

                                </div>

                                <div className="row form-group">
                                    <div className="col-12">
                                        <label htmlFor='detalhes_para_exibir'>Detalhes</label>
                                        <Select
                                            isMulti
                                            closeMenuOnSelect={false}
                                            name="detalhes_para_exibir"
                                            options={this.state.detalhesParaExibirOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecione os detalhes"
                                            value={this.state.detalhes_para_exibir}
                                            onChange={async (e) => {
                                                let value = e;
                                                if (e === null) {
                                                    value = [];

                                                }
                                                await this.setState({
                                                    ...this.state, detalhes_para_exibir: value
                                                });
                                            }}></Select>
                                        {this.state.errors['detalhes_para_exibir'] &&
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {this.state.errors['detalhes_para_exibir'][0]}
                                            </div>}

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <button className={"btn btn-primary button-brain btn-round btn-block"}
                                            onClick={async () => {
                                                await this.submit();
                                            }} type="button">Gerar Relatório
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <div className="card">
                <div className="card-body">
                    <div style={{ overflowY: "auto" }}>
                        {this.state.rows.length > 0 && <TableRelatorioAcompanhamento
                            rows={this.state.rows}
                            tipo_detalhamento={this.state.response_tipo_detalhamento}
                            detalhesLabel={this.state.detalhesLabel}
                            response_period_type={this.state.response_period_type}
                            periodos={this.state.periodos}
                            setLoading={this.setLoading}
                            variacao={this.state.response_variacao}
                            periodos_variacao={this.state.periodos_variacao}
                        />}

                        {this.state.rows.length === 0 && this.state.submited && <p className="alert alert-info text-center font-weight-bold small">Sem Informações para Exibir</p>}
                    </div>
                </div>
            </div>

        </div>);
    }
}


if (document.getElementById('js-form-acompanhamento-report')) {
    ReactDOM.render(<RelatorioAcompanhamentoForm />, document.getElementById('js-form-acompanhamento-report'));
}
