import axios from 'axios';

const colorRanges = [
    { min: 0, max: 1000, color: '#a1d070' },
    { min: 1001, max: 2000, color: '#ffff00' },
    { min: 2001, max: 3000, color: '#80ff00' },
    { min: 3001, max: 4000, color: '#00ff00' },
    { min: 4001, max: 5000, color:  '#00ff80'},
    { min: 5001, max: 6000, color: '#ff7000' },
    { min: 6001, max: 7000, color: '#00ffff' },
    { min: 7001, max: 8000, color: '#0080ff' },
    { min: 8001, max: 9000, color: '#0000ff' },
    { min: 9001, max: 10000, color: '#8000ff' },
    { min: 10001, max: 11000, color: '#ff00ff' },
    { min: 11001, max: 12000, color: '#ff0080' },
    { min: 12001, max: 13000, color: '#FFC0CB' },
    { min: 13001, max: 14000, color: '#008080' },
    { min: 14001, max: 15000, color: '#800000'},
    { min: 15001, max: Infinity, color: '#556B2F' }
];

const RENDA_IBGE_BASE_URL = process.env.MIX_BASE_URL_RENDA_IBGE;

const getSetores = async ( polygons = [], cidades = [] ) => {
    const api_key = process.env.MIX_RENDA_IBGE_API_KEY;

    const endpoint = `${RENDA_IBGE_BASE_URL}/api/geojson/setores`;
    //'https://rendaibge.belogic.com.br/api/geojson/setores';

    const response = await axios.post(endpoint,{
        'polygons' : polygons,
        'cidades' : cidades
    },{
        headers: {
            'x-api-key': api_key,
        }
    });

    if( response.status !== 200 ){
        throw new Error('Erro ao buscar setores.' + response.data.message);
    }

    return {
        'geojson': initializeColors(response.data.geojson),
        'rendas_por_area': response.data.rendas_por_area,
    };
};

const initializeColors = ( data ) => {

    data.features.map( feature =>{
        let renda = feature.properties.renda;

        for (const range of colorRanges) {
            if (renda >= range.min && renda <= range.max) {
                feature.properties.color = range.color;
                break;
            }
        }

    });

    return data;
};

const getFaixasEtarias = async ( polygons = [], cidades = [] ) => {
    const api_key = process.env.MIX_RENDA_IBGE_API_KEY;

    const endpoint = `${RENDA_IBGE_BASE_URL}/api/geojson/setores-faixa-etaria`;

    const response = await axios.post(endpoint,{
        'polygons' : polygons,
        'cidades' : cidades
    },{
        headers: {
            'x-api-key': api_key,
        }
    });

    if( response.status !== 200 ){
        throw new Error('Erro ao buscar setores.' + response.data.message);
    }

    return response.data;
}

const getDomiciliosPorTipo = async ( polygons = [], cidades = [] ) => {
    const api_key = process.env.MIX_RENDA_IBGE_API_KEY;

    const endpoint = `${RENDA_IBGE_BASE_URL}/api/domicilios/por-tipo`;

    const response = await axios.post(endpoint,{
        'polygons' : polygons,
        'cidades' : cidades
    },{
        headers: {
            'x-api-key': api_key,
        }
    });

    if( response.status !== 200 ){
        throw new Error('Erro ao buscar Domicilios Por Tipo.' + response.data.message);
    }

    return response.data;
}

const getDomiciliosPorTipoOcupacao = async( polygons = [], cidades = [])=>{
    const api_key = process.env.MIX_RENDA_IBGE_API_KEY;

    const endpoint = `${RENDA_IBGE_BASE_URL}/api/domicilios/por-condicao-ocupacao`;

    const response = await axios.post(endpoint,{
        'polygons' : polygons,
        'cidades' : cidades
    },{
        headers: {
            'x-api-key': api_key,
        }
    });

    if( response.status !== 200 ){
        throw new Error('Erro ao buscar Domicilios Por Tipo.' + response.data.message);
    }

    return response.data;
}

const getDomiciliosPorNumeroMoradores = async ( polygons = [], cidades = [] ) => {
    const api_key = process.env.MIX_RENDA_IBGE_API_KEY;

    const endpoint = `${RENDA_IBGE_BASE_URL}/api/domicilios/por-numero-moradores`;

    const response = await axios.post(endpoint,{
        'polygons' : polygons,
        'cidades' : cidades
    },{
        headers: {
            'x-api-key': api_key,
        }
    });

    if( response.status !== 200 ){
        throw new Error('Erro ao buscar Domicilios Por Tipo.' + response.data.message);
    }

    return response.data;
}

const getDomiciliosPorFaixaDeRenda = async ( polygons = [], cidades = [] ) => {
    const api_key = process.env.MIX_RENDA_IBGE_API_KEY;

    const endpoint = `${RENDA_IBGE_BASE_URL}/api/renda-domicilios`;

    const response = await axios.post(endpoint,{
        'polygons' : polygons,
        'cidades' : cidades
    },{
        headers: {
            'x-api-key': api_key,
        }
    });

    if( response.status !== 200 ){
        throw new Error('Erro ao buscar Domicilios Por Tipo.' + response.data.message);
    }

    return response.data;
}

const getPopulacaoPorFaixaDeRenda = async ( polygons = [], cidades = [] ) => {
    const api_key = process.env.MIX_RENDA_IBGE_API_KEY;

    const endpoint = `${RENDA_IBGE_BASE_URL}/api/populacao-renda-por-faixa`;

    const response = await axios.post(endpoint,{
        'polygons' : polygons,
        'cidades' : cidades
    },{
        headers: {
            'x-api-key': api_key,
        }
    });

    if( response.status !== 200 ){
        throw new Error('Erro ao buscar Domicilios Por Tipo.' + response.data.message);
    }

    return response.data;
}

export {
    getSetores,
    colorRanges,
    getFaixasEtarias,
    getDomiciliosPorTipo,
    getDomiciliosPorTipoOcupacao,
    getDomiciliosPorNumeroMoradores,
    getDomiciliosPorFaixaDeRenda,
    getPopulacaoPorFaixaDeRenda
};
