import React, { Component } from 'react';


class Loader extends Component {
    state = {
        styles : ['loading']
     }
    render() {
        return (
            <div style={{
                    width: "100%",
                    height: "90vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: 'absolute',
                    zIndex: 20000,
                    alignContent: 'center',
                    alignSelf: 'center',

                }}>
                <div className="loading">Loading&#8230;</div>
            </div>
         );
    }
}

export default Loader;
