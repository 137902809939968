import {React, Component} from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import InnerLoading from "./InnerLoading";

highchartsMore(Highcharts);
solidGauge(Highcharts);

const options = {
    chart: {
        zoomType: 'x'
    },
    series: [{
        data: [
            ["Mês 1",100000.00],
        ]

    }],
    xAxis: {
        categories: ['Mês 1', 'Mês 2', 'Mês 3', 'Mês 4', 'Mês 5', 'Mês 6', 'Mês 7', 'Mês 8', 'Mês 9','Mês 1', 'Mês 2', 'Mês 3', 'Mês 4', 'Mês 5', 'Mês 6', 'Mês 7', 'Mês 8', 'Mês 9','Mês 1', 'Mês 2', 'Mês 3', 'Mês 4', 'Mês 5', 'Mês 6', 'Mês 7', 'Mês 8', 'Mês 9','Mês 1', 'Mês 2', 'Mês 3', 'Mês 4', 'Mês 5', 'Mês 6', 'Mês 7', 'Mês 8', 'Mês 9']
    }
}
function getGaugeOptions( valor, nome, descricao = 'Descrição') {
    return {
        chart: {
            type: 'solidgauge'
        },

        title: descricao,

        pane: {
            center: ['50%', '85%'],
            size: '100%',
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
                innerRadius: '60%',
                outerRadius: '100%',
                shape: 'arc'
            }
        },

        exporting: {
            enabled: true
        },

        tooltip: {
            enabled: false
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true
                }
            }
        },
        yAxis: {
            min: -50,
            max: 200,
            title: {
                text: nome
            },
            stops: [
                [1.5, '#DF5353'], // red
                [2.5, '#DDDF0D'], // yellow
                [3.0, '#55BF3B'] // green
            ],
        },

        credits: {
            enabled: false
        },

        series: [{
            name: nome,
            data: [valor],
            dataLabels: {
                format:
                    '<div style="text-align:center">' +
                    '<span style="font-size:25px">{y}</span><br/>' +
                    '<span style="font-size:12px;opacity:0.4">{descricao}</span>' +
                    '</div>'
            },
            tooltip: {
                valueSuffix: nome
            }
        }]

    }
}



export default class DemonstrativoResultados extends Component {

    constructor(props) {
        super(props);

        this.state = {
            analise_uuid: '',
            resultado: {
                vpl: 0.00,
                tir: 0.00,
                receita_liquida: 0.00,
                ipl: 0.00,
                ipl_proprio: 0.00,
                total_juros: 0.00,
                roi: 0.00,
                payback: 0.00,
                investimento_maximo: 0.00,
            }

        }
    }


    static getDerivedStateFromProps(props, state) {
        return {
            resultado: {
                ...props.resultado
            },
            loading: props.loading_resultados_dasboard
        }

    }

    render(){

        if (this.state.loading) {
            return <InnerLoading/>
        }


        return(<>
            <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                        <label htmlFor="receita_liquida">Valor Presente Líquido</label>
                        <input type="text" className="readonly form-control" id="receita_liquida"
                               name="receita_liquida" value={this.state.resultado.vpl}
                               readOnly/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="receita_liquida">TIR(%)</label>
                        <input type="text" className="readonly form-control" id="tir"
                               name="tir" value={this.state.resultado.tir}
                               readOnly/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="receita_liquida">Receita Líquida (R$) - Descontando Impostos</label>
                        <input type="text" className="readonly form-control" id="receita_liquida"
                               name="receita_liquida" value={this.state.resultado.receita_liquida}
                               readOnly/>
                    </div>

                   <div className="form-group">
                        <label htmlFor="">Exposição Máxima (R$)</label>
                        <input type="text" className="readonly form-control" id="exposicao_maxima"
                               name="exposicao_maxima"
                               defaultValue={this.props.resultado?.investimento_maximo}
                               readOnly/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="investimento_total">Investimento total a valor de presente (R$)</label>
                        <input type="text" className="readonly form-control" id="investimento_total"
                               readOnly
                               name="investimento_total" value={this.props.resultado.ipl}/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="investimento_proprio">Investimento Próprio a valor de presente (R$)</label>
                        <input type="text" className="readonly form-control" id="investimento_proprio"
                               readOnly
                               name="investimento_proprio" value={this.props.resultado.ipl_proprio}/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="total_juros_pago">Total de juros pago ao agente financeiro (R$)</label>
                        <input type="text" className="readonly form-control" id="total_juros_pago"
                               readOnly
                               name="total_juros_pago" value={this.props.resultado.total_juros}/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="total_juros_pago">ROI</label>
                        <input type="text" className="readonly form-control" id="total_juros_pago"
                               readOnly
                               name="total_juros_pago" value={this.props.resultado.roi}/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="total_juros_pago">Payback</label>
                        <input type="text" className="readonly form-control" id="total_juros_pago"
                               readOnly
                               name="total_juros_pago" value={this.props.resultado.payback}/>
                    </div>


                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div className="d-flex justify-content-center align-itens-center">
                        <div className="" style={{width: '50%'}}>
                            <HighchartsReact highcharts={Highcharts} options={getGaugeOptions(
                                this.props.resultado.margem_liquida, 'Margem Líquida', 'Margem Líquida = VPL / Receita Líquida'
                            )}/>
                            <h5 className="text-center">
                                Margem Líquida = VPL / Receita Líquida
                            </h5>
                        </div>
                        <div className="" style={{width: '50%'}}>
                            <HighchartsReact highcharts={Highcharts} options={getGaugeOptions(
                                this.props.resultado.resultado_1, 'Resultado 1', 'Resultado 1 = VPL / Investimento Total'
                            )}/>
                            <h5 className="text-center">
                                Resultado 1 = VPL / Investimento Total
                            </h5>

                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-itens-center">
                        <div className="" style={{width: '50%'}}>
                            <HighchartsReact highcharts={Highcharts} options={getGaugeOptions(
                                this.props.resultado.resultado_2, 'Resultado 2', 'Resultado 2 = VPL / Investimento Próprio'
                            )}/>
                            <h5 className="text-center">
                                Resultado 2 = VPL / Investimento Próprio
                            </h5>
                        </div>
                    </div>
                </div>

            </div>

        </>);
    }


}
