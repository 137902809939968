import React from 'react';
import {OverlayTrigger, Popover} from "react-bootstrap";
import {Chip} from "@material-ui/core";
import { Toolbar } from 'primereact/toolbar';

import Icon from '@mdi/react';
import { mdiMapMarkerMultiple, mdiMapMarkerOff, mdiMapMarkerMultipleOutline, mdiMapMarker } from '@mdi/js';




class Ferramentas extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            uploading: false,
        }
    }

    uploadKmlKmzFile = async ( e ) => {
        const file = e.target.files[0];
        this.setState({uploading: true});
        try{

            let formData = new FormData();

            formData.append('file', file );

            const response = await axios.post( route('transform-kml'), formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if( response.status === 200 ){
                 let paths = response.data;

                for( let x = 0; x < paths.length; x++ ){
                    await this.props.createAndAddPolygon( paths[x], {editable: false, draggable: false } );
                }

            }

        }catch (e){
            console.error(e);
        }finally {
            this.setState({uploading: false});
        }
    }


    render() {

        const leftContents = (
            <React.Fragment>
                <div className="btn-group" role="group" aria-label="Basic example">
                    <button className={"btn btn-success"}
                            onClick={()=>{
                                this.props.setShowMarkers(!this.props.showMarkers);
                            }}
                            title={this.props.showMarkers ? 'Mostrar Marcadores' : 'Esconder Marcadores'}>
                        {this.props.showMarkers && <>
                            <Icon path={mdiMapMarkerOff} size={1} />
                        </>}
                        {!this.props.showMarkers && <>
                            <Icon path={mdiMapMarker} size={1} />
                        </>}

                    </button>
                    <button className={"btn btn-default"} title={"Agrupar Marcadores"}
                            onClick={this.props.toggleUseCluster}
                    >
                        {this.props.use_cluster && <>
                            <Icon path={mdiMapMarkerMultiple} size={1} />
                        </>}

                        {!this.props.use_cluster && <>
                            <Icon path={mdiMapMarkerMultipleOutline} size={1} />
                        </>}

                    </button>

                    <button className={"btn btn-primary"} title={"Baixar KML"}
                            onClick={this.props.downloadKml}
                    >
                        <i className="fa-solid fa-download"></i> &nbsp;
                        KML

                    </button>

                    <button className={"btn btn-success"} title={"Baixar KML"}
                            onClick={this.props.openModalWork}
                    >
                        <i className="fa-solid fa-floppy-disk"></i> &nbsp;
                        Salvar Trabalho

                    </button>
                </div>

            </React.Fragment>
        );

        return (
            <div>
                <Toolbar left={leftContents}/>


                <ul className='list-group'>
                    {this.props.overlays.map((overlay, index) => {

                        if (overlay.type !== 'cidade') {
                            return <React.Fragment key={index}>
                                <li className='list-group-item'>
                                    <div className="pull-left" style={{fontWeight: "bold"}}>
                                        {overlay.type === 'Círculo' &&
                                            <i className="fa-solid fa-circle" style={{color: overlay.color}}></i>}
                                        {overlay.type === 'Círculo Manual' &&
                                            <i className="fa-solid fa-circle" style={{color: overlay.color}}></i>}
                                        {overlay.type === 'Polígono' &&
                                            <i className="fa-solid fa-draw-polygon" style={{color: overlay.color}}></i>}
                                        {overlay.type === 'Retângulo' &&
                                            <i className="fa-solid fa-square" style={{color: overlay.color}}></i>}
                                        &nbsp; {overlay.type} - {overlay.name}
                                    </div>
                                    <div className="pull-right">

                                        <div className="btn-group" role="group" aria-label="Basic example">
                                            <button className={"btn btn-success btn-sm"}
                                                    onClick={ (e)=>{
                                                        e.preventDefault();
                                                        this.props.editShapeName(overlay) }}
                                                    title={"Renomear Forma"}>
                                                <i className="fa-regular fa-pen-to-square"></i>
                                            </button>
                                            <button className={"btn btn-default btn-sm"}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.toogleOverlayVisibility(overlay);
                                                    }}
                                                    title={overlay.map ? 'Esconder' : 'Mostrar'}>
                                                {overlay.map ? <i className="fa-solid fa-eye-slash"></i> :
                                                    <i className="fa-solid fa-eye"></i>}
                                            </button>

                                            {(overlay.type === 'Círculo' || overlay.type === 'Círculo Manual') && <>
                                                <OverlayTrigger
                                                    trigger="click"
                                                    placement="top"
                                                    overlay={
                                                        <Popover>
                                                            <Popover.Title as="h3">Editar Raio do
                                                                Círculo</Popover.Title>
                                                            <Popover.Content>
                                                                <input type="number"
                                                                       className="form-control form-control-sm"
                                                                       value={overlay.radius}
                                                                       onChange={(e) => {
                                                                           overlay.setRadius( parseFloat(e.target.value) );
                                                                       }}/>
                                                                &nbsp;
                                                                <button
                                                                    title={"Salvar"}
                                                                    onClick={this.props.reloadEmpreendimentos }
                                                                    className="btn btn-success btn-sm">
                                                                    <i className="fa-solid fa-check"></i>
                                                                </button>
                                                            </Popover.Content>
                                                        </Popover>
                                                    }>
                                                    <button
                                                        className="btn btn-light btn-sm" title={overlay.type}
                                                        title={"Alterar Raio do Círculo"}>
                                                        <i className="fa-solid fa-up-right-and-down-left-from-center"></i>
                                                    </button>
                                                </OverlayTrigger>
                                            </>}
                                            <button className={"btn btn-danger btn-sm"}
                                                    onClick={() => {
                                                        this.props.removeOverlay(overlay.id);
                                                    }}
                                                    title={"Remover"}>
                                                <i className="far fa-trash-alt"></i>
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            </React.Fragment>
                        }
                    })}

                    {this.props.heatMap && <>

                        <li className="list-group-item">

                            <div className="pull-left" style={{fontWeight: "bold"}}>
                                <i className="fa-solid fa-fire"></i>
                                &nbsp; Mapa de Calor
                            </div>

                            <div className="pull-right">
                                <div className="btn-group" role="group" aria-label="Basic example">
                                    <button className="btn btn-danger btn-sm" title="Remover Mapa de Calor"
                                            key={`mapa_de_calor_remover_button`}
                                            title="Remover Mapa de Calor"
                                            onClick={() => {this.props.removeHeatMap();}}
                                    >
                                        <i className="fa fa-trash" style={{color: 'white'}}></i>
                                    </button>

                                    <button className="btn btn-danger btn-sm"
                                            key={`mapa_de_calor_esconder_marcadores`}
                                            title={this.props.showMarkers === true ? 'Esconder Marcadores' : 'Mostrar Marcadores'}
                                            onClick={() => {

                                                this.props.toggleShowMarkers();
                                            }}
                                    >
                                        {this.props.showMarkers === false ?
                                            <i className="fa fa-map-marker-alt"></i> :
                                            <i className="fa-solid fa-xmark"></i>
                                        }
                                    </button>
                                </div>
                            </div>

                        </li>


                    </>
                    }
                    { this.props.ibgeDataLayer != null && <li className="list-group-item">

                            <div className="pull-left" style={{fontWeight: "bold"}}>
                                <i className="fa-solid fa-map"></i>
                                &nbsp; Dados de Renda
                            </div>

                            <div className="pull-right">
                                <div className="btn-group" role="group" aria-label="Basic example">
                                    <button className="btn btn-danger btn-sm" title="Remover Camada de Dados de Renda"
                                            key={`mapa_de_calor_remover_button`}
                                            title="Remover Mapa de Calor"
                                            onClick={() => {this.props.removeIbgeDataLayer();}}
                                    >
                                        <i className="fa fa-trash" style={{color: 'white'}}></i>
                                    </button>

                                    <button
                                        onClick={() => {this.props.toggleIbgeDataLayerVisibility();}}
                                        className="btn btn-default btn-sm"
                                        title={ this.props.ibgeDataLayer?.getMap() === null ? 'Mostrar Camada de Dados de Renda' : 'Esconder Camada de Dados de Renda'}>
                                        {this.props.ibgeDataLayer?.getMap() === null ? <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>}
                                    </button>

                                </div>
                            </div>
                    </li>}

                </ul>
                {this.props.polos_geradores_types_selected.length > 0 && <>

                    <div className="card-body">
                        <strong>Polos Geradores Selecionados</strong> <br />
                        {this.props.polos_geradores_types_selected.map((type, index)=>{
                            return <React.Fragment key={index}>
                                <Chip
                                      size="small"
                                      label={type.label}
                                      onDelete={(e)=>{
                                          this.props.removePolosGeradoresTypeSelectedAndReloadEmpreendimentos(index);
                                      }}
                                />
                                &nbsp;
                            </React.Fragment>
                        })}
                    </div>
                </>}

                <div className="card">
                    <div className="card-header">Upload KML/KMZ</div>
                    <div className="card-body">
                         <input type="file" className="form-control" onChange={ this.uploadKmlKmzFile} />
                    </div>
                </div>

            </div>
        );
    }
}

export default Ferramentas;
